import { Box, Button, IconButton } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import moment from "moment-timezone";

// ** Custom Components
import CustomChip from '@core/components/mui/chip'
import { SortType, StatusObj } from '@core/utils/types'

import AddUpdate from './add-update'
import { useAppDispatch, useAppSelector } from 'store/store'
import DeleteDialog from './delete-dialog'
import ConfirmDialog from './confirm-dialog'
import { getAllOrderList } from 'store/Thunk/orderManagementThunk'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import CheckIcon from '@mui/icons-material/CheckRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import { extractFileNameFromUrl } from 'helper/service'
import AgGridSelectTable from 'views/table/AgGridSelectTable';

const statusObj: StatusObj = {
  1: { title: 'current', color: 'primary' },
  2: { title: 'professional', color: 'success' },
  3: { title: 'rejected', color: 'error' },
  4: { title: 'resigned', color: 'warning' },
  5: { title: 'applied', color: 'info' }
}

export default function Index() {

  const user_type: any = localStorage.getItem("user_type");
  // ** State
  const [refresh, setRefresh] = useState(true); // Just a toggle. Does not any effect on true/false value
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([]) // set data from the json object
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState<any>({
    order_date: new Date().toISOString(),
    credit_limit: "0",
    products: [{
      "product": {},
      "description": "",
      "width": "",
      "height": "",
      "rate": "",
      "amount": "",
    }]
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<any>({ open: false, id: null, status: "" });

  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      width: "100px",
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      width: "150px",
      field: "order_date",
      headerName: "Order Date",
      valueFormatter: (p: any) => p.value ? moment(p.value).format('DD/MM/YYYY') : "-",
      filter: "agDateColumnFilter",
    },
    {
      field: "client_name",
      width: "170px",
      headerName: "Client Name",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.client?.name || ""
      }
    },
    {
      width: "150px",
      field: "mobile_no",
      headerName: "Mobile No",
      filter: 'agTextColumnFilter',
    },
    {
      field: "total",
      width: "150px",
      headerName: 'Total',
      cellStyle: { textAlign: "right" },
      sortable: false,
      filter: "agNumberColumnFilter",
      valueFormatter: (p: any) => {
        let totalAmount = 0;

        p?.data?.OrderProduct.map((product: any) => {
          // Convert "amount" to a number and add it to the total
          totalAmount += parseFloat(product.amount);
        });
        return totalAmount
      }
    },
    {
      field: "status",
      width: "130px",
      headerName: 'status',
      autoHeight: true,
      sortable: false,
      filter: "agSetColumnFilter",
      valueFormatter: (p: any) => {
        return p?.data?.status || "-"
      },
      cellRenderer: (params: any) => {

        if (params?.data?.status) {
          return (
            <CustomChip
              size='small'
              skin='light'
              color={params.data.status == "approved" ? "success" : "error"}
              label={params.data.status}
              sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
            />
          )
        } else if (user_type != "super_admin" && !rolesPermission?.order_management?.all_order?.includes("write")) {
          return (
            <CustomChip
              size='small'
              skin='light'
              color={"info"}
              label={"Pending"}
              sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
            />
          )
        } else {
          return (
            <Box sx={{ mb: 1 }}>
              <IconButton size='small'
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDialog({ open: true, id: params?.data?.id, status: "approved" })
                }}
                sx={{
                  width: "34px", height: "36px",
                  borderRadius: "14px", color: "#fff", backgroundColor: "#4caf50", "&:hover": {
                    backgroundColor: "#45a049"
                  }
                }}>
                <CheckIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmDialog({ open: true, id: params?.data?.id, status: "decline" })
                }}
                sx={{
                  ml: "9px",
                  width: "34px", height: "36px",
                  borderRadius: "14px", color: "#fff", backgroundColor: "#ff5252", "&:hover": {
                    backgroundColor: "#d13a3a"
                  }
                }}>
                <ClearIcon />
              </IconButton>
            </Box>
          )
        }
      }
    },
    {
      field: "order_image",
      width: "130px",
      headerName: 'Order Image',
      floatingFilter: false,
      sortable: false,
      cellRenderer: (p: any) => {
        // const status = statusObj[params.row.status]
        return (
          // <Typography variant='body2' sx={{ color: 'text.primary' }}>
          //   {params.row.OrderProduct[0].order_image}
          // </Typography>
          <>{p?.data?.OrderProduct[0]?.order_image ? <Button sx={{ m: "-8px" }} onClick={(e) => {
            e.stopPropagation();
            onDownload(p?.data?.OrderProduct[0]?.order_image, p?.data?.id, p?.data.client.file_code, p?.data.client?.name)
          }}>
            Download
          </Button> : <Box>--</Box>}</>
        )
      }
    },
    {
      field: 'remarks',
      width: "120px",
      headerName: 'Remarks',
      filter: 'agTextColumnFilter',
      // cellRenderer: (params: any) => {
      //   const status = statusObj[params.data.status]

      //   return (
      //     <Typography variant='body2' sx={{ color: 'text.primary' }}>
      //       {params.data.remarks}
      //     </Typography>
      //   )
      // }
    },
    {
      width: "100px",
      field: "action",
      headerName: 'Action',
      floatingFilter: false,
      sortable: false,
      cellRenderer: (params: any) => {
        if (user_type == "super_admin" || rolesPermission?.order_management?.all_order?.includes("write")) {
          return (
            <Box>
              {
                params?.data?.status !== "deleted" && (
                  <IconButton onClick={(e) => {
                    e.stopPropagation()
                    setOpenDialog(!openDialog)
                    // setOrderDetails(params.row.OrderProduct)
                    setFormData({ ...params.data, client: params.data.client })
                  }}>
                    <EditIcon />
                  </IconButton>
                )
              }
              {
                params?.data?.status == "approved" && (
                  <IconButton onClick={(e) => {
                    e.stopPropagation()
                    setConfirmDialog({ open: true, id: params?.data?.id, status: "deleted" })
                  }}>
                    <DeleteIcon />
                  </IconButton>
                )
              }
            </Box>
          )
        } else {
          return (<>-</>)
        }

      }
    }
  ]);

  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.order_management?.all_order?.includes("export");

  const onDownload = async (imageUrl: any, orderId: any, fileCode: any, clientName: any) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);

    const filename = extractFileNameFromUrl(decodeURIComponent(imageUrl));
    downloadLink.download = `${orderId}${fileCode ? "-" + fileCode : ""}${clientName ? "-" + clientName : ""}-${filename}`; // Adjust the filename based on the image type

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  const dispatch = useAppDispatch();

  const { allOrderListing } = useAppSelector((state: any) => state.orderManagementReducer);

  // const columns: any = [
  //   {
  //     flex: 0.1,
  //     minWidth: 90,
  //     field: 'id',
  //     headerName: 'Id',
  //     renderHeader: (params: any) => (
  //       <div>
  //         ID
  //         <TextField
  //           variant="standard"
  //           size="small"
  //           onChange={(e) => params.api.setFilterModel({
  //             items: [
  //               { columnField: 'id', operatorValue: 'contains', value: e.target.value },
  //             ],
  //           })}
  //         />
  //       </div>
  //     ),
  //     renderCell: (params: any) => {
  //       const { row } = params

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {params.row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.1,
  //     minWidth: 120,
  //     // flex: 0.1,
  //     headerName: 'Date',
  //     field: 'order_date',
  //     valueGetter: (params: any) => {
  //       return params ? moment(params).format('DD/MM/YYYY') : "-";
  //     },
  //     renderCell: (params: any) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params?.value}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.15,
  //     // minWidth: 110,
  //     field: 'name',
  //     headerName: 'Client',
  //     renderCell: (params: any) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //         {params.row.client.name}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.15,
  //     field: 'mobile_no',
  //     minWidth: 80,
  //     headerName: 'Mobile',
  //     type: "number",
  //     renderCell: (params: any) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.mobile_no}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.1,
  //     // minWidth: 140,
  //     field: 'total',
  //     headerName: 'Total',
  //     type: "number",
  //     renderCell: (params: any) => {
  //       let totalAmount = 0;

  //       params?.row?.OrderProduct.map((product: any) => {
  //         // Convert "amount" to a number and add it to the total
  //         totalAmount += parseFloat(product.amount);
  //       });

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {totalAmount}
  //         </Typography>
  //       )
  //     }
  //   },
  //   // {
  //   //   // flex: 0.175,
  //   //   flex: 0.1,
  //   //   // minWidth: 140,
  //   //   field: 'courier_charge',
  //   //   headerName: 'Courier Charge',
  //   //   renderCell: (params: GridRenderCellParams) => {
  //   //     return (
  //   //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //   //         {params.row.courier_charge}
  //   //       </Typography>
  //   //     )
  //   //   }
  //   // },
  //   {
  //     // flex: 0.175,
  //     minWidth: 140,
  //     field: 'status',
  //     headerName: 'status',
  //     renderCell: (params: any) => {

  //       if (params?.row?.status) {
  //         return (
  //           <CustomChip
  //             size='small'
  //             skin='light'
  //             color={params.row.status == "approved" ? "success" : "error"}
  //             label={params.row.status}
  //             sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
  //           />
  //         )
  //       } else if (user_id != 1 && !rolesPermission?.order_management?.all_order?.includes("write")) {
  //         return (
  //           <CustomChip
  //             size='small'
  //             skin='light'
  //             color={"info"}
  //             label={"Pending"}
  //             sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
  //           />
  //         )
  //       } else {
  //         return (
  //           <Box sx={{margin: "-8px"}}>
  //             <IconButton size='small'
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 setConfirmDialog({ open: true, id: params?.id, status: "approved" })
  //               }}
  //               sx={{
  //                 width: "34px", height: "36px",
  //                 borderRadius: "14px", color: "#fff", backgroundColor: "#4caf50", "&:hover": {
  //                   backgroundColor: "#45a049"
  //                 }
  //               }}>
  //               <CheckIcon />
  //             </IconButton>
  //             <IconButton
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 setConfirmDialog({ open: true, id: params?.id, status: "decline" })
  //               }}
  //               sx={{
  //                 ml: "9px",
  //                 width: "34px", height: "36px",
  //                 borderRadius: "14px", color: "#fff", backgroundColor: "#ff5252", "&:hover": {
  //                   backgroundColor: "#d13a3a"
  //                 }
  //               }}>
  //               <ClearIcon />
  //             </IconButton>
  //           </Box>
  //         )
  //       }
  //     }
  //   },
  //   {
  //     minWidth: 140,
  //     flex: 0.1,
  //     field: 'order_image',
  //     sortable: false,
  //     headerName: 'Order Image',
  //     renderCell: (params: any) => {
  //       const status = statusObj[params.row.status]
  //       return (
  //         // <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         //   {params.row.OrderProduct[0].order_image}
  //         // </Typography>
  //         <>{params?.row?.OrderProduct[0]?.order_image ? <Button sx={{m: "-8px"}} onClick={(e) => {
  //           e.stopPropagation();
  //           onDownload(params?.row?.OrderProduct[0]?.order_image, params?.row?.id, params?.row.client.file_code)
  //         }}>
  //           Download
  //         </Button> : <Box>--</Box>}</>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.1,
  //     field: 'remarks',
  //     headerName: 'Remarks',
  //     renderCell: (params: any) => {
  //       const status = statusObj[params.row.status]

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params.row.remarks}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     minWidth: 120,
  //     field: '',
  //     headerName: 'Action',
  //     renderCell: (params: any) => {
  //       if (user_id == 1 || rolesPermission?.order_management?.all_order?.includes("write")) {
  //         return (
  //           <Box sx={{m: "-10px"}}>
  //             <IconButton  onClick={(e) => {
  //               e.stopPropagation()
  //               setOpenDialog(!openDialog)
  //               // setOrderDetails(params.row.OrderProduct)
  //               setFormData({ ...params.row, client: params.row.client })
  //             }}>
  //               <EditIcon />
  //             </IconButton>
  //             {
  //               params?.row?.status == "approved" && (
  //                 <IconButton onClick={(e) => {
  //                   e.stopPropagation()
  //                   setConfirmDialog({ open: true, id: params?.id, status: "deleted" })
  //                 }}>
  //                   <DeleteIcon />
  //                 </IconButton>
  //               )
  //             }
  //           </Box>
  //         )
  //       } else {
  //         return (<>-</>)
  //       }

  //     }
  //   }
  // ]

  useEffect(() => {
    if (allOrderListing && allOrderListing?.items) {
      setRows(allOrderListing?.items);
      setTotal(allOrderListing?.meta?.total);
    }
  }, [allOrderListing]);

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      // setLoading(true)
      return dispatch(getAllOrderList({ pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : "" })).then((res: any) => {
        return res.payload.data
        // setLoading(false)
      })
    },
    []
  )

  const handleDialog = () => {
    if (openDialog) {
      setFormData({
        order_date: new Date().toISOString(),
        credit_limit: "0",
        products: [{
          "product": {},
          "description": "",
          "width": "",
          "height": "",
          "rate": "",
          "amount": "",
        }]
      })
    }
    setRefresh(!refresh);
    setOpenDialog(!openDialog)
  }

  const handleDeleteDialog = () => {
    setDeleteDialog(false);
    setDeleteId(null);
    setRefresh(!refresh);
    setConfirmDialog({ open: false, id: null, status: "" })

  }

  const handleConfirmClose = () => {
    setConfirmDialog({ open: false, id: null, status: "" });
    setRefresh(!refresh);
  }
  const handleRowClick = (value: any) => {
    if (value?.data?.status !== "deleted") {
      setOpenDialog(!openDialog);
      setFormData(value.data);
    }
  }

  const getRowClassName = () => {
    // return params?.data?.status == null ? 'pending-row' : '';
    return {
      'pending-row': (params: any) => { return params.data?.status == null; },
      'normal-row': (params: any) => { return params.data?.status != null; }
    }
  };

  const attr = {
    title: 'Manage Order',
    buttonName: user_type == "super_admin" || rolesPermission?.order_management?.all_order?.includes("write") ? 'Add Order' : "",
    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    columns: columnDefs,
    loading,
    searchValue,
    setSearchValue,
    sortColumn,
    setSortColumn,
    onButtonClick: handleDialog,
    fetchTableData,
    handleRowClick,
    isExport,
    getRowClassName,
    isWrite: user_type == "super_admin" || rolesPermission?.order_management?.all_order?.includes("write"),
    refresh
  }
  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable  {...attr} />
      {openDialog && <AddUpdate onDownload={onDownload} setConfirmDialog={setConfirmDialog} open={openDialog} onClose={handleDialog} formData={formData}
        setFormData={setFormData} />}
      <DeleteDialog open={deleteDialog} onClose={handleDeleteDialog} deleteId={deleteId} />
      <ConfirmDialog confirmDialog={confirmDialog} onClose={handleConfirmClose} setOpenDialog={setOpenDialog} />
    </Box>
  )
}
