import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "store/api";
import { setContactUsList } from "store/Slice/contactUsSlice";
import { notificationSuccess } from "store/Slice/notificationSlice";

export const getContactUsList = createAsyncThunk(
  "getContactUsList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/contact-us?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setContactUsList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteContactUsData = createAsyncThunk(
  "deleteContactUsData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/contact-us/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);