// *** React Imports
import { SyntheticEvent, useEffect, useState } from 'react'

//MUI Imports
import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  CircularProgress,
  FormHelperText,
  Grid,
  GridProps,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'

import CheckIcon from '@mui/icons-material/CheckRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import CustomChip from '@core/components/mui/chip'

// ** Icon Imports
import Close from 'mdi-material-ui/Close'
import Plus from 'mdi-material-ui/Plus'

import MDDialog from '@core/components/MDDialog'
import Repeater from '@core/components/repeater'
import Validations from 'helper/validations'
import CustomDateInput from 'views/customeDateInput/CustomDateInput'
import { useAppDispatch, useAppSelector } from 'store/store'
import { postAllOrderData, postCashMemoData, updateAllOrderList } from 'store/Thunk/orderManagementThunk'
import { getClientList } from 'store/Thunk/clientManagmentThunk'
import { getProductList } from 'store/Thunk/masterDataThunk'
import React from 'react'
import { isoStringNextDay, isoStringPrevDay, removePlus } from 'helper/service'
import config from 'configs/config'

interface Props {
  open?: any
  onClose?: any
  formData?: any
  setFormData?: any
  setConfirmDialog?: any
  onDownload?: any
}

const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
  paddingRight: 0,
  marginTop: theme.spacing(8),
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .col-title': {
    top: '-1.5rem',
    position: 'absolute'
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.down('lg')]: {
    '& .col-title': {
      top: '0',
      position: 'relative'
    }
  }
}))

const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(5.5),
  '& .repeater-wrapper + .repeater-wrapper': {
    marginTop: theme.spacing(8)
  }
}))

const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

function Index({ open, onClose, formData, setFormData, setConfirmDialog, onDownload }: Props) {
  const user_type: any = localStorage.getItem("user_type");
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);

  const [count, setCount] = useState<number>(1)
  // const [formData, setFormData] = useState<any>({})
  const [formError, setFormError] = useState<any>({})

  const [orderDetails, setOrderDetails] = useState<any>([{
    product: "",
    description: "",
    height: "",
    width: "",
    quantity: "",
    rate: "",
    amount: "",
    gst: "18%",
    image: null
  }]);
  const [loading, setLoading] = useState<boolean>(false);

  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [openACClient, setOpenACClient] = useState<boolean>(false);
  const [isFieldDisable, setIsFieldDisable] = useState<any>([]);
  const [clientOptions, setClientOptions] = useState<any[]>([]);
  const [errorWidth, setErrorWidth] = useState(null);
  const [errorHeight, setErrorHeight] = useState(null);

  const { clientListing } = useAppSelector((state: any) => state.clientReducer);
  const { productListing } = useAppSelector((state: any) => state.masterDataReducer)
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProductList({ pageSize: 1000, page: 0 }))
    if (formData?.OrderProduct) {
      setOrderDetails(formData?.OrderProduct)
      // Assuming orderDetail is an array
      const orderDetailLength = formData?.OrderProduct?.length || 0;

      // Initialize an array with 'false' values
      const newFieldDisableArray = Array.from({ length: orderDetailLength }, () => true);

      setIsFieldDisable(newFieldDisableArray);
    }
  }, []);

  useEffect(() => {
    setClientLoading(false);
    setClientOptions(clientListing?.items ? clientListing?.items?.filter((item: any) => item?.email !== config.SUPER_ADMIN) : []);
  }, [clientListing]);

  useEffect(() => {
    if (!openACClient) {
      setClientOptions([]);
    } else {
      dispatch(getClientList({ pageSize: 1000, page: 0 }));
    }
    setClientLoading(openACClient);
  }, [openACClient]);

  useEffect(() => {
    if (formData?.client) {
      setFormData({ ...formData, products: [...orderDetails], client_name: formData?.client?.name, mobile_no: removePlus(formData?.client?.phone) });
    } else {
      setFormData({ ...formData, products: [...orderDetails] });
    }
  }, [orderDetails])

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleOnChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, value: any) => {
    if (name == "client") {
      setOrderDetails([
        {
          product: {},
          description: "",
          quantity: "",
          width: "",
          height: "",
          rate: "",
          amount: "",
          gst: "18%"
        }])
      setFormData({
        ...formData,
        [name]: value ? value : null
      })
    } else {
      setFormData({
        ...formData,
        [name]: value ? value : null
      })

    }
  }

  const handleProductChange = (e: any, i: any) => {
    let updatedProducts = JSON.parse(JSON.stringify(orderDetails));
    if (e.target.name == "image") {
      updatedProducts[i][e.target.name] = e?.target?.files[0]
    } else {
      updatedProducts[i][e.target.name] = e.target.value;
      if (e.target.name == "quantity") {
        if (updatedProducts[i]["height_width"] == "yes") {
          const incGst = Number(e.target.value) * updatedProducts[i]?.rate * updatedProducts[i]?.height * updatedProducts[i]?.width;
          updatedProducts[i]["amount"] = incGst + (incGst * 0.18);
        } else {
          const incGst = Number(e.target.value) * updatedProducts[i]?.rate;
          updatedProducts[i]["amount"] = incGst + (incGst * 0.18);
        }
      } else if (e.target.name == "width") {
        const incGst = Number(e.target.value) * updatedProducts[i]?.rate * updatedProducts[i]?.quantity * updatedProducts[i]?.height;
        updatedProducts[i]["amount"] = incGst + (incGst * 0.18);
      } else if (e.target.name == "height") {
        const incGst = Number(e.target.value) * updatedProducts[i]?.rate * updatedProducts[i]?.quantity * updatedProducts[i]?.width;
        updatedProducts[i]["amount"] = incGst + (incGst * 0.18);
      } else if (e.target.name == "image") {
        const incGst = e?.target?.files[0];
        updatedProducts[i][e.target.name] = incGst + (incGst * 0.18);
      }
    }
    // else{
    // }fsd
    setOrderDetails([...updatedProducts])
  };

  const handleOnChangeProduct = (name: string, i: any) => (event: any) => {
    const value = event.target.value;
    const find = productListing?.items?.find((item: any) => item?.id == value)

    if (find?.category && !find?.category?.is_deleted && find?.category?.name?.toUpperCase() == "OTHERS") {
      setIsFieldDisable((prevData: any) => {
        const newData = [...prevData];
        newData[i] = false;
        return newData;
      });
    } else {
      setIsFieldDisable((prevData: any) => {
        const newData = [...prevData];
        newData[i] = true;
        return newData;
      });
    }

    const updatedProducts = [...orderDetails]
    updatedProducts[i][name] = value;
    updatedProducts[i]["quantity"] = 1;
    updatedProducts[i]["rate"] = getPrice(find);
    updatedProducts[i]["height_width"] = find?.height_width;
    if (find?.height_width == "no") {
      updatedProducts[i]["height"] = 0;
      updatedProducts[i]["width"] = 0;
      updatedProducts[i]["amount"] = getPrice(find);
    } else if (find?.height_width == "yes") {
      updatedProducts[i]["height"] = 0.25;
      updatedProducts[i]["width"] = 0.25;
      updatedProducts[i]["amount"] = (getPrice(find) * 0.25 * 0.25).toString();
    }
    setOrderDetails([...updatedProducts])
  }

  // ** Deletes form
  const deleteForm = (e: SyntheticEvent, i: any) => {
    e.preventDefault()
    if (orderDetails?.length) {
      const updatedOrderDetails = orderDetails?.filter((value: any, index: any) => index !== i);

      setOrderDetails([...updatedOrderDetails]);
      let prevData = [...isFieldDisable];
      prevData.splice(i, 1);
      setIsFieldDisable([...prevData])
      // @ts-ignore
      // e.target.closest('.repeater-wrapper').remove()
    }
  }

  // ** Deletes form
  const AddForm = () => {
    setCount(count + 1)
    setOrderDetails([
      ...orderDetails,
      {
        product: {},
        description: "",
        quantity: "",
        width: "",
        height: "",
        rate: "",
        amount: "",
        gst: "18%"
      }])
  }

  const onSave = (event: any) => {

    const productArr = orderDetails.map((val: any) => ({
      product_id: val?.product_id,
      quantity: val?.quantity,
      width: val?.height_width == "yes" ? val?.width : "",
      height: val?.height_width == "yes" ? val?.height : "",
      rate: val?.rate,
      amount: val?.amount?.toString(),
      description: val?.description,
      height_width: val?.height_width,
      image: val?.image
    }))

    event.preventDefault()
    let _requestError: any = {
      client_id: formData?.client?.id,
      client_name: formData?.client_name,
      mobile_no: formData?.mobile_no,
      remarks: formData?.remarks,
      credit_limit: formData?.credit_limit,
      // credit_limit_hour: formData?.credit_limit_hour,
      order_date: formData?.order_date,
      products: productArr || []
    }

    const formDataProduct = new FormData();
    formDataProduct.append('client_id', formData?.client?.id);
    formDataProduct.append('client_name', formData.client_name);
    formDataProduct.append('mobile_no', formData.mobile_no);
    formDataProduct.append('remarks', formData.remarks);
    formDataProduct.append('credit_limit', formData.credit_limit);
    // formDataProduct.append('credit_limit_hour', formData.credit_limit_hour);
    formDataProduct.append('order_date', formData.order_date);
    orderDetails.forEach((product: any, index: any) => {
      formDataProduct.append(`products[${index}][product_id]`, product.product_id);
      formDataProduct.append(`products[${index}][quantity]`, product.quantity);
      if (product?.height_width == "yes") {
        formDataProduct.append(`products[${index}][width]`, product?.width);
        formDataProduct.append(`products[${index}][height]`, product?.height);
      }
      formDataProduct.append(`products[${index}][rate]`, product.rate);
      formDataProduct.append(`products[${index}][total_price]`, product.amount);
      formDataProduct.append(`products[${index}][description]`, product?.description || "");
      if (product?.image) {
        formDataProduct.append(`products[${index}][file]`, product?.image || "");
      }
      // formData.append(`products[${index}][height]`, product?.height || "");
    })

    // let _request: any = {
    //   client_id: formData?.client?.id,
    //   client_name: formData?.client_name,
    //   mobile_no: formData?.mobile_no,
    //   remarks: formData?.remarks,
    //   credit_limit: formData?.credit_limit,
    //   order_date: formData?.order_date,
    //   products: formDataProduct || []
    // }
    // {
    //   client_id: 7 || '',
    //   client_name: formData?.client_name || '',
    //   mobile_no: formData?.mobile_no || '',
    //   cash_memo_date: "12/12/12" || '',
    //   remarks: "hjbhjbjh" || '',
    //   credit_limit: formData?.credit_limit || '',
    //   gst_no: "12121212",
    //   invoice_no: "112",
    //   product: [
    //     {
    //         "product_id": 8,
    //         "quantity": 2,
    //         "width": 12,
    //         "height": 12,
    //         "rate": "3000",
    //         "amount": "12000"
    //     }
    // ] || []
    // }

    let allError = Validations.validateOrderForm(_requestError)
    // const temp = allError;
    if (Object.entries(allError?.products[0]).length === 0 && !errorWidth && !errorHeight) {
      delete allError['products']
    }
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {

      setLoading(true);
      if (formData?.id) {
        const id = formData?.id
        dispatch(updateAllOrderList({ formDataProduct, id })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 200) {
            onClose();
          }
        })
      } else {
        dispatch(postAllOrderData({ formDataProduct })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 201) {
            onClose();
          }
        })
      }
    } else {

      setFormError(allError)
    }
  }

  const handleWidthChange = (e: any, i: any) => {
    if ((Number(e.target.value) % (0.25)) != 0) {
      setErrorWidth(i);
    } else {
      setErrorWidth(null);
    }
  }

  const handleHeightChange = (e: any, i: any) => {
    if ((Number(e.target.value) % (0.25)) != 0) {
      setErrorHeight(i);
    } else {
      setErrorHeight(null);
    }
  }

  const getPrice = (product: any) => {
    if (product && product?.ClientProduct?.length) {
      return product?.ClientProduct[0]?.price
    } else {
      if (formData?.client?.client_category == "category_a") {
        return product?.price_A
      }
      else if (formData?.client?.client_category == "category_b") {
        return product?.price_B
      }
      else if (formData?.client?.client_category == "category_c") {
        return product?.price_C
      }
      else if (formData?.client?.client_category == "category_d") {
        return product?.price_D
      }
      else if (formData?.client?.client_category == "category_e") {
        return product?.price_E
      }
      else if (formData?.client?.client_category == "category_g") {
        return product?.price_F
      } else {
        return product?.price_A
      }
    }
  }

  const dialogProps = {
    open,
    onClose,
    loading,
    onSave,
    title: formData?.id ? `Edit Order` : `Add Order`,
    size: 'lg',
    saveTbtText: 'Save'
  }

  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACClient}
              onOpen={() => {
                setOpenACClient(true);
              }}
              onClose={() => {
                setOpenACClient(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("client")}
              value={formData?.client ? formData?.client : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={clientOptions}
              loading={clientLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError.client ? true : false}
                  helperText={formError && formError.client}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {clientLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {formError && formError?.client_id && (<FormHelperText error>{formError?.client_id}</FormHelperText>)}

          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Order Date</InputLabel>
            <CustomDateInput
              error={formError && formError?.order_date}
              helperText={formError && formError?.order_date}
              value={formData?.order_date ? isoStringPrevDay(formData?.order_date) : ""}
              onChange={(e: any) => setFormData({ ...formData, order_date: isoStringPrevDay(e) })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Client Name'
              name='client_name'
              onChange={handleChange}
              value={formData?.client_name || ''}
              error={formError && formError?.client_name}
              helperText={formError && formError?.client_name ? formError.client_name : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Mobile No</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Mobile No'
              name='mobile_no'
              onChange={handleChange}
              value={formData?.mobile_no || ''}
              error={formError && formError?.mobile_no}
              helperText={formError && formError?.mobile_no ? formError.mobile_no : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Remarks</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Remarks'
              name='remarks'
              onChange={handleChange}
              value={formData?.remarks || ''}
            // error={formError && formError?.remarks}
            // helperText={formError && formError?.remarks ? formError.remarks : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Credit Limit</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Credit Limit'
              name='credit_limit'
              onChange={handleChange}
              value={formData?.credit_limit || ''}
              error={formError && formError?.credit_limit}
              helperText={formError && formError?.credit_limit ? formError.credit_limit : ''}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Credit Limit Hour</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Credit Limit Hour'
              name='credit_limit_hour'
              onChange={handleChange}
              value={formData?.credit_limit_hour || ''}
              error={formError && formError?.credit_limit_hour}
              helperText={formError && formError?.credit_limit_hour ? formError.credit_limit_hour : ''}
            />
          </Grid> */}
          {
            formData?.id && (
              <>
                <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <InputLabel>Status: </InputLabel>

                  <Box sx={{ marginLeft: '10px' }}>
                    {formData?.status && <CustomChip
                      size='small'
                      skin='light'
                      color={formData?.status == "approved" ? "success" : "error"}
                      label={formData?.status}
                      sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }} />}
                    {user_type != "super_admin" && !rolesPermission?.order_management?.all_order?.includes("write") && (<CustomChip
                      size='small'
                      skin='light'
                      color={"info"}
                      label={"Pending"}
                      sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }} />)}

                    {!formData?.status && (<Box sx={{ mb: 1 }}>
                      <IconButton size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          setConfirmDialog({ open: true, id: formData?.id, status: "approved" });
                        }}
                        sx={{
                          width: "34px", 
                          height: "36px",
                          borderRadius: "14px", 
                          color: "#fff", 
                          backgroundColor: "#4caf50", 
                          "&:hover": {
                            backgroundColor: "#45a049"
                          }
                        }}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setConfirmDialog({ open: true, id: formData?.id, status: "decline" });
                        }}
                        sx={{
                          ml: "9px",
                          width: "34px", 
                          height: "36px",
                          borderRadius: "14px", 
                          color: "#fff", 
                          backgroundColor: "#ff5252", 
                          "&:hover": {
                            backgroundColor: "#d13a3a"
                          }
                        }}>
                        <ClearIcon />
                      </IconButton>
                    </Box>)}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                  <InputLabel>Order Image: </InputLabel>
                  <Box sx={{ marginLeft: '10px' }}>{formData?.OrderProduct[0]?.order_image ? <Button variant='outlined' sx={{ m: "-8px", margin: '5px' }} onClick={(e) => {
                    e.stopPropagation();
                    onDownload(formData?.OrderProduct[0]?.order_image, formData?.id, formData.client.file_code, formData?.client?.name);
                  }}>
                    Download
                  </Button> : <Box>--</Box>}</Box>
                </Grid>
              </>
            )
          }
        </Grid>
        <Grid container sx={{ mt: 4.75, display: 'flex', justifyContent: 'flex-end', }}>
          <Grid item xs={12} sx={{ px: 0 }}>
            <Button
              size='small'
              variant='contained'
              disabled={formData?.id}
              startIcon={<Plus fontSize='small' />}
              onClick={() => AddForm()}
            >
              Add Item
            </Button>
          </Grid>
        </Grid>
        {/* <RepeaterWrapper> */}
        {/* <Repeater count={count}> */}

        {orderDetails.map((val: any, i: any) => {
          const Tag = i === 0 ? Box : Collapse

          return (
            // <Tag key={i} className='repeater-wrapper' {...(i !== 0 ? { in: true } : {})}>
            <Grid container>
              <RepeatingContent item xs={12}>
                <Grid container sx={{ py: 4, width: '100%', pr: { lg: 0, xs: 4 } }}>
                  <Grid item lg={4} md={5} xs={12} sx={{ pl: 4, my: { lg: 0, xs: 2 } }}>
                    <Typography
                      variant='subtitle2'
                      className='col-title'
                      sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                    >
                      Product
                    </Typography>
                    <Select
                      fullWidth
                      size='small'
                      placeholder='Select Product'
                      name='product_id'
                      disabled={formData?.id}
                      onChange={handleOnChangeProduct("product_id", i)}
                      value={orderDetails[i]?.product_id ? orderDetails[i]?.product_id : {}}
                      error={formError?.products && formError?.products[i]?.product_id}
                    >
                      {
                        productListing?.items?.length > 0 && productListing?.items?.map((val: any) => (
                          <MenuItem value={val?.id}>{val.name}</MenuItem>
                        ))
                      }
                    </Select>
                    {formError?.products && formError?.products[i]?.product_id &&
                      <FormHelperText error>{formError?.products[i]?.product_id}</FormHelperText>}
                    {/* <Autocomplete
                            id="asynchronous-demo"
                            open={openACProduct}
                            onOpen={() => {
                              setOpenACProduct(true);
                            }}
                            onClose={() => {
                              setOpenACProduct(false);
                            }}
                            fullWidth
                            size='small'
                            onChange={handleOnChangeProduct("product", i)}
                            value={orderDetails[i]?.product ? orderDetails[i]?.product : null}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            getOptionLabel={(option) => option?.name}
                            options={productOptions}
                            loading={productLoading}
                            loadingText={"Loading..."}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // error={formError && formError?.product ? true : false}
                                // helperText={formError && formError?.product}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {productLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )} /> */}
                    {/* <TextField
                            rows={2}
                            fullWidth
                            multiline
                            size='small'
                            sx={{ mt: 3.5 }}
                            value={orderDetails?.length > 0 ? orderDetails[i]?.description : ""}
                            // defaultValue='Product Description'
                            name="description"
                            onChange={(e) => handleProductChange(e, i)}
                            error={formError?.products && formError?.products[i]?.description ? formError?.products[i]?.description : ""}
                            helperText={formError?.products && formError?.products[i]?.description ? formError?.products[i]?.description : ""}
                          /> */}
                  </Grid>
                  <Grid item lg={1.2} md={3} xs={12} sx={{ pl: 4, my: { lg: 0, xs: 2 } }}>
                    <Typography
                      variant='subtitle2'
                      className='col-title'
                      sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                    >
                      Quantity
                    </Typography>
                    <TextField
                      size='small'
                      type='number'
                      placeholder='1'
                      disabled={formData?.id}
                      value={orderDetails?.length > 0 ? orderDetails[i]?.quantity : ""}
                      InputProps={{ inputProps: { min: 0 } }}
                      name="quantity"
                      onChange={(e) => handleProductChange(e, i)}
                      error={formError?.products && formError?.products[i]?.quantity ? formError?.products[i]?.quantity : ""}
                      helperText={formError?.products && formError?.products[i]?.quantity ? formError?.products[i]?.quantity : ""}
                    />

                  </Grid>

                  <Grid item lg={1.2} md={6} xs={12} sx={{ pl: 4, my: { lg: 0, xs: 2 } }}>
                    <Typography
                      variant='subtitle2'
                      className='col-title'
                      sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                    >
                      Width
                    </Typography>
                    <TextField
                      size='small'
                      type='number'
                      placeholder='1'
                      disabled={orderDetails[i]["height_width"] == "no" && formData?.id}
                      // defaultValue='1'
                      onBlur={(e) => handleWidthChange(e, i)}
                      value={orderDetails?.length > 0 ? orderDetails[i]?.width : ""}
                      name="width"
                      InputProps={{ inputProps: { min: 0, step: "0.25" } }}
                      onChange={(e) => handleProductChange(e, i)}
                      error={formError?.products && formError?.products[i]?.width ? formError?.products[i]?.width : (errorWidth == i ? true : false)}
                      helperText={formError?.products && formError?.products[i]?.width ? formError?.products[i]?.width : (errorWidth == i ? "width multiply by 0.25" : "")}

                    />
                  </Grid>
                  <Grid item lg={1.2} md={1} xs={12} sx={{ pl: 4, my: { lg: 0 }, mt: 2 }}>
                    <Typography
                      variant='subtitle2'
                      className='col-title'
                      sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                    >
                      Height
                    </Typography>
                    <TextField
                      size='small'
                      type='number'
                      placeholder='1'
                      disabled={orderDetails[i]["height_width"] == "no" && formData?.id}
                      onBlur={(e: any) => handleHeightChange(e, i)}
                      // defaultValue='1'
                      name='height'
                      value={orderDetails?.length > 0 ? orderDetails[i]?.height : ""}
                      InputProps={{ inputProps: { min: 0, step: "0.25" } }}
                      onChange={(e) => handleProductChange(e, i)}
                      error={formError?.products && formError?.products[i]?.height ? formError?.products[i]?.height : (errorHeight == i ? true : false)}
                      helperText={formError?.products && formError?.products[i]?.height ? formError?.products[i]?.height : (errorHeight == i ? "Height multiply by 0.25" : "")}

                    />
                  </Grid>
                  <Grid item lg={1.2} md={2} xs={12} sx={{ pl: 4, my: { lg: 0, xs: 2 } }}>
                    <Typography
                      variant='subtitle2'
                      className='col-title'
                      sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                    >
                      Rate
                    </Typography>
                    <TextField
                      size='small'
                      type='number'
                      placeholder='1'
                      disabled
                      // defaultValue='1'
                      value={orderDetails?.length > 0 ? orderDetails[i]?.rate : ""}
                      InputProps={{ inputProps: { min: 0 } }}
                      name="rate"
                      onChange={(e) => handleProductChange(e, i)}
                      error={formError?.products && formError?.products[i]?.rate ? formError?.products[i]?.rate : ""}
                      helperText={formError?.products && formError?.products[i]?.rate ? formError?.products[i]?.rate : ""}
                    />
                  </Grid>
                  <Grid item lg={1.2} md={2} xs={12} sx={{ pl: 4, my: { lg: 0, xs: 2 } }}>
                    <Typography
                      variant='subtitle2'
                      className='col-title'
                      sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                    >
                      GST
                    </Typography>
                    <TextField
                      size='small'
                      type='text'
                      placeholder='1'
                      disabled
                      // defaultValue='1'
                      value={orderDetails[i]?.gst}
                      InputProps={{ inputProps: { min: 0 } }}
                      name="gst"
                      onChange={(e) => handleProductChange(e, i)}
                    // error={formError?.products && formError?.products[i]?.gst ? formError?.products[i]?.gst : ""}
                    // helperText={formError?.products && formError?.products[i]?.gst ? formError?.products[i]?.gst : ""}
                    />
                  </Grid>
                  <Grid item lg={2} md={1} xs={12} sx={{ px: 4, my: { lg: 0 }, mt: 2 }}>
                    <Typography
                      variant='subtitle2'
                      className='col-title'
                      sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                    >
                      Amount
                    </Typography>
                    <TextField
                      size='small'
                      type='number'
                      placeholder='1'
                      disabled={isFieldDisable[i] && formData?.id}
                      value={orderDetails?.length > 0 ? orderDetails[i]?.amount : ""}
                      // defaultValue='1'
                      InputProps={{ inputProps: { min: 0 } }}
                      name="amount"
                      onChange={(e) => handleProductChange(e, i)}
                      error={formError?.products && formError?.products[i]?.amount ? formError?.products[i]?.amount : ""}
                      helperText={formError?.products && formError?.products[i]?.amount ? formError?.products[i]?.amount : ""}
                    />
                  </Grid>
                  <Grid item lg={4} md={5} xs={12} sx={{ pl: 4, my: { lg: 0, xs: 2 } }}>
                    <TextField
                      rows={2}
                      fullWidth
                      multiline
                      size='small'
                      sx={{ mt: 3.5 }}
                      value={orderDetails?.length > 0 ? orderDetails[i]?.description : ""}
                      // defaultValue='Product Description'
                      disabled={formData?.id}
                      name="description"
                      onChange={(e) => handleProductChange(e, i)}
                      error={formError?.products && formError?.products[i]?.description ? formError?.products[i]?.description : ""}
                      helperText={formError?.products && formError?.products[i]?.description ? formError?.products[i]?.description : ""}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12} sx={{ pl: 4, pt: 4, my: { lg: 0 }, mt: 2 }}>
                    <TextField
                      fullWidth
                      type='file'
                      // size='small'
                      disabled={formData?.id}
                      placeholder='Image'
                      name='image'
                      onChange={(e) => handleProductChange(e, i)}
                      // value={orderDetails?.length > 0 ? orderDetails[i]?.image : ""}
                      error={formError?.products && formError?.products[i]?.image ? formError?.products[i]?.image : ""}
                      helperText={formError?.products && formError?.products[i]?.image ? formError?.products[i]?.image : ""}
                    // value={formData?.banner || ''}

                    />
                  </Grid>
                </Grid>
                {
                  orderDetails?.length > 1 && (
                    <InvoiceAction>
                      <IconButton size='small' onClick={(e: any) => deleteForm(e, i)}>
                        <Close fontSize='small' />
                      </IconButton>
                    </InvoiceAction>
                  )
                }
              </RepeatingContent>
            </Grid>
            // </Tag>
          )
        })}
        {/* </Repeater> */}
        {/* </RepeaterWrapper> */}
      </Box>
    </MDDialog>
  )
}

export default Index
