import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { NotificationInitialState } from 'store/interface';

interface InitialState {
	clientListing: any[],
	clientDropdownListing: any[]
	clientLoginReport: any[]
}

const initialState: InitialState = {
	clientListing: [],
	clientDropdownListing: [],
	clientLoginReport: []
};

const clientSlice = createSlice({
	name: 'clientSlice',
	initialState: initialState,
	reducers: {
		setClientList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.clientListing = action.payload
		},
		setClientDropdownList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.clientDropdownListing = action.payload
		},
		setClientLoginReport: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.clientLoginReport = action.payload
		},
		
	}
});

export const { 
	setClientList,
	setClientDropdownList,
	setClientLoginReport
} =
clientSlice.actions;

export default clientSlice.reducer;
