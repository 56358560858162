// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Grid, { GridProps } from '@mui/material/Grid'

// ** Hook
import { useSettings } from '@core/hooks/useSettings'
import { getAuthUser } from 'helper/service'

// Styled Grid component
const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    order: -1,
    display: 'flex',
    justifyContent: 'center'
  }
}))

// Styled component for the image
const Img = styled('img')(({ theme }) => ({
  right: 0,
  bottom: 0,
  width: 298,
  position: 'absolute',
  [theme.breakpoints.down('sm')]: {
    width: 250,
    position: 'static'
  }
}))

const AnalyticsCongratulations = () => {
  // ** Hook
  const { settings } = useSettings();

  const username = localStorage.getItem("username") ? localStorage.getItem("username") : "";

  return (
      <Card sx={{ position: 'relative' }}>
        <CardContent sx={{ p: theme => `${theme.spacing(6.75, 7.5)} !important` }}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={7} sx={{ pb: 2 }}>
              <Typography variant='h5' sx={{ mb: 4.5 }}>
                Congratulations{' '}
                <Box component='span' sx={{ fontWeight: 'bold' }}>
                  {username}
                </Box>
                ! 🎉
              </Typography>
              <Typography variant='subtitle2'>
                Great job today! 🌟 Explore your profile for exciting updates.
              </Typography>
              <Typography sx={{ mb: 4.5 }} variant='subtitle2'>
                Keep up the excellent work! 😎👏
              </Typography>
              <Box sx={{ p: 4 }}></Box>
            </Grid>
            <StyledGrid item xs={12} sm={4}>
              <Img alt='Congratulations John' src={`/images/cards/illustration-john-${settings.mode}.png`} />
            </StyledGrid>
          </Grid>
        </CardContent>
      </Card>
  )
}

export default AnalyticsCongratulations
