import { Autocomplete, Box, Card, CircularProgress, Grid, InputLabel, TextField } from '@mui/material'
import Validations from 'helper/validations';
import React, { useEffect, useState } from 'react';
import { getClientList } from 'store/Thunk/clientManagmentThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import SummaryTable from './tabs/summary-tab';
import DetailedTable from './tabs/detailed-tab';

export default function Demo() {
  const [formError, setFormError] = useState<any>({})

  const [formData, setFormData] = useState<any>({
    type: "",
    client: ""
  });

  const dispatch = useAppDispatch();
  const [value, setValue] = useState('1');
  const [loading, setLoading] = useState<boolean>(false);
  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [openACClient, setOpenACClient] = useState<boolean>(false);
  const [clientOptions, setClientOptions] = useState<any[]>([]);

  const { clientListing } = useAppSelector((state: any) => state.clientReducer);

  useEffect(() => {
    setClientLoading(false);
    setClientOptions(clientListing?.items ? clientListing?.items : []);
  }, [clientListing]);

  useEffect(() => {
    if (!openACClient) {
      setClientOptions([]);
    } else {
      dispatch(getClientList({ pageSize: 1000, page: 0 }));
    }
    setClientLoading(openACClient);
  }, [openACClient]);

  const handleOnChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, value: any) => {
    console.log("value ", value);
    setFormData({
      ...formData,
      [name]: value ? value : null
    })
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleOnClick = (event: any) => {
    event.preventDefault()
    let _request: any = {
      client_id: formData?.client?.id,
      type_id: formData?.type?.id,
    }

    let allError = Validations.validateOutstandingForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      setLoading(true);
    } else {
      setFormError(allError)
    }
  }

  const handleClientIdChange = (value: any) => {
    setFormData({
      ...formData,
      client: value ? value : null
    })
  }

  const handleRowClick = (rowValue: any) => {
    dispatch(getClientList({ pageSize: 1000, page: 0 }));
    setValue("2");
    setFormData({
      ...formData,
      client: rowValue?.data
    })
  }

  return (
    <Box >
      <Card>

        <>
          <TabContext value={value}  >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>

              <Grid container>
                <Grid item md={6} sm={12} sx={{ padding: 2 }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: 4, paddingRight: 4, mt: 5 }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="Summary" value="1" />
                      <Tab label="Detailed" value="2" />
                    </TabList>
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} sx={{ padding: 4, display: 'flex', justifyContent: 'flex-end' }}>
                  {value == '2' && <Box>

                    <InputLabel>Client</InputLabel>
                    <Autocomplete
                      id="asynchronous-demo"
                      placeholder='Select Client'
                      open={openACClient}
                      onOpen={() => {
                        setOpenACClient(true);
                      }}
                      sx={{ minWidth: '300px' }}
                      onClose={() => {
                        setOpenACClient(false);
                      }}
                      fullWidth
                      size='small'
                      onChange={handleOnChange("client")}
                      value={formData.client ? formData.client : null}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      getOptionLabel={(option) => option?.name}
                      options={clientOptions}
                      loading={clientLoading}
                      loadingText={"Loading..."}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={formError && formError.client_id ? true : false}
                          helperText={formError && formError.client_id}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {clientLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />

                  </Box>}
                </Grid>
              </Grid>
            </Box>
            <TabPanel value="1"><SummaryTable setValue={setValue} handleClientIdChange={handleClientIdChange} handleRowClick={handleRowClick} /></TabPanel>
            <TabPanel value="2"><DetailedTable client_id={formData?.client?.id} /></TabPanel>
          </TabContext>
        </>
      </Card>
    </Box>
  )
}
