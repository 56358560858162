// ** React Imports
import { useState } from 'react'

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import { styled } from '@mui/material/styles'

// ** Icons Imports
import ArrowUp from 'mdi-material-ui/ArrowUp'

// ** Theme Config Import
import themeConfig from 'configs/themeConfig'

// ** Type Import
import { LayoutProps, NavGroup, NavLink, NavSectionTitle } from '@core/layouts/types'

// ** Components
import ScrollToTop from '@core/components/scroll-to-top'
import AppBar from './components/vertical/appBar'
import Navigation from './components/vertical/navigation'
import { useAppSelector } from 'store/store'
import { useLocation } from 'react-router-dom'
import Error401 from 'views/notAccess/401'

// ** Styled Component

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

interface Temp {
  [key: string]: string[] | Temp;
}

function findKeysWithRead(obj: Temp, result: string[] = [], currentPath: string[] = []): string[] {
  
  for (const [key, value] of Object.entries(obj)) {
    const newPath = currentPath.concat(key);

    if (Array.isArray(value) && value.includes("read")) {
      result.push(newPath.join("."));
    } else if (typeof value === "object" && value !== null) {
      findKeysWithRead(value as Temp, result, newPath);
    }
  }

  return result;
}

function isPathExist(menuItems: any, targetPath: any) {
  if (targetPath === "/dashboard") {
    return true;
  }
  for (const menuItem of menuItems) {
    if (menuItem.path === targetPath) {
      return true; // Direct path found
    }

    if (menuItem.children && menuItem.children.length > 0) {
      for (const childItem of menuItem.children) {
        if (childItem.path === targetPath) {
          return true; // Path found in children
        }
      }
    }
  }

  return false; // Path not found
}

const VerticalLayout = (props: LayoutProps) => {
  // ** Props
  const { settings, children, scrollToTop, verticalNavItems } = props

  // ** Vars
  const { skin, navHidden, contentWidth } = settings
  const { navigationSize, collapsedNavigationSize } = themeConfig
  const navWidth = navigationSize
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0
  const collapsedNavWidth = collapsedNavigationSize

  // ** States
  const [navHover, setNavHover] = useState<boolean>(false)
  const [navVisible, setNavVisible] = useState<boolean>(false)

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible)

  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  // const user_id: any = localStorage.getItem("user_id");
  const user_type: any = localStorage.getItem("user_type");
  const location = useLocation();

  const temp = findKeysWithRead(rolesPermission)?.map((item: any) => item?.split(".")[0])?.concat(findKeysWithRead(rolesPermission)?.map((item: any) => item?.split(".")[1]));
  
  //  const filter: any = temp?.length ? verticalNavItems?.filter((item: any) => temp?.includes(item?.subject)) : [];
  const filteredNavItems = user_type == "super_admin" ? verticalNavItems : Object.keys(rolesPermission).length ? verticalNavItems?.filter((item: any, index: number) => {
    if (item?.title == "Dashboard") {
      return item
    } else if (temp?.includes(item?.subject)) {
      return item
    }
  }) : [];

  const isAccessPage = isPathExist(filteredNavItems, location.pathname);

  return (
    <>
      <VerticalLayoutWrapper className='layout-wrapper'>
        {/* Navigation Menu */}
        {navHidden &&
          themeConfig.layout === 'vertical' &&
          !(navHidden && settings.lastLayout === 'horizontal') ? null : (
          <Navigation
            navWidth={navWidth}
            navHover={navHover}
            navVisible={navVisible}
            setNavHover={setNavHover}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            {...props}
            verticalNavItems={filteredNavItems}
          />
        )}
        <MainContentWrapper className='layout-content-wrapper'>
          {/* AppBar Component */}
          <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />

          {/* Content */}
          <ContentWrapper
            className='layout-page-content'
            sx={{
              padding: "30px",
              ...(contentWidth === 'boxed' && {
                mx: 'auto',
                '@media (min-width:1440px)': { maxWidth: 1440 },
                '@media (min-width:1200px)': { maxWidth: '100%' }
              })
            }}
          >
            {isAccessPage ? children : <Error401/>}
          </ContentWrapper>
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )}
    </>
  )
}

export default VerticalLayout
