import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { notificationSuccess } from "store/Slice/notificationSlice";
import { setAllOderList, setCashMemoList, setHistoryList, setOutstandingDetailedList, setOutstandingList, setOutstandingSummaryList } from "store/Slice/orderManagementSlice";

   /*--------------------------------- All Order Apis ---------------------------------------------------*/

export const getAllOrderList = createAsyncThunk(
  "getAllOrderList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/order?page=${page + 1}&limit=${pageSize}` + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setAllOderList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postAllOrderData = createAsyncThunk(
  "postAllOrderData",
  async ({ formDataProduct }: any, { dispatch }) => {
    
    try {
      let url = `/api/v1/order`;
      const response: any = await API.post(
        url,
        formDataProduct
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateAllOrderList = createAsyncThunk(
  "updateAllOrderList",
  async ({ formDataProduct, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/order/${id}`;
      const response: any = await API.patch(
        url,
        formDataProduct
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteAllOrderData = createAsyncThunk(
  "deleteAllOrderData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/order/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postConfirmData = createAsyncThunk(
  "postConfirmData",
  async ({ _request, id }: any, { dispatch }) => {
    
    try {
      let url = `/api/v1/order/update_status/${id}`;
      const response: any = await API.post(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

/*--------------------------------- CashMemo Apis ---------------------------------------------------*/

export const getCashMemoList = createAsyncThunk(
  "getCashMemoList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/cash-memo?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setCashMemoList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postCashMemoData = createAsyncThunk(
  "postCashMemoData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/cash-memo`;
      const response: any = await API.post(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateCashMemoList = createAsyncThunk(
  "updateCashMemoList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/cash-memo/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteCashMemoData = createAsyncThunk(
  "deleteCashMemoData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/cash-memo/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

/*--------------------------------- Outstanding Apis ---------------------------------------------------*/

export const getOutstandingList = createAsyncThunk(
  "getOutstandingList",
  async ({ pageSize, page, q, sort, column, navigate }: any, { dispatch }) => {
    try {
      let url = `/api/v1/outstanding?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setOutstandingList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postOutstandingData = createAsyncThunk(
  "postOutstandingData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/outstanding`;
      const response: any = await API.post(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateOutstandingList = createAsyncThunk(
  "updateOutstandingList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/outstanding/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteOutstandingData = createAsyncThunk(
  "deleteOutstandingData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/outstanding/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

/*--------------------------------- History Apis ---------------------------------------------------*/

export const getHistoryList = createAsyncThunk(
  "getHistoryList",
  async ({ pageSize, page, q, sort, column, filterModel, order_id, client_id, start_date, end_date }: any, { dispatch }) => {
    try {
      console.log("order_id, client_id, start_date, end_date", order_id, client_id, start_date, end_date);
      let url = `/api/v1/allhistory/order?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``) 
      + (order_id ? `&order_id=${order_id}` : ``) + (client_id ? `&client_id=${client_id}` : ``) + (start_date ? `&start_date=${start_date}` : ``) + (end_date ? `&end_date=${end_date}` : ``);
      const response: any = await API.get(
        url
      );
      dispatch(setHistoryList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postHistoryData = createAsyncThunk(
  "postHistoryData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/history`;
      const response: any = await API.post(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateHistoryList = createAsyncThunk(
  "updateHistoryList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/history/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteHistoryData = createAsyncThunk(
  "deleteHistoryData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/history/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const getSummaryOutstandingList = createAsyncThunk(
  "getHistoryList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/outstanding/summary?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
        );
      dispatch(setOutstandingSummaryList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const getDetailedOutstandingList = createAsyncThunk(
  "getHistoryList",
  async ({ pageSize, page, q, sort, column, filterModel, clientId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/outstanding/detailed/client/${clientId}?page=${page + 1}&limit=${pageSize}` + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
        );
      dispatch(setOutstandingDetailedList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);