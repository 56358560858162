import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	acEntryListing: any[]
}

const initialState: InitialState = {
	acEntryListing: []
};

const accountEntrySlice = createSlice({
	name: 'accountEntrySlice',
	initialState: initialState,
	reducers: {
		setAccountEntryList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.acEntryListing = action.payload
		},
		
	}
});

export const { 
	setAccountEntryList
} =
accountEntrySlice.actions;

export default accountEntrySlice.reducer;
