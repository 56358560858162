// import { ProtectedRouteProps } from "common/interface/interface";
import { Navigate } from "react-router-dom";
import { ProtectedRouteProps } from "store/interface";
import { useAppSelector } from "store/store";

interface Temp {
  [key: string]: string[] | Temp;
}


const ProtectedRoute = ({
  children,
}: ProtectedRouteProps) => {

  const isAuthenticated = localStorage.getItem("userData");

  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  console.log('user_type123 user_type == "super_admin", user_type');


  console.log("rolesPermission", rolesPermission);

  if (!isAuthenticated) {
    return <Navigate to={"/"} replace />;
  }

  return children;
};


export default ProtectedRoute;