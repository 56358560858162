// *** React Imports
import { SyntheticEvent, useState } from 'react'

//MUI Imports
import {
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  Grid,
  GridProps,
  InputLabel,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import MDDialog from '@core/components/MDDialog'
import Validations from 'helper/validations'
import { postAreaData, postPaperTypeData, updateAreaList, updatePaperTypeData } from 'store/Thunk/masterDataThunk'
import { useAppDispatch } from 'store/store'

interface Props {
  open?: any
  onClose?: any
  formData?: any
  setFormData?: any
}

const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(5.5),
  '& .repeater-wrapper + .repeater-wrapper': {
    marginTop: theme.spacing(8)
  }
}))

const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

function Index({ open, onClose, formData, setFormData }: Props) {
  const [count, setCount] = useState<number>(1)
  // const [formData, setFormData] = useState<any>({})
  const [formError, setFormError] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  // ** Deletes form
  const deleteForm = (e: SyntheticEvent) => {
    e.preventDefault()

    // @ts-ignore
    e.target.closest('.repeater-wrapper').remove()
  }

  const onSave = (event: any) => {
    event.preventDefault()
    let _request: any = {
      name: formData?.name || '',
    }

    let allError = Validations.validatePaperTypeForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      setLoading(true);
      if (formData?.id) {
        const id = formData?.id
        dispatch(updatePaperTypeData({ _request, id })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 200) {
            onClose();
          }
        })
      } else {
        dispatch(postPaperTypeData({ _request })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 201) {
            onClose();
          }
        })
      }
    } else {
      setFormError(allError)
    }
  }

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: formData?.id ? `Edit Paper Type` : `Add Paper Type`,
    size: 'md',
    saveTbtText: 'Save',
    loading
  }

  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Paper Type</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Paper Type'
              name='name'
              onChange={handleChange}
              value={formData?.name || ''}
              error={formError && formError?.name}
              helperText={formError && formError?.name ? formError.name : ''}
            />
          </Grid>

        </Grid>
      </Box>
    </MDDialog>
  )
}

export default Index
