// ** Types Import
import { Settings } from '@core/context/settingsContext'
import { NavGroup, NavLink, NavSectionTitle, VerticalNavItemsType } from '@core/layouts/types'

// ** Custom Menu Components
import VerticalNavGroup from './VerticalNavGroup'
import VerticalNavLink from './VerticalNavLink'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'
import { useAppSelector } from 'store/store'

interface Props {
  parent?: NavGroup
  navHover?: boolean
  settings: Settings
  navVisible?: boolean
  groupActive: string[]
  isSubToSub?: NavGroup
  currentActiveGroup: string[]
  navigationBorderWidth: number
  verticalNavItems?: VerticalNavItemsType
  saveSettings: (values: Settings) => void
  setGroupActive: (value: string[]) => void
  setCurrentActiveGroup: (item: string[]) => void
}

const resolveNavItemComponent = (item: NavGroup | NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle
  if ((item as NavGroup).children) return VerticalNavGroup

  return VerticalNavLink
}

const VerticalNavItems = (props: Props) => {
  // ** Props
  const { verticalNavItems } = props
//   const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
//   const user_id: any =  localStorage.getItem("user_id");
  
//   const temp = findKeysWithRead(rolesPermission)?.map((item: any) => item?.split(".")[0])?.concat(findKeysWithRead(rolesPermission)?.map((item: any) => item?.split(".")[1]));
// //  const filter: any = temp?.length ? verticalNavItems?.filter((item: any) => temp?.includes(item?.subject)) : [];
  const RenderMenuItems = verticalNavItems?.map((item: NavGroup | NavLink | NavSectionTitle, index: number) => {
    // if(user_id == 1){
    //   const TagName: any = resolveNavItemComponent(item)
    //   return <TagName {...props} key={index} item={item} />
    // }else if(temp?.includes(item?.subject)){
    //   const TagName: any = resolveNavItemComponent(item)
    //   return <TagName {...props} key={index} item={item} />
    // }
    const TagName: any = resolveNavItemComponent(item)
    return <TagName {...props} key={index} item={item} />
  })

  return <>{RenderMenuItems}</>
}

export default VerticalNavItems
