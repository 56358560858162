// *** React Imports
import React, { SyntheticEvent, useEffect, useState } from 'react'

//MUI Imports
import {
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  Divider,
  Grid,
  GridProps,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  Autocomplete,
  CircularProgress
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'

// ** Icon Imports
import Close from 'mdi-material-ui/Close'
import Plus from 'mdi-material-ui/Plus'

import MDDialog from '@core/components/MDDialog'
import Repeater from '@core/components/repeater'
import Validations from 'helper/validations'
import CustomDateInput from 'views/customeDateInput/CustomDateInput'
import { useAppDispatch, useAppSelector } from 'store/store'
import { postCashMemoData, updateCashMemoList } from 'store/Thunk/orderManagementThunk'
import { getClientDrodownList, getClientList } from 'store/Thunk/clientManagmentThunk'
import { getProductDropdownList, getProductList } from 'store/Thunk/masterDataThunk'
import { isoStringNextDay, isoStringPrevDay } from 'helper/service'

interface Props {
  open?: any
  onClose?: any
  filterData?: any
  setFilterData?: any
}

function Index({ open, onClose, filterData, setFilterData }: Props) {
  // const [count, setCount] = useState<number>(1)
  const [formData, setFormData] = useState<any>({
    start_date: (new Date()).toISOString(),
    end_date: (new Date()).toISOString()
  })
  const [formError, setFormError] = useState<any>({})
  const dispatch = useAppDispatch();

  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [openACClient, setOpenACClient] = useState<boolean>(false);
  const [clientOptions, setClientOptions] = useState<any[]>([]);

  const { clientListing } = useAppSelector((state: any) => state.clientReducer);

  useEffect(() => {
    if (Object.keys(filterData).length) {
      setFormData(filterData);
    }
  }, [filterData]);

  useEffect(() => {
    setClientLoading(false);
    setClientOptions(clientListing?.items ? clientListing?.items : []);
  }, [clientListing]);

  useEffect(() => {
    if (!openACClient) {
      setClientOptions([]);
    } else {
      dispatch(getClientList({ pageSize: 1000, page: 0 }));
    }
    setClientLoading(openACClient);
  }, [openACClient]);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleOnChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setFormData({
      ...formData,
      [name]: value ? value : null
    })
  }

  const onSave = (event: any) => {
    event.preventDefault();
    // let _request: any = {
    //   client_id: formData?.client?.id || '',
    //   order_id: formData?.order_id || '',
    //   start_date: formData?.start_date || '',
    //   end_date: formData?.end_date || '',
    // }
    setFilterData(formData);
    onClose();
  }

  const onClear = (event: any) => {
    event.preventDefault();
    setFilterData({});
    onClose();
  } 

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: `Apply Filter`,
    size: 'md',
    onClear,
    saveTbtText: 'Apply'
  }

  return (
    <MDDialog  {...dialogProps} >
      <Box p={4} >
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Order Id</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Order Id'
              name='order_id'
              onChange={handleChange}
              value={formData?.order_id || ''}
              error={formError && formError?.order_id}
              helperText={formError && formError?.order_id ? formError.order_id : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACClient}
              onOpen={() => {
                setOpenACClient(true);
              }}
              onClose={() => {
                setOpenACClient(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("client")}
              value={formData?.client ? formData?.client : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={clientOptions}
              loading={clientLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError.client ? true : false}
                  helperText={formError && formError.client}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {clientLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CustomDateInput value={formData?.start_date ? isoStringPrevDay(formData?.start_date) : ""} onChange={(e: any) => setFormData({ ...formData, start_date: isoStringPrevDay(e) })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CustomDateInput value={formData?.end_date ? isoStringPrevDay(formData?.end_date) : ""} onChange={(e: any) => setFormData({ ...formData, end_date: isoStringPrevDay(e) })} />
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  )
}

export default Index
