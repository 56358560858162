// import { AuthRouteProps } from "common/interface/interface";
import { Navigate } from "react-router-dom";
import { AuthRouteProps } from "store/interface";

const AuthRoute = ({ children, path }: AuthRouteProps) => {
  const isAuthenticated = localStorage.getItem("userData");
  if (isAuthenticated) {
    return <Navigate to={"/dashboard"} replace />;
  }
  return children;
};

export default AuthRoute;
