import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { notificationSuccess } from "store/Slice/notificationSlice";
import { setAccountEntryList } from "store/Slice/accountEntrySlice";

export const getAccountEntryList = createAsyncThunk(
  "getAccountEntryList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/ac_entry?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setAccountEntryList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postAccountEntryList = createAsyncThunk(
  "postAccountEntryList",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/ac_entry`;
      const response: any = await API.post(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateAccountEntryList = createAsyncThunk(
  "updateAccountEntryList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/ac_entry/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteAccountEntryData = createAsyncThunk(
  "deleteAccountEntryData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/ac_entry/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateAccountEntryStatus = createAsyncThunk(
  "updateAccountEntryList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/ac_entry/update_status/${id}`;
      const response: any = await API.post(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);
