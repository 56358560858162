import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	productListing: any[],
	productDropdownListing: any[],
	areaListing: any[],
	categoryListing: any[],
	clientProductList: any[],
	paperTypeList: any[],
	laminationList: any[]
}

const initialState: InitialState = {
	productListing: [],
	productDropdownListing: [],
	areaListing: [],
	categoryListing: [],
	clientProductList: [],
	paperTypeList: [],
	laminationList: [],
};

const masterDataSlice = createSlice({
	name: 'masterDataSlice',
	initialState: initialState,
	reducers: {
		setProductList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.productListing = action.payload
		},
		setProductDropdownList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.productDropdownListing = action.payload
		},
		setAreaList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.areaListing = action.payload
		},
		setCategoryList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.categoryListing = action.payload
		},
		setClientProductList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.clientProductList = action.payload
		},
		setPaperTypeList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.paperTypeList = action.payload
		},
		setLaminationList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.laminationList = action.payload
		},
	}
});

export const { 
	setProductList,
	setProductDropdownList,
	setAreaList,
	setCategoryList,
	setClientProductList,
	setPaperTypeList,
	setLaminationList
} =
masterDataSlice.actions;

export default masterDataSlice.reducer;
