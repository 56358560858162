import 'react-perfect-scrollbar/dist/css/styles.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'

import { SettingsConsumer, SettingsProvider } from '@core/context/settingsContext'
import ThemeComponent from '@core/theme/ThemeComponent'
import themeConfig from 'configs/themeConfig'
import Dashboard from 'pages/dashboard'
import Login from 'pages/dashboard/Authentication/login'
import Register from 'pages/dashboard/Authentication/register'
import AllOrder from 'pages/order-managment/all-order'
import CashMemo from 'pages/order-managment/cash-memo'
import Outstanding from 'pages/order-managment/outstanding'
import History from 'pages/order-managment/history'
import AllClient from 'pages/client-managment/all-client'
import ClientLoginReport from 'pages/client-managment/client-login-report'
import Staff from 'pages/staff-managment'
import Area from 'pages/master-data/area'
import Category from 'pages/master-data/category'
import ClientProduct from 'pages/master-data/client-products'
import Product from 'pages/master-data/products'
import PaperType from 'pages/master-data/paper-type'
import Lamination from 'pages/master-data/lamination'
import RolePermission from 'pages/master-data/roles-permission'

import AcEntry from 'pages/ac-entry'
import Inquiry from 'pages/inquiry'
import UserLayout from './layouts/UserLayout'
import AuthRoute from 'views/screenLayout/auth-route'
import ProtectedRoute from 'views/screenLayout/protected-route'
import Notification from 'views/notification/notification'
import { useEffect, useState } from 'react'
import { NotificationType } from 'store/interface'
import { notificationClear } from 'store/Slice/notificationSlice'
import { useAppDispatch, useAppSelector } from 'store/store'
import ContactUs from 'pages/contact-us'
import { getStaffListById } from 'store/Thunk/staffManagmentThunk'

const initialState: NotificationType = {
  message: "",
  status: false,
  type: "",
  position: "",
};

function App(props: any) {
  const { Component } = props
  const setConfig = Component?.setConfig ?? undefined
  let notificationInfo = useAppSelector(
    (state: any) => state.notificationReducer
  );
  const [notificationData, setNotificationData] = useState<NotificationType>(initialState);
  const dispatch = useAppDispatch();

  const clearNotificationMsg = () => {
    setTimeout(() => {
      dispatch(notificationClear());
    }, 5000);
    return true;
  };

  useEffect(() => {
    if (notificationInfo) {
      setNotificationData(notificationInfo);
    }
  }, [notificationInfo]);

  useEffect(() => {
    if (window.location.pathname !== "/") {
      const id = Number(localStorage.getItem("user_id"));
      const usertype = localStorage.getItem("user_type");
      console.log("user_type1", usertype);
      
      dispatch(getStaffListById({id, usertype}))
    }
  },[])

  return (
    <BrowserRouter>
      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
        <head>
          <title>{`${themeConfig.templateName} | Admin Panel`}</title>
          <meta name='description' content='Let us manage your school admin data' />
          <meta name='keywords' content='Material Design, MUI, Admin Template, React Admin Template' />
          <meta name='viewport' content='initial-scale=1, width=device-width' />
        </head>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                {notificationData?.message && (
                  <>
                    <Notification
                      position={notificationData?.position}
                      msg={notificationData?.message}
                      status={notificationData?.status ? "success" : "error"}
                    />
                    {clearNotificationMsg()}
                  </>
                )}
                <Routes>
                  <Route
                    path='/dashboard'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <Dashboard />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />

                  {/* ---------------------order Managment ---------------------------------------------*/}
                  <Route
                    path='/order/all'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <AllOrder />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/order/memo'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <CashMemo />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/order/outstanding'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <Outstanding />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/order/history'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <History />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />

                  {/* ---------------------------------Client Managment -----------------*/}

                  <Route
                    path='/client/all'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <AllClient />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/client/report'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <ClientLoginReport />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />


                  {/* -------------------------------------------Staff Management ----------------------------------------*/}
                  <Route
                    path='/staff'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <Staff />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />

                  {/* -------------------------------------------Master Data ----------------------------------------*/}
                  <Route
                    path='/master/area'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <Area />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path='/master/category'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <Category />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/master/client-product'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <ClientProduct />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/master/products'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <Product />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/master/paper-type'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <PaperType/>
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/master/lamination'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <Lamination/>
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path='/master/roles-permission'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <RolePermission />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />


                  {/* --------------------------------A/C entry -----------------------------------------------*/}
                  <Route
                    path='/ac-entry'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <AcEntry />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />

                  {/* --------------------------------Inquiry -----------------------------------------------*/}
                  <Route
                    path='/inquiry'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <Inquiry />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />

                  {/* --------------------------------Contact Us -----------------------------------------------*/}
                  <Route
                    path='/contact-us'
                    element={
                      <ProtectedRoute>
                        <UserLayout>
                          <ContactUs />
                        </UserLayout>
                      </ProtectedRoute>
                    }
                  />


                  <Route
                    path='/'
                    element={
                      // <UserLayout>
                      <AuthRoute>
                        <Login />
                      </AuthRoute>
                      // </UserLayout>
                    }
                  />
                  <Route
                    path='/register'
                    element={
                      <AuthRoute>
                        <Register />
                      </AuthRoute>
                    }
                  />
                </Routes>
              </ThemeComponent>
            )
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </BrowserRouter>
  )
}

export default App
