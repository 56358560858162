import { SortType } from "@core/utils/types"
import { Box, Typography } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import moment from "moment-timezone"
import { useCallback, useEffect, useState } from "react"
import { getDetailedOutstandingList, getSummaryOutstandingList } from "store/Thunk/orderManagementThunk"
import { useAppDispatch, useAppSelector } from "store/store"
import AgGridSelectTable from "views/table/AgGridSelectTable"
import TableServerSide from "views/table/TableServerSide"


function Index({ client_id }: any) {
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([]) // set data from the json object
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const dispatch = useAppDispatch();

  const { outstandingDetailedList } = useAppSelector((state: any) => state.orderManagementReducer);
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.order_management?.outstanding?.includes("export");

  useEffect(() => {
    if (outstandingDetailedList && outstandingDetailedList?.items) {
      setRows(outstandingDetailedList?.items);
      setTotal(outstandingDetailedList?.meta?.total);
    }
  }, [outstandingDetailedList]);

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      width: "100px",
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      field: "createdAt",
      headerName: "Date",
      valueFormatter: (p: any) => p.value ? moment(p.value).format('DD/MM/YYYY') : "-",
      filter: "agDateColumnFilter",
    },
    {
      field: "payment_type",
      headerName: "Transaction Type",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.acEntry?.payment_type?.toUpperCase() || ""
      }
    },
    {
      field: "journal_item_name",
      headerName: "Journal Name",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.acEntry?.journal_item_name || ""
      }
    },
    {
      width: "140px",
      field: "debit",
      cellStyle: { textAlign: "right" },
      headerName: "Debit",
      filter: 'agNumberColumnFilter',
    },
    {
      width: "140px",
      field: "credit",
      cellStyle: { textAlign: "right" },
      headerName: "Credit",
      filter: 'agNumberColumnFilter',
    },
    {
      field: "balance",
      headerName: "Balance",
      filter: 'agTextColumnFilter',
    },
  ]);

  // const columns: GridColDef[] = [
  //   {
  //     // flex: 0.1,
  //     maxWidth: 90,
  //     field: 'id',
  //     headerName: 'Id',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params
  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {params.row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     // flex: 0.175,
  //     // minWidth: 120,
  //     flex: 0.1,
  //     headerName: 'Date',
  //     field: 'date',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row?.date || "-"}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.175,
  //     // minWidth: 110,
  //     flex: 0.15,
  //     field: 'payment_type',
  //     headerName: 'Transaction Type',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //         {params.row?.payment_type || "-"}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.125,
  //     flex: 0.15,
  //     field: 'journal_name',
  //     minWidth: 80,
  //     headerName: 'Journal Name',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row?.journal_name || "-"}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.125,
  //     flex: 0.15,
  //     field: 'debit',
  //     minWidth: 80,
  //     headerName: 'Debit',
  //     type: "number",
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.debit}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.125,
  //     flex: 0.15,
  //     field: 'credit',
  //     minWidth: 80,
  //     headerName: 'Credit',
  //     type: "number",
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.credit}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.125,
  //     flex: 0.15,
  //     field: 'balance',
  //     minWidth: 80,
  //     headerName: 'Balance',
  //     type: "number",
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.balance}
  //       </Typography>
  //     )
  //   }
  // ]

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "", clientId: any = null) => {
      // setLoading(true)
      if (clientId) {

        return dispatch(getDetailedOutstandingList({ clientId, pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : ""})).then((res: any) => {
          // setLoading(false)
          return res.payload.data
        })
      }
    },
    []
  )

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const attr = {
    title: 'Manage Detailed',
    // buttonName: 'Add Order',
    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    columns: columnDefs,
    // loading,
    searchValue,
    setSearchValue,
    sortColumn,
    setSortColumn,
    // onButtonClick: handleDialog,
    fetchTableData,
    getRowClassName,
    isShadow: true,
    isExport,
    isWrite: false,
    client_id
  }

  return (
    <Box >
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable  {...attr} />
    </Box>
  )
}

export default Index
