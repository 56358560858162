import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export default function CustomDateInput({ onChange, value, error = false, helperText = "" }: any) {
    return (<LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
            onChange={(e) => onChange(e)}
            format='DD/MM/YYYY'
            value={value ? dayjs(value) : value} sx={{ width: '100%' }}
            slotProps={{
                textField: {
                    size: 'small', error: error, helperText: helperText ? helperText : ""
                }
            }}
        />
    </LocalizationProvider>)
}