import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from 'store/Slice/AuthSlice';
import notificationReducer from 'store/Slice/notificationSlice';
import clientReducer from 'store/Slice/clientSlice';
import masterDataReducer from 'store/Slice/masterDataSlice';
import staffReducer from 'store/Slice/staffManagmentSlice';
import acEntryReducer from 'store/Slice/accountEntrySlice';
import orderManagementReducer from 'store/Slice/orderManagementSlice';
import contactUsReducer from 'store/Slice/contactUsSlice';
import inquiryReducer from 'store/Slice/inquirySlice';
import rolePermissionReducer from 'store/Slice/rolePermissionSlice';
import dashboardReducer from 'store/Slice/DashboardSlice';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const combinedReducer = combineReducers({
  authReducer,
  notificationReducer,
  clientReducer,
  masterDataReducer,
  staffReducer,
  acEntryReducer,
  orderManagementReducer,
  contactUsReducer,
  inquiryReducer,
  rolePermissionReducer,
  dashboardReducer
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
