import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	contactUsListing: any[]
}

const initialState: InitialState = {
	contactUsListing: []
};

const contactUsSlice = createSlice({
	name: 'contactUsSlice',
	initialState: initialState,
	reducers: {
		setContactUsList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.contactUsListing = action.payload
		},
		
	}
});

export const { 
	setContactUsList
} =
contactUsSlice.actions;

export default contactUsSlice.reducer;
