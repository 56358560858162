import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { notificationSuccess } from "store/Slice/notificationSlice";
import { setStaffList } from "store/Slice/staffManagmentSlice";
import { setRolePermission } from "store/Slice/rolePermissionSlice";

const permissionToObject = (array: any) => {
  const a = array?.map((item: any) => { 
    return item.name;
})

const inputArray = a.map((item: any) => {
    return item.split("::")
})

const outputJson: any = {};

inputArray.forEach((item: any) => {
    const [mainKey, subKey, action] = item;
    const actionArray = ['read', 'write', 'delete', 'export'];
    if (!outputJson[mainKey] ) {
        outputJson[mainKey] = actionArray.includes(subKey) ? [] : {};
    }
    if (!Array.isArray(outputJson[mainKey]) && !outputJson[mainKey][subKey]) {
        outputJson[mainKey][subKey] = [];
    }
    
    if(outputJson[mainKey].constructor === Object){
    outputJson[mainKey][subKey].push(action);
    }else {
        outputJson[mainKey].push(subKey);
    }
    
});

return outputJson

}

export const getStaffList = createAsyncThunk(
  "getStaffList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/staff?page=${page + 1}&limit=${pageSize}` + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setStaffList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const 
getStaffListById = createAsyncThunk(
  "getStaffListById",
  async ({ id, usertype }: any, { dispatch }) => {
    console.log("id, user_type", id, usertype);
    
    try {
      let url = `/auth/get-user-info/${id}?user_type=${usertype}`;
      const response: any = await API.get(
        url,
        );
      // dispatch(setStaffList(response?.data?.data))
      const convertedArray = permissionToObject(response?.data?.data?.role?.Permission);
      dispatch(setRolePermission(convertedArray))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postStaffList = createAsyncThunk(
  "postStaffList",
  async ({ _reqFormData }: any, { dispatch }) => {
    try {
      let url = `/api/v1/staff`;
      const response: any = await API.post(
        url,
        _reqFormData
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateStaffList = createAsyncThunk(
  "updateStaffList",
  async ({ _reqFormData, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/staff/${id}`;
      const response: any = await API.patch(
        url,
        _reqFormData
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteStaffData = createAsyncThunk(
  "deleteStaffData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/staff/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);
