// eslint-disable-next-line
export default {
  MuiLink: {
    styleOverrides: {
      root: {
        textDecorationColor: 'transparent'
      }
    }
  }
}
