import { Box, Button, IconButton, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import TableServerSide from 'views/table/TableServerSide'

// ** Custom Components
import { SortType, StatusObj } from '@core/utils/types'

import AddUpdate from './add-update'
import { useAppDispatch, useAppSelector } from 'store/store'
import DeleteDialog from './delete-dialog'
import { getCashMemoList } from 'store/Thunk/orderManagementThunk'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment-timezone";
import AgGridSelectTable from 'views/table/AgGridSelectTable'

const statusObj: StatusObj = {
  1: { title: 'current', color: 'primary' },
  2: { title: 'professional', color: 'success' },
  3: { title: 'rejected', color: 'error' },
  4: { title: 'resigned', color: 'warning' },
  5: { title: 'applied', color: 'info' }
}

export default function Demo() {
  // ** State
  const [refresh, setRefresh] = useState(true); // Just a toggle. Does not any effect on true/false value
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false)
  const [formData, setFormData] = useState<any>({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { cashMemoListing } = useAppSelector((state: any) => state.orderManagementReducer);
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.order_management?.cash_memo?.includes("export");

  // const user_id: any = localStorage.getItem("user_id");
  const user_type: any = localStorage.getItem("user_type");

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      width: "100px",
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      width: "150px",
      field: "client_name",
      headerName: "Client Name",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.client?.name || ""
      }
    },
    // {
    //   width: "170px",
    //   field: "old_client_name",
    //   headerName: "Old Client Name",
    //   filter: 'agTextColumnFilter',
    //   sortable: false,
    // },
    {
      width: "140px",
      field: "total",
      headerName: 'Total',
      cellStyle: { textAlign: "right" },
      valueFormatter: (p: any) => {
        if (p?.data?.CashMemoProduct?.length) {
          return calculateTotalProductAmount(p?.data?.CashMemoProduct);
        } else {
          return "-"
        }
      },
      filter: "agNumberColumnFilter",
      sortable: false,
    },
    {
      width: "140px",
      field: "advance_amount",
      headerName: 'Advance',
      valueFormatter: (p: any) => p.value || 0,
      cellStyle: { textAlign: "right" },
      filter: "agNumberColumnFilter",
      sortable: false,
    },
    {
      width: "170px",
      field: "remaining_amount",
      headerName: 'Finale Outstanding',
      cellStyle: { textAlign: "right" },
      filter: "agNumberColumnFilter",
      sortable: false,
    },
    {
      width: "180px",
      field: "cash_memo_date",
      headerName: 'Date Time',
      cellStyle: { textAlign: "center" },
      valueFormatter: (p: any) => p.value ? moment(p.value).format('DD/MM/YYYY') : "-",
      filter: "agDateColumnFilter",
    },
    {
      width: "110px",
      field: 'view',
      headerName: 'View',
      autoHeight: true,
      sortable: false,
      cellStyle: { textAlign: "center" },
      floatingFilter: false,
      cellRenderer: (p: any) => {
        const url = p.data.receipt_url || ""

        if (url) {
          
          return (
            <Button sx={{mb: "2px"}} onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
              View
            </Button>
          )
        } else {
          return (<Box sx={{ml: 4}}>-</Box>)
        }
      }
    }, 
    {
      width: "150px",
      field: 'action',
      headerName: 'Action',
      cellStyle: { textAlign: "center" },
      sortable: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        if (user_type != "super_admin" && !rolesPermission?.order_management?.cash_memo?.includes("write") && !rolesPermission?.order_management?.cash_memo?.includes("delete")) {
          return (<>-</>)
        }
        return (
          <Box>
            {
              (user_type == "super_admin" || rolesPermission?.order_management?.cash_memo?.includes("write")) && (
                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setOpenDialog(!openDialog)
                  setFormData(params.data)
                }}>
                  <EditIcon />
                </IconButton>
              )}
            {
              (user_type == "super_admin" || rolesPermission?.order_management?.cash_memo?.includes("delete")) && (

                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setDeleteDialog(!deleteDialog);
                  setDeleteId(params.data.id)
                }}>
                  <DeleteIcon />
                </IconButton>
              )}
          </Box>
        )
      }
    }
  ])

  const calculateTotalProductAmount = (products: any) => {
    const temp = products.reduce((total: any, product: any) => total + parseFloat(product?.amount || "0"), 0);
    return Math.ceil(temp);
  }

  // const columns: GridColDef[] = [
  //   {
  //     flex: 0.1,
  //     minWidth: 90,
  //     field: 'id',
  //     headerName: 'Id',
  //     renderCell: (params: GridRenderCellParams) => {

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params.row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.15,
  //     minWidth: 120,
  //     headerName: 'Client',
  //     field: 'client_name',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //         {params.row.client_name}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.15,
  //     minWidth: 150,
  //     field: 'old_client_name',
  //     headerName: 'Old Client Name',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.old_client_name}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.1,
  //     type: "number",
  //     field: 'total',
  //     minWidth: 100,
  //     headerName: 'Total',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.total}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.1,
  //     minWidth: 140,
  //     type: "number",
  //     field: 'advance',
  //     headerName: 'Advance',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const status = statusObj[params.row.advance]

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params.row.advance}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.1,
  //     minWidth: 180,
  //     field: 'finale_outstanding',
  //     headerName: 'Finale Outstanding',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const status = statusObj[params.row.status]

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params.row.finale_outstanding}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.1,
  //     minWidth: 150,
  //     field: 'cash_memo_date',
  //     headerName: 'Date Time',
  //     valueGetter: (params: any) => {
  //       return params ? moment(params).format('DD/MM/YYYY') : "-";
  //     },
  //     renderCell: (params: GridRenderCellParams) => {
  //       const status = statusObj[params.row.status]
  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params?.value}
  //         </Typography>
  //       )
  //     }
  //   },    
  //   {
  //     flex: 0.08,
  //     minWidth: 140,
  //     field: 'view',
  //     headerName: 'View',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => {
  //       const url = params.row.receipt_url || ""

  //       if (url) {
          
  //         return (
  //           <Button sx={{m: "-10px"}} onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
  //             View
  //           </Button>
  //         )
  //       } else {
  //         return (<Box sx={{ml: 4}}>-</Box>)
  //       }
  //       // <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //       //   {params.row.view}
  //       // </Typography>
  //     }
  //   },
  //   {
  //     minWidth: 140,
  //     field: '',
  //     headerName: 'Action',
  //     renderCell: (params: GridRenderCellParams) => {
  //       if (user_id != 1 && !rolesPermission?.order_management?.cash_memo?.includes("write") && !rolesPermission?.order_management?.cash_memo?.includes("delete")) {
  //         return (<>-</>)
  //       }
  //       return (
  //         <Box sx={{m: "-10px"}}>
  //           {
  //             (user_id == 1 || rolesPermission?.order_management?.cash_memo?.includes("write")) && (
  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setOpenDialog(!openDialog)
  //                 setFormData(params.row)
  //               }}>
  //                 <EditIcon />
  //               </IconButton>
  //             )}
  //           {
  //             (user_id == 1 || rolesPermission?.order_management?.cash_memo?.includes("write")) && (

  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setDeleteDialog(!deleteDialog);
  //                 setDeleteId(params.row.id)
  //               }}>
  //                 <DeleteIcon />
  //               </IconButton>
  //             )}
  //         </Box>
  //       )
  //     }
  //   }
  // ]

  useEffect(() => {
    if (cashMemoListing && cashMemoListing?.items) {
      setRows(cashMemoListing?.items);
      setTotal(cashMemoListing?.meta?.total);
    }
  }, [cashMemoListing])

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      // setLoading(true)
      return dispatch(getCashMemoList({ pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : "" })).then((res: any) => {
        return res.payload.data
        // setLoading(false)
      })
    },
    []
  )

  const handleDialog = () => {
    if (openDialog) {
      setFormData({})
    } else {
      setFormData({
        cash_memo_date: new Date(),
        credit_limit: "0",
        invoice_no: `${Math.floor(10000 + Math.random() * 90000)}`
      })
    }
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
    setOpenDialog(!openDialog)
  }

  const handleDeleteDialog = () => {
    setDeleteDialog(false);
    setDeleteId(null);
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn)
  }

  const handleRowClick = (value: any) => {
    setOpenDialog(!openDialog)
    setFormData(value.data)
  }

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const attr = {
    title: 'Cash Memo',
    buttonName: user_type == "super_admin" || rolesPermission?.order_management?.cash_memo?.includes("write") ? 'Add Cash Memo' : "",
    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    columns: columnDefs,
    searchValue,
    setSearchValue,
    loading,
    sortColumn,
    setSortColumn,
    onButtonClick: handleDialog,
    fetchTableData,
    handleRowClick,
    getRowClassName,
    isExport,
    isWrite: user_type == "super_admin" || rolesPermission?.order_management?.cash_memo?.includes("write"),
    refresh
  }
  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable  {...attr} />
      {openDialog && <AddUpdate open={openDialog} onClose={handleDialog} formData={formData} setFormData={setFormData} />}
      <DeleteDialog open={deleteDialog} onClose={handleDeleteDialog} deleteId={deleteId} />
    </Box>
  )
}
