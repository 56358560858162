// ** MUI Imports
import Grid from '@mui/material/Grid'

// ** Icon Imports
import CartPlus from 'mdi-material-ui/CartPlus'

// ** Custom Component Import
import CardStatisticsVertical from '@core/components/card-statistics/card-stats-vertical'

// ** Styled Component Import
import ApexChartWrapper from '@core/styles/libs/react-apexcharts'

// ** Demo Components Imports
import AnalyticsCongratulations from 'views/dashboards/analytics/AnalyticsCongratulations'
import { Helmet } from 'react-helmet'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getReport, getTop10LowestBalanceClient } from 'store/Thunk/dashboardThunk'
import ClientTable from 'views/dashboards/analytics/ClientTable'
import OrderOverview from 'views/dashboards/analytics/OrderOverview'
import AccountOutline from 'mdi-material-ui/AccountOutline'

const Dashboard = () => {

  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState<string>('1')

  const { reportData } = useAppSelector((state: any) => state.dashboardReducer);

  useEffect(() => {
    dispatch(getTop10LowestBalanceClient())
  },[]);
  
  useEffect(() => {
    const filterBy = tabValue == "1" ? "daily" : tabValue == "2" ? "weekly" : "monthly"
    dispatch(getReport({ filterBy: filterBy }))
  },[tabValue])
  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-9QJ7LZGCBC"></script>
        <script dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9QJ7LZGCBC', {
              page_path: window.location.pathname,
            });
          `,
        }}>
        </script>
      </Helmet>

      <ApexChartWrapper>
        <Grid container spacing={6} className='match-height'>
          <Grid item xs={12} md={8}>
            <AnalyticsCongratulations />
          </Grid>
          <Grid item xs={6} md={2}>
            <CardStatisticsVertical
              stats={reportData?.totalUsers}
              color='primary'
              trendNumber='+22%'
              icon={<AccountOutline />}
              title='Total Users'
              chipText='All Users'
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <CardStatisticsVertical
              stats={reportData?.totalOrders}
              color='primary'
              trendNumber='+22%'
              icon={<CartPlus />}
              title='Total Orders'
              chipText='All Orders'
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <OrderOverview tabValue={tabValue} setTabValue={setTabValue}/>
          </Grid>
          <Grid item xs={12} md={12}>
            <ClientTable />
          </Grid>
        </Grid>
      </ApexChartWrapper>
    </>
  )
}

export default Dashboard
