import axios from "axios";
import config from "configs/config";
// import config from "../config/config";
export const API = axios.create({ baseURL: config.BASE_URL });

API.interceptors.request.use(async (req: any) => {
  // const res = await fetch('https://api.ipify.org?format=json');
  // const data = await res.json();
  req.headers["x-current-ip"] = /* data.ip ||  */"106.214.29.36"
  if (localStorage.getItem("header")) {
    const header: any = localStorage.getItem("header")
    const token: any = JSON.parse(header).token;

    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
      // req.headers.uid = uid;
    }

    if (req.customHeaders) {
      for (const headerName in req.customHeaders) {
        // If the header value is `null`, remove the header
        if (req.customHeaders[headerName] === null) {
          delete req.headers[headerName];
        }
      }
    }
  }

  return req;
});

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    // If the request was successful, return the response
    return response;
  },
  (error) => {
    // If the request resulted in a 401 Unauthorized response
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('userData');
      localStorage.removeItem('header');
      localStorage.removeItem("username");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      if (window.location.pathname !== "/") {
        window.location.href = "/"
      }
      // Handle unauthorized access, e.g., redirect to login page
      console.log('Unauthorized access. Redirecting to login page.');
    }

    // Return any errors for further handling
    return Promise.reject(error);
  }
);

export default API;