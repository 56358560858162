import MESSAGES from './messages'

const reWhiteSpace = /^[^-\s][\w\s-]+$/
const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
const alphabet = /^[a-zA-Z]*$/
const number = /^[0-9]+$/
const phoneNumber = /^\+(?:[0-9] ?){6,14}[0-9]$/
const personNameValidation = /^[a-zA-Z ]*$/
const reWhiteSp = /^[^\s]+(\s+[^\s]+)*$/
const emailValidation = /[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/
// const emailValidation = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
const alphabetWithSpace = /[^\w -]/
const smallLetter = /^(?=.*[a-z])/
const capitalLetter = /^(?=.*[A-Z])/

const Validations = {
  validateOrderForm: (form: any) => {
    const errors: Partial<any> = {}

    const { client_id, client_name, remarks, order_date, mobile_no, credit_limit, credit_limit_hour, products } = form;

    if (!client_id) {
      errors.client_id = MESSAGES.ORDER.ERROR.CLIENT_ID.EMPTY
    }
    if (!order_date) {
      errors.order_date = MESSAGES.ORDER.ERROR.ORDER_DATE.EMPTY
    }
    if (!client_name) {
      errors.client_name = MESSAGES.ORDER.ERROR.CLIENT_NAME.EMPTY
    }
    if (!mobile_no) {
      errors.mobile_no = MESSAGES.ORDER.ERROR.MOBILE_NO.EMPTY
    }
    // if (!remarks) {
    //   errors.remarks = MESSAGES.ORDER.ERROR.REMARK.EMPTY
    // }
    if (!credit_limit) {
      errors.credit_limit = MESSAGES.ORDER.ERROR.CREDIT_LIMIT.EMPTY
    }
    // if (!credit_limit_hour) {
    //   errors.credit_limit_hour = MESSAGES.CLIENT.ERROR.CREDIT_LIMIT_HOUR.EMPTY
    // }
    if (products.length) {
      var errorsDetails: any = [];
      products?.map((item: any, index: any) => {

        var errors: any = {};
        if (!item?.product_id) {
          errors = { ...errors, product_id: `Please Select Product ` }
        }
        // if (!item?.description) {
        //   errors = { ...errors, description: `Please enter description ` }
        // }
        if (!item?.quantity) {
          errors = { ...errors, quantity: `Please enter quantity ` }
        }
        if (!item?.rate) {
          errors = { ...errors, rate: `Please enter rate ` }
        }
        if (!item?.amount) {
          errors = { ...errors, amount: `Please enter amount ` }
        }
        if (!item?.image) {
          errors = { ...errors, image: `Please select cdr file` }
        }
        if (!item?.width && item?.height_width == "yes") {
          errors = { ...errors, width: `Please enter width ` }
        }
        if (!item?.height && item?.height_width == "yes") {
          errors = { ...errors, height: `Please enter height ` }
        }
        errorsDetails[index] = errors;
      })
      errors.products = errorsDetails;
    }

    return errors
  },

  validateCashMemoForm: (form: any) => {
    const errors: Partial<any> = {}
    const { client_id, client_name, remarks, cash_memo_date, mobile_no, gst_no, invoice_no, credit_limit,
      products } = form;

    if (!client_id) {
      errors.client_id = MESSAGES.CASH_MEMO.ERROR.CLIENT_NAME.EMPTY
    }
    // if (!cash_memo_date) {
    //   errors.cash_memo_date = MESSAGES.CASH_MEMO.ERROR.ORDER_DATE.EMPTY
    // }
    if (!client_name) {
      errors.client_name = MESSAGES.CASH_MEMO.ERROR.CLIENT_NAME.EMPTY
    }
    // if (!remarks) {
    //   errors.remarks = MESSAGES.CASH_MEMO.ERROR.REMARK.EMPTY
    // }
    if (!mobile_no) {
      errors.mobile_no = MESSAGES.CASH_MEMO.ERROR.MOBILE_NO.EMPTY
    }
    // if (!gst_no) {
    //   errors.gst_no = MESSAGES.CASH_MEMO.ERROR.GST_NO.EMPTY
    // }
    if (!invoice_no) {
      errors.invoice_no = MESSAGES.CASH_MEMO.ERROR.INVOICE_NO.EMPTY
    }

    // if (!credit_limit) {
    //   errors.credit_limit = MESSAGES.CASH_MEMO.ERROR.CREDIT_LIMIT.EMPTY
    // }
    if (products) {
      {/* "product_id": 8,
            "quantity": 2,
            "width": 12,
            "height": 12,
            "rate": 3000,
            "gst_percentage": "12",
            "gst_amount": "12000",
            "amount": "12000",
            "description": "adssd" */}
      var errorsDetails: any = [];
      console.log("products", products);
      
      products.map((item: any, index: any) => {
        var errors: any = {};
        if (!item?.product_id) {
          errors = { ...errors, product_id: `Please Select Product` }
        }
        // if (!item?.description) {
        //   errors = { ...errors, description: `Please enter description` }
        // }
        if (!item?.quantity) {
          errors = { ...errors, quantity: `Please enter quantity` }
        }
        if (!item?.rate) {
          errors = { ...errors, rate: `Please enter rate` }
        }        
        // if (item?.width == "") {
        //   errors = { ...errors, width: `Please enter width` }
        // }
        // if (item?.height == "") {
        //   errors = { ...errors, height: `Please enter length` }
        // }
        // if (!item?.gst_percentage) {
        //   errors = { ...errors, gst_percentage: `Please enter gst` }
        // }
        if (!item?.gst_amount) {
          errors = { ...errors, gst_amount: `Please enter gst amount` }
        }
        if (!item?.amount) {
          errors = { ...errors, amount: `Please enter amount` }
        }
        errorsDetails[index] = errors;
      })
      errors.products = errorsDetails;
    }
    return errors
  },

  validateOutstandingForm: (form: any) => {
    const errors: Partial<any> = {}
    const { type_id, client_id } = form;

    if (!type_id) {
      errors.type_id = MESSAGES.OUTSTANDING.ERROR.TYPE.EMPTY
    }
    if (!client_id) {
      errors.client_id = MESSAGES.OUTSTANDING.ERROR.CLIENT.EMPTY
    }
    return errors
  },

  validateStaffForm: (form: any) => {
    const errors: Partial<any> = {}
    const {
      username, password, name, address, city, language, phone, birth_date, gender, blood_group,
      emergency_contact,
      salary, join_date, reference, guarantor, marital, hobbies, photo, id_proof,
      area_id, return_charge, commission, zone, tiffin_capacity, remarks, role_id } = form;

    if (!username) {
      errors.username = MESSAGES.STAFF.ERROR.USERNAME.EMPTY
    }
    if (!password) {
      errors.password = MESSAGES.STAFF.ERROR.PASSWORD.EMPTY
    }
    if (!name) {
      errors.name = MESSAGES.STAFF.ERROR.NAME.EMPTY
    }
    // if (!address) {
    //   errors.address = MESSAGES.STAFF.ERROR.ADDRESS.EMPTY
    // }
    // if (!city) {
    //   errors.city = MESSAGES.STAFF.ERROR.CITY.EMPTY
    // }
    // if (!language) {
    //   errors.language = MESSAGES.STAFF.ERROR.LANGUAGE.EMPTY
    // }
    // if (!phone) {
    //   errors.phone = MESSAGES.STAFF.ERROR.PHONE.EMPTY
    // }
    // if (!birth_date) {
    //   errors.birth_date = MESSAGES.STAFF.ERROR.BIRTH_DATE.EMPTY
    // }

    // if (!gender) {
    //   errors.gender = MESSAGES.STAFF.ERROR.GENDER.EMPTY
    // }
    // if (!blood_group) {
    //   errors.blood_group = MESSAGES.STAFF.ERROR.BLOOD_GROUP.EMPTY
    // }
    // if (!emergency_contact) {
    //   errors.emergency_contact = MESSAGES.STAFF.ERROR.EMERGENCY_CONTACT.EMPTY
    // }
    // if (!salary) {
    //   errors.salary = MESSAGES.STAFF.ERROR.SALARY.EMPTY
    // }
    // if (!join_date) {
    //   errors.join_date = MESSAGES.STAFF.ERROR.JOIN_DATE.EMPTY
    // }
    // if (!reference) {
    //   errors.reference = MESSAGES.STAFF.ERROR.REFERENCE.EMPTY
    // }
    // if (!guarantor) {
    //   errors.guarantor = MESSAGES.STAFF.ERROR.GUARANTOR.EMPTY
    // }

    // if (!marital) {
    //   errors.marital = MESSAGES.STAFF.ERROR.MARITAL.EMPTY
    // }
    // if (!hobbies) {
    //   errors.hobbies = MESSAGES.STAFF.ERROR.HOBBIES.EMPTY
    // }
    // if (!photo) {
    //   errors.photo = MESSAGES.STAFF.ERROR.PHOTO.EMPTY
    // }
    // if (!id_proof) {
    //   errors.id_proof = MESSAGES.STAFF.ERROR.ID_PROOF.EMPTY
    // }
    // if (!area_id) {
    //   errors.area_id = MESSAGES.STAFF.ERROR.AREA.EMPTY
    // }
    // if (!return_charge) {
    //   errors.return_charge = MESSAGES.STAFF.ERROR.RETURN_CHARGE.EMPTY
    // }
    // if (!commission) {
    //   errors.commission = MESSAGES.STAFF.ERROR.COMMISION.EMPTY
    // }
    // if (!zone) {
    //   errors.zone = MESSAGES.STAFF.ERROR.ZONE.EMPTY
    // }
    // if (!tiffin_capacity) {
    //   errors.tiffin_capacity = MESSAGES.STAFF.ERROR.TIFIN_CAPACITY.EMPTY
    // }
    // if (!remarks) {
    //   errors.remarks = MESSAGES.STAFF.ERROR.TIFIN_CAPACITY.EMPTY
    // }
    if (!role_id) {
      errors.role_id = MESSAGES.STAFF.ERROR.ROLE.EMPTY
    }
    return errors
  },

  validateClientForm: (form: any) => {
    const errors: Partial<any> = {}
    const { 
      username, 
      password, 
      name, 
      // client_category,
      // file_code,
      // client_code,
      email,
      // home_address,
      // tin_no,
      // gst_no,
      // city,
      // state,
      // phone,
      // remarks,
      // credit_limit, 
      // credit_limit_hour 
    } = form;

    if (!username) {
      errors.username = MESSAGES.CLIENT.ERROR.USER_NAME.EMPTY
    }
    if (!password) {
      errors.password = MESSAGES.CLIENT.ERROR.PASSWORD.EMPTY
    }
    if (!name) {
      errors.name = MESSAGES.CLIENT.ERROR.NAME.EMPTY
    }
    // if (!client_category) {
    //   errors.client_category = MESSAGES.CLIENT.ERROR.CLIENT_CATEGORY.EMPTY
    // }
    // if (!file_code) {
    //   errors.file_code = MESSAGES.CLIENT.ERROR.FILE_CODE.EMPTY
    // }
    // if (!client_code) {
    //   errors.client_code = MESSAGES.CLIENT.ERROR.CLIENT_CODE.EMPTY
    // }
    if (!email) {
      errors.email = MESSAGES.CLIENT.ERROR.EMAIL.EMPTY
    }
    // if (!home_address) {
    //   errors.home_address = MESSAGES.CLIENT.ERROR.HOME_ADDRESS.EMPTY
    // }

    // if (!tin_no) {
    //   errors.tin_no = MESSAGES.CLIENT.ERROR.TIN_NO.EMPTY
    // }
    // if (!gst_no) {
    //   errors.gst_no = MESSAGES.CLIENT.ERROR.GST_NO.EMPTY
    // }
    // if (!city) {
    //   errors.city = MESSAGES.CLIENT.ERROR.CITY.EMPTY
    // }
    // if (!state) {
    //   errors.state = MESSAGES.CLIENT.ERROR.STATE.EMPTY
    // }
    // if (!phone) {
    //   errors.phone = MESSAGES.CLIENT.ERROR.PHONE.EMPTY
    // }
    // if (!credit_limit_hour) {
    //   errors.credit_limit_hour = MESSAGES.CLIENT.ERROR.CREDIT_LIMIT_HOUR.EMPTY
    // }
    // if (!remarks) {
    //   errors.remarks = MESSAGES.CLIENT.ERROR.REMARKS.EMPTY
    // }
    // if (!credit_limit) {
    //   errors.credit_limit = MESSAGES.CLIENT.ERROR.CREDIT_LIMIT.EMPTY
    // }
    return errors
  },

  validateProductForm: (form: any) => {
    const errors: Partial<any> = {}
    const { name, code, price_A, price_B, price_C, price_D, price_E, price_F, courier_charges, transport_charges,
      discount, cgst, sgst, igst, sort, hsn_code, print_copy, image, category_id, banner, height_width } = form;

    if (!name) {
      errors.name = MESSAGES.PRODUCT.ERROR.NAME.EMPTY
    }
    if (!code) {
      errors.code = MESSAGES.PRODUCT.ERROR.CODE.EMPTY
    }
    if (!price_A) {
      errors.price_A = MESSAGES.PRODUCT.ERROR.PRICE_A.EMPTY
    }
    if (!price_B) {
      errors.price_B = MESSAGES.PRODUCT.ERROR.PRICE_B.EMPTY
    }
    if (!price_C) {
      errors.price_C = MESSAGES.PRODUCT.ERROR.PRICE_C.EMPTY
    }
    if (!price_D) {
      errors.price_D = MESSAGES.PRODUCT.ERROR.PRICE_D.EMPTY
    }
    if (!price_E) {
      errors.price_E = MESSAGES.PRODUCT.ERROR.PRICE_E.EMPTY
    }
    if (!price_F) {
      errors.price_F = MESSAGES.PRODUCT.ERROR.PRICE_F.EMPTY
    }
    if (!courier_charges) {
      errors.courier_charges = MESSAGES.PRODUCT.ERROR.COURIER_CHARGES.EMPTY
    }
    if (!transport_charges) {
      errors.transport_charges = MESSAGES.PRODUCT.ERROR.TRANSPORT_CHARGES.EMPTY
    }
    if (!discount) {
      errors.discount = MESSAGES.PRODUCT.ERROR.DISCOUNT.EMPTY
    }
    if (!cgst) {
      errors.cgst = MESSAGES.PRODUCT.ERROR.CGST.EMPTY
    }
    if (!sgst) {
      errors.sgst = MESSAGES.PRODUCT.ERROR.SGST.EMPTY
    }
    if (!igst) {
      errors.igst = MESSAGES.PRODUCT.ERROR.IGST.EMPTY
    }
    if (!sort) {
      errors.sort = MESSAGES.PRODUCT.ERROR.SORT.EMPTY
    }
    if (!hsn_code) {
      errors.hsn_code = MESSAGES.PRODUCT.ERROR.HSN_CODE.EMPTY
    }
    if (!print_copy) {
      errors.print_copy = MESSAGES.PRODUCT.ERROR.PRINT_COPY.EMPTY
    }
    // if (!image) {
    //   errors.image = MESSAGES.PRODUCT.ERROR.IMAGE.EMPTY
    // }
    if (!category_id) {
      errors.category = MESSAGES.PRODUCT.ERROR.CATEGORY.EMPTY
    }
    // if (!banner) {
    //   errors.banner = MESSAGES.PRODUCT.ERROR.BANNER.EMPTY
    // }
    if (!height_width) {
      errors.height_width = MESSAGES.PRODUCT.ERROR.HEIGHT.EMPTY
    }
    return errors
  },
  validateAreaForm: (form: any) => {
    const errors: Partial<any> = {}
    const { name } = form;

    if (!name) {
      errors.name = MESSAGES.AREA.ERROR.AREA_NAME.EMPTY
    }
    return errors
  },
  validatePaperTypeForm: (form: any) => {
    const errors: Partial<any> = {}
    const { name } = form;

    if (!name) {
      errors.name = MESSAGES.PAPER_TYPE.ERROR.NAME.EMPTY
    }
    return errors
  },
  validateClientProductForm: (form: any) => {
    const errors: Partial<any> = {}
    const { client_id, product_id, price, effective_date, status, stop_date } = form;

    if (!client_id) {
      errors.client_id = MESSAGES.CLIENT_PRODUCT.ERROR.CLIENT_NAME.EMPTY
    }
    if (!product_id) {
      errors.product_id = MESSAGES.CLIENT_PRODUCT.ERROR.PRODUCT_NAME.EMPTY
    }
    if (!price) {
      errors.price = MESSAGES.CLIENT_PRODUCT.ERROR.PRICE.EMPTY
    }
    if (!effective_date) {
      errors.effective_date = MESSAGES.CLIENT_PRODUCT.ERROR.EFFECTIVE_DATE.EMPTY
    }
    if (!status) {
      errors.status = MESSAGES.CLIENT_PRODUCT.ERROR.STATUS.EMPTY
    }
    if (!stop_date) {
      errors.stop_date = MESSAGES.CLIENT_PRODUCT.ERROR.STOP_DATE.EMPTY
    }
    return errors
  },

  validateCategoryForm: (form: any) => {
    const errors: Partial<any> = {}
    const { name, sort } = form;

    if (!name) {
      errors.name = MESSAGES.CATEGORY.ERROR.NAME.EMPTY
    }
    if (!sort) {
      errors.sort = MESSAGES.CATEGORY.ERROR.SORT.EMPTY
    }
    return errors
  },

  validateACEntryForm: (form: any) => {
    const errors: Partial<any> = {}
    const { journal_item_name, credit_debit, client_id, debitor_creditor_type, amount, date, hours, payment_type, remarks } = form;

    if (!journal_item_name) {
      errors.journal_item_name = MESSAGES.AC_ENTRY.ERROR.JOURNAL_ITEM_NAME.EMPTY
    }
    if (!credit_debit) {
      errors.credit_debit = MESSAGES.AC_ENTRY.ERROR.CREDIT_DEBIT.EMPTY
    }
    if (!client_id) {
      errors.client_id = MESSAGES.AC_ENTRY.ERROR.CLIENT_ID.EMPTY
    }
    if (!debitor_creditor_type) {
      errors.debitor_creditor_type = MESSAGES.AC_ENTRY.ERROR.DEBITOR_CREDITOR_TYPE.EMPTY
    }
    if (!amount) {
      errors.amount = MESSAGES.AC_ENTRY.ERROR.AMOUNT.EMPTY
    }
    if (!date) {
      errors.date = MESSAGES.AC_ENTRY.ERROR.DATE.EMPTY
    }
    if (!hours) {
      errors.hours = MESSAGES.AC_ENTRY.ERROR.HOURS.EMPTY
    }
    if (!payment_type) {
      errors.payment_type = MESSAGES.AC_ENTRY.ERROR.PAYMENT_TYPE.EMPTY
    }
    // if (!remarks) {
    //   errors.remarks = MESSAGES.AC_ENTRY.ERROR.REMARKS.EMPTY
    // }
    return errors
  },
  validateInquiryForm: (form: any) => {
    const errors: Partial<any> = {}
    const { client_id, paper_type_id, lamination_id, client_name, mobile, job_width, job_height, quantity, other_details } = form;

    if (!client_id) {
      errors.client_id = MESSAGES.INQUIRY.ERROR.CLIENT_ID.EMPTY
    }
    if (!paper_type_id) {
      errors.paper_type_id = MESSAGES.INQUIRY.ERROR.PAPER_TYPE_ID.EMPTY
    }
    if (!lamination_id) {
      errors.lamination_id = MESSAGES.INQUIRY.ERROR.LAMINATION_ID.EMPTY
    }
    if (!client_name) {
      errors.client_name = MESSAGES.INQUIRY.ERROR.CLIENT_NAME.EMPTY
    }
    if (!mobile) {
      errors.mobile = MESSAGES.INQUIRY.ERROR.MOBILE.EMPTY
    }
    if (!job_width) {
      errors.job_width = MESSAGES.INQUIRY.ERROR.JOB_WIDTH.EMPTY
    }
    if (!job_height) {
      errors.job_height = MESSAGES.INQUIRY.ERROR.JOB_HEIGHT.EMPTY
    }
    if (!quantity) {
      errors.quantity = MESSAGES.INQUIRY.ERROR.QUANTITY.EMPTY
    }
    if (!other_details) {
      errors.other_details = MESSAGES.INQUIRY.ERROR.OTHER_DETAILS.EMPTY
    }
    return errors
  }
  // journal_item_name: formData?.journal_item_name || '',
  //       credit_debit: formData?.credit_debit || '',
  //       client_id: formData?.client_id || '',
  //       debitor_creditor_type: formData?.debitor_creditor_type || '',
  //       amount: formData?.amount || '',
  //       date: formData?.date || '',
  //       hours: formData?.hours || '',
  //       payment_type: formData?.payment_type || '',
  //       remarks: formData?.remarks || '',
}






export default Validations
