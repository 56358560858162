import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { styled } from '@mui/material/styles'
import MDDialogTitle from '../MDDialogTitle'
import MDLoader from '../MDLoader'

// ** Third Party Components
import PerfectScrollbar from 'react-perfect-scrollbar'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

export default function CustomizedDialogs(props: any) {
  const {
    children,
    open,
    title,
    saveTbtText,
    closeBtnText,
    onSave,
    onClose,
    onClear,
    onCloseFunc,
    size,
    loading,
    screenLoader,
    saveBtn = true,
    closeBtn = true,
    closeBtnColor = 'light',
    deleteBtn = false,
    disabledBtn = false,
    isDisabled = false,
    overflowVisible,
    additionalBtnText,
    onAdditionalBtnSave,
    additionalBtnColor = 'info',
    additionalBtn = false,
    titleClass = '',
    bodyClass = ''
  } = props

  const checkOpen = () => {
    let openModal = false
    if (open) {
      openModal = true
    }
    return openModal
  }

  return (
    <div>
      <BootstrapDialog aria-labelledby='customized-dialog-title' open={checkOpen()} maxWidth={size || 'sm'} fullWidth>
        <MDDialogTitle sx={{color: '#fff', background: '#1E90FF'}} onClose={onClose}>{title}</MDDialogTitle>
        <PerfectScrollbar>
          <DialogContent dividers className={bodyClass || ''} sx={{ overflow: 'hidden' }}>
            {children}
            {/* {screenLoader && <AppLoader />} */}
            {/* {children} */}
          </DialogContent>
        </PerfectScrollbar>
        <DialogActions sx={{ m: 2 }}>
          {
            onClear && (
              <Button onClick={onClear} variant='outlined' color='error'>
              {'Clear'}
            </Button>  
            )
          }
          {closeBtn && (
            <Button onClick={onClose} variant='contained' color='primary' className='gray_bg_btn'>
              {closeBtnText || 'Close'}
            </Button>
          )}
          {saveBtn && (
            <Button
              onClick={onSave}
              variant='contained'
              className='primary_bg_btn'
              disabled={loading}
              sx={{ minWidth: '100px' }}
            >
              {loading ? <MDLoader size={24} color={'secondary'} /> : saveTbtText || 'Save'}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
