// *** React Imports
import React from 'react'
import { SyntheticEvent, useEffect, useState } from 'react'

//MUI Imports
import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  CircularProgress,
  FormHelperText,
  Grid,
  GridProps,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'

// ** Icon Imports
import Close from 'mdi-material-ui/Close'
import Plus from 'mdi-material-ui/Plus'

import MDDialog from '@core/components/MDDialog'
import Repeater from '@core/components/repeater'
import Validations from 'helper/validations'

import CustomDateInput from 'views/customeDateInput/CustomDateInput'
import { getProductList, postCategoryData, postClientProductData, updateClientProductList } from 'store/Thunk/masterDataThunk'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getClientList } from 'store/Thunk/clientManagmentThunk'
import { isoStringNextDay, isoStringPrevDay } from 'helper/service'
interface Props {
  open?: any
  onClose?: any
  formData?: any
  setFormData?: any
}

const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
  paddingRight: 0,
  marginTop: theme.spacing(3),
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .col-title': {
    top: '-1.5rem',
    position: 'absolute'
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.down('lg')]: {
    '& .col-title': {
      top: '0',
      position: 'relative'
    }
  }
}))

const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(5.5),
  '& .repeater-wrapper + .repeater-wrapper': {
    marginTop: theme.spacing(8)
  }
}))

const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

function Index({ open, onClose, formData, setFormData }: Props) {
  const dispatch = useAppDispatch();
  const [formError, setFormError] = useState<any>({})
  const [loading, setLoading] = useState(false);

  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [openACClient, setOpenACClient] = useState<boolean>(false);
  const [clientOptions, setClientOptions] = useState<any[]>([]);

  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [openACProduct, setOpenACProduct] = useState<boolean>(false);
  const [productOptions, setProductOptions] = useState<any[]>([]);

  const { clientListing } = useAppSelector((state: any) => state.clientReducer)
  const { productListing } = useAppSelector((state: any) => state.masterDataReducer)

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleOnChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setFormData({
      ...formData,
      [name]: value ? value : null
    })
  }

  useEffect(() => {
    setClientLoading(false);
    setClientOptions(clientListing?.items ? clientListing?.items : []);
  }, [clientListing]);

  useEffect(() => {
    if (!openACClient) {
      setClientOptions([]);
    } else {
      dispatch(getClientList({ pageSize: 1000, page: 0 }));
    }
    setClientLoading(openACClient);
  }, [openACClient]);

  useEffect(() => {
    setProductLoading(false);
    setProductOptions(productListing?.items ? productListing?.items : []);
  }, [productListing]);

  useEffect(() => {
    if (!openACProduct) {
      setProductOptions([]);
    } else {
      dispatch(getProductList({ pageSize: 1000, page: 0 }));
    }
    setProductLoading(openACProduct);
  }, [openACProduct]);

  const onSave = (event: any) => {
    event.preventDefault()
    let _request: any = {
      client_id: formData?.client?.id || '',
      product_id: formData?.product?.id || '',
      price: formData?.price || '',
      effective_date: formData?.effective_date || '',
      status: formData?.status || '',
      stop_date: formData?.stop_date || '',
    }

    let allError = Validations.validateClientProductForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      setLoading(true);
      if (formData?.id) {
        const id = formData?.id
        dispatch(updateClientProductList({ _request, id })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 200) {
            onClose();
          }
        })
      } else {
        dispatch(postClientProductData({ _request })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 201) {
            onClose();
          }
        })
      }
    } else {
      setFormError(allError)
    }
  }

  const dialogProps = {
    loading,
    open,
    onClose,
    onSave,
    title: formData?.id ? `Edit Client Product` : `Add Client Product`,
    size: 'md',
    saveTbtText: 'Save'
  }

  console.log("formData", formData);

  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            {/* dropdown */}
            <InputLabel>Client</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACClient}
              onOpen={() => {
                setOpenACClient(true);
              }}
              onClose={() => {
                setOpenACClient(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("client")}
              value={formData?.client ? formData?.client : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={clientOptions}
              loading={clientLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError?.client_id ? true : false}
                  helperText={formError && formError?.client_id}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {clientLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* dropdown */}
            <InputLabel>Product</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACProduct}
              onOpen={() => {
                setOpenACProduct(true);
              }}
              onClose={() => {
                setOpenACProduct(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("product")}
              value={formData?.product ? formData?.product : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={productOptions}
              loading={productLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError?.product ? true : false}
                  helperText={formError && formError?.product}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {productLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}/>
              {formError && formError.product_id && (
                <FormHelperText error id='password-error'>
                  {formError.product_id}
                </FormHelperText>
              )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* TextField */}
            <InputLabel>Price</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Price'
              name='price'
              onChange={handleChange}
              value={formData?.price || ''}
              error={formError && formError?.price}
              helperText={formError && formError?.price ? formError.price : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* Date Picker */}
            <InputLabel>Effective Date</InputLabel>
            <CustomDateInput
              error={formError?.effective_date}
              helperText={formError?.effective_date}
              value={formData?.effective_date ? isoStringPrevDay(formData?.effective_date) : ""}
              onChange={(e: any) => {
                setFormData({ ...formData, effective_date: isoStringPrevDay(e) })} 
              }/>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* dropdown */}
            <InputLabel>Status</InputLabel>
            <Select
              fullWidth
              size='small'
              placeholder='Credit Limit'
              name='status'
              onChange={handleChange}
              error={formError.status}
              value={formData?.status || ''}>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">inactive</MenuItem>
            </Select>
            {formError && formError.status && (
              <FormHelperText error id='password-error'>
                {formError.status}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* Date Picker */}
            <InputLabel>Stop Date</InputLabel>
            <CustomDateInput
              error={formError?.stop_date}
              helperText={formError?.stop_date}
              value={formData?.stop_date ? isoStringPrevDay(formData?.stop_date) : ""}
              onChange={(e: any) => setFormData({ ...formData, stop_date: isoStringPrevDay(e) })} />
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  )
}

export default Index
