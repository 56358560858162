import BlankLayout from '@core/layouts/BlankLayout'
import { CardContent, Grow } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'
import MuiCard, { CardProps } from '@mui/material/Card'
import { styled } from '@mui/material/styles'

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: '400px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  }
}))

const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '80%' },
  borderRadius: '22px'
}))

export default function ScreenLayout({ children }: any) {
  return (
    <BlankLayout>
      <Box className='content-center' sx={{ background: '#FFF' }}>
        <Grow in={true}>
          <Card sx={{ zIndex: 1 }}>
            <CardContent>{children}</CardContent>
          </Card>
        </Grow>
      </Box>
    </BlankLayout>
  )
}
