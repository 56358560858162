import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

export default function Index(props: any) {
  const { size } = props
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress size={size || 20} {...props} />
    </Box>
  )
}
