//MUI Imports
import {
  Box,
  Typography
} from '@mui/material'

import MDDialog from '@core/components/MDDialog'
import { useAppDispatch } from 'store/store'
import { useState } from 'react'
import { postConfirmData } from 'store/Thunk/orderManagementThunk'

interface Props {
  confirmDialog?: any
  onClose?: any
  deleteId?: any
  setOpenDialog?: any
}

function Index({ confirmDialog, onClose, deleteId, setOpenDialog }: Props) {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

  const onSave = () => {
    setLoading(true)
    const id = confirmDialog?.id;
    const _request = {
      status: confirmDialog?.status
    };
    if (id) {
      dispatch(postConfirmData({ _request, id })).then((res: any) => {
        setLoading(false);
        setOpenDialog(false);
        onClose();
      })
    }
  }
  const open = confirmDialog?.open;
  const dialogProps = {
    open,
    onClose,
    onSave,
    loading,
    title: `Confirm Order`,
    size: 'sm',
    saveTbtText: 'Confirm'
  }

  

  return (
    <MDDialog {...dialogProps}>
      <Box p={4} sx={{mb: 10}}>
        <Typography variant='h6'>Are you sure want to confirm order?</Typography>
      </Box>
    </MDDialog>
  )
}

export default Index
