import { Box, Button, IconButton, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import TableServerSide from 'views/table/TableServerSide'

// ** Custom Components
import CustomAvatar from '@core/components/mui/avatar'
import CustomChip from '@core/components/mui/chip'
import { ThemeColor } from '@core/layouts/types'
import { getInitials } from '@core/utils/get-initials'
import { SortType, StatusObj } from '@core/utils/types'

import AddUpdate from './add-update'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getAreaList, getCategoryList } from 'store/Thunk/masterDataThunk'
import DeleteDialog from './delete-dialog'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { extractFileNameFromUrl } from 'helper/service'
import AgGridSelectTable from 'views/table/AgGridSelectTable'

export default function Demo() {
  // ** State
  const [refresh, setRefresh] = useState(true); // Just a toggle. Does not any effect on true/false value
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false)
  const [formData, setFormData] = useState<any>({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);

  const { categoryListing } = useAppSelector((state: any) => state.masterDataReducer);
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.Master_Data?.Category?.includes("export");

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      field: "name",
      headerName: "Name",
      filter: 'agTextColumnFilter',
    },
    {
      field: "sort",
      headerName: "Sort",
      filter: 'agNumberColumnFilter',
    },
    {
      field: "category_image",
      headerName: 'Image',
      floatingFilter: false,
      sortable: false,
      cellRenderer: (params: any) => (
        <>
          {
            params?.data?.category_image ? 
            <Button onClick={(e) => {
              e.stopPropagation();
              onDownload(params?.data?.category_image)
            }}>
              Download
            </Button> 
            : 
            <Box>--</Box>
          }
        </>
      )
    },
    {
      field: "action",
      headerName: 'Action',
      floatingFilter: false,
      sortable: false,
      cellRenderer: (params: any) => {
        if (user_type != "super_admin" && !rolesPermission?.Master_Data?.Category?.includes("write") && !rolesPermission?.Master_Data?.Category?.includes("delete")) {
          return (<>-</>)
        }

        return (
          <Box>
            {
              (user_type == "super_admin" || rolesPermission?.Master_Data?.Category?.includes("write")) && (
                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setOpenDialog(!openDialog)
                  setFormData(params.data)
                }}>
                  <EditIcon />
                </IconButton>
              )}
            {
              (user_type == "super_admin" || rolesPermission?.Master_Data?.Category?.includes("delete")) && (
                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setDeleteDialog(!deleteDialog);
                  setDeleteId(params.data.id)
                }}>
                  <DeleteIcon />
                </IconButton>
              )}
          </Box>
        )
      }
    }
  ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (categoryListing && categoryListing?.items) {
      setRows(categoryListing?.items);
      setTotal(categoryListing?.meta?.total);
    }
  }, [categoryListing])

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      // setLoading(true)
      return dispatch(getCategoryList({ pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : "" })).then((res: any) => {
        // setLoading(false)
        return res.payload.data
      })
    },
    []
  )

  const handleDialog = () => {
    if (openDialog) {
      setFormData({})
    }
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
    setOpenDialog(!openDialog)
  }

  const handleDeleteDialog = () => {
    setDeleteDialog(false);
    setDeleteId(null);
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
  }

  const onDownload = async (imageUrl: any) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    
    const filename = extractFileNameFromUrl(decodeURIComponent(imageUrl));
    downloadLink.download = filename; // Adjust the filename based on the image type

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  // const onDownload = (imageUrl: any) => {
  //   // Create an anchor element
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = imageUrl;

  //   // Set the download attribute with the desired filename
  //   downloadLink.download = 'downloaded-image.jpg';

  //   // Append the anchor element to the document
  //   document.body.appendChild(downloadLink);

  //   // Trigger a click on the anchor element
  //   downloadLink.click();

  //   // Remove the anchor element from the document
  //   document.body.removeChild(downloadLink);

  // }

  // const columns: GridColDef[] = [
  //   {
  //     field: 'id',
  //     headerName: 'Id',
  //     minWidth: 1-0,
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params

  //       return (
  //         <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     minWidth: 120,
  //     headerName: 'Name',
  //     field: 'name',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //         {params.row.name}
  //       </Typography>
  //     )
  //   },
  //   {
  //     minWidth: 120,
  //     field: 'sort',
  //     headerName: 'Sort',
  //     type: "number",
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.sort}
  //       </Typography>
  //     )
  //   },
  //   {
  //     field: 'category_image',
  //     minWidth: 180,
  //     headerName: 'Image',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => (
  //       <>{params?.row?.category_image ? <Button sx={{m: "-12px"}} onClick={(e) => {
  //         e.stopPropagation();
  //         onDownload(params?.row?.category_image)
  //       }}>
  //         Download
  //       </Button> : <Box>--</Box>}</>
  //     )
  //   },
  //   {
  //     minWidth: 120,
  //     field: '',
  //     headerName: 'Action',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => {
  //       if (user_id != 1 && !rolesPermission?.Master_Data?.Category?.includes("write") && !rolesPermission?.Master_Data?.Category?.includes("delete")) {
  //         return (<>-</>)
  //       }

  //       return (
  //         <Box sx={{m: "-10px"}}>
  //           {
  //             (user_id == 1 || rolesPermission?.Master_Data?.Category?.includes("write")) && (
  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setOpenDialog(!openDialog)
  //                 setFormData(params.row)
  //               }}>
  //                 <EditIcon />
  //               </IconButton>
  //             )}
  //           {
  //             (user_id == 1 || rolesPermission?.Master_Data?.Category?.includes("delete")) && (
  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setDeleteDialog(!deleteDialog);
  //                 setDeleteId(params.row.id)
  //               }}>
  //                 <DeleteIcon />
  //               </IconButton>
  //             )}
  //         </Box>
  //       )
  //     }
  //   }
  // ]

  const handleRowClick = (value: any) => {
    setOpenDialog(!openDialog)
    setFormData(value.data)
  }

  // const user_id: any = localStorage.getItem("user_id");
  const user_type: any = localStorage.getItem("user_type");
  console.log('user_type123 user_type == "super_admin", user_type');

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const attr = {
    title: 'Manage Category',
    // buttonName: 'Add',
    buttonName: user_type == "super_admin" || rolesPermission?.Master_Data?.Category?.includes("write") ? 'Add' : "",

    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    loading,
    columns: columnDefs,
    searchValue,
    setSearchValue,
    sortColumn,
    setSortColumn,
    onButtonClick: handleDialog,
    fetchTableData,
    handleRowClick,
    getRowClassName,
    isExport,
    isWrite: user_type == "super_admin" || rolesPermission?.Master_Data?.Category?.includes("write"),
    refresh
  }

  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable  {...attr} />
      {openDialog && <AddUpdate open={openDialog} onClose={handleDialog} formData={formData} setFormData={setFormData} />}
      <DeleteDialog open={deleteDialog} onClose={handleDeleteDialog} deleteId={deleteId} />
    </Box>
  )
}
