import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	inquiryListing: any[],
}

const initialState: InitialState = {
	inquiryListing: [],
};

const inquirySlice = createSlice({
	name: 'inquirySlice',
	initialState: initialState,
	reducers: {
		setInquiryList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.inquiryListing = action.payload
		},
		
	}
});

export const { 
	setInquiryList
} =
inquirySlice.actions;

export default inquirySlice.reducer;
