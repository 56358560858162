import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	allOrderListing: any[],
	cashMemoListing: any[],
	outstandingListing: any[]
	historyListing: any[],
	outstandingSummaryListing: any[],
	outstandingDetailedList: any[]
}

const initialState: InitialState = {
	allOrderListing: [],
	cashMemoListing: [],
	outstandingListing: [],
	historyListing: [],
	outstandingSummaryListing: [],
	outstandingDetailedList:[]
};

const masterDataSlice = createSlice({
	name: 'masterDataSlice',
	initialState: initialState,
	reducers: {
		setAllOderList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.allOrderListing = action.payload
		},
		setCashMemoList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.cashMemoListing = action.payload
		},
		setOutstandingList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.outstandingListing = action.payload
		},
		setHistoryList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.historyListing = action.payload
		},
		setOutstandingSummaryList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.outstandingSummaryListing = action.payload
		},
		setOutstandingDetailedList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.outstandingDetailedList = action.payload
		},
		
	}
});

export const { 
	setAllOderList,
	setCashMemoList,
	setOutstandingList,
	setHistoryList,
	setOutstandingSummaryList,
	setOutstandingDetailedList
} =
masterDataSlice.actions;

export default masterDataSlice.reducer;
