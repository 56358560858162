

import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/AddRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Badge, Box, Button, Card, CardHeader, Grow, Menu, MenuItem, Stack } from '@mui/material';
import FilterDialog from 'pages/order-managment/history/filter-dialog';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment-timezone';

ModuleRegistry.registerModules([ServerSideRowModelModule, CsvExportModule, ExcelExportModule]);

class ExtendedJsPDF extends jsPDF {
    autoTable(options: any) {
        // Add any necessary implementation here or leave it empty
    }
}

function capitalizeFirstLetter(string: String) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function CustomExportButton(props: any) {
    const { gridRef, filterData, title } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const exportAsCsv = useCallback(() => {
        // const onlySelected = gridRef.current!.api.getSelectedRows()?.length > 0 ? true : false
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    const exportAsExcel = useCallback(() => {
        // const onlySelected = gridRef.current!.api.getSelectedRows()?.length > 0 ? true : false
        gridRef.current!.api.exportDataAsExcel();
    }, []);

    const exportAsPdf = useCallback((filterData: any = {}) => {
        let startY = 10
        const doc = new ExtendedJsPDF()
        const clientName = filterData?.client?.name || "";
        const order_id = filterData?.order_id || "";
        const start_date = filterData?.start_date ? moment(filterData?.start_date).format('DD/MM/YYYY') :"";
        const end_date = filterData?.end_date ? moment(filterData?.end_date).format('DD/MM/YYYY') : "";

        doc.setTextColor(0, 0, 0);
        const pageWidth = doc.internal.pageSize.getWidth();
        if (clientName || order_id || start_date || end_date) {
            let text = "";
            if (clientName) {
                text = capitalizeFirstLetter(clientName);
            } 
            
            if (order_id) {
                text += `(Order ID: ${order_id})`;
            }

            if (start_date && end_date) {
                text += `(${start_date} - ${end_date})`;
            }
            doc.setFontSize(13);
            doc.setTextColor(77, 78, 98);
            
            const textWidth = doc.getStringUnitWidth(`${text}`) * 13 / doc.internal.scaleFactor;
            const textX = (pageWidth - textWidth) / 2;
            doc.text(`${text}`, textX, 10);

            startY += 6;
        }
        doc.setFontSize(15);

        const columns = gridRef.current!.api.getAllGridColumns().map((col: any) => (
            { header: col?.colDef?.headerName || col.colId, dataKey: col.colId }
        )).filter((items: any) => !["Action", "Order Image", "View"].includes(items?.header || ""));
        const rowData: any[] = [];
        const columnStyles: any = {};
        gridRef.current!.api.forEachNode((node: any) => {
            const row: any = []
            columns.forEach((items: any) => {
                row.push(gridRef.current!.api.getCellValue({ rowNode: node, colKey: items?.dataKey, useFormatter: true }) || "-");
                columnStyles[items?.dataKey] = { maxWidth: 10 }
            })
            rowData.push(row);
        });
        
        // Initialize finalY to startY value
        let finalY = startY;

        const headers = columns.map((items: any) => items?.header);
        doc.autoTable({
            startY: startY,
            head: [headers],
            body: rowData,
            columnStyles: columnStyles,
            didDrawPage: (data: any) => {
                finalY = data.cursor.y; // Update finalY to the current Y position after the table is drawn
            }
        });

        // Calculate and add the total amount row
        if (title === "Order History") {
            const sum = rowData.reduce((acc, curr) => { 
                let lastAdd = parseFloat(curr[9]) || 0 
                return acc + lastAdd
            }, 0); // Ensure the amount is parsed as a float
            
            const boxX = 14; // Adjust this value to position the box correctly
            const boxY = finalY + 6; // Position below the table with some padding
            const boxWidth = 182; // Adjust the width of the box
            const boxHeight = 10; // Height of the box

            // Draw the rectangle (box)
            doc.rect(boxX, boxY, boxWidth, boxHeight);

            
            // Add text inside the box
            doc.setFontSize(11);
            const sumTextWidth = doc.getStringUnitWidth(`${sum}`) * 11 / doc.internal.scaleFactor;
            
            doc.text("Total Amount", boxX + 5, boxY + 6); // Adjust positions as needed
            doc.text(`${sum.toFixed(2)}`, pageWidth + sumTextWidth - 48, boxY + 6); // Adjust positions as needed
        }
        // doc.text("", marginLeft, 10)
        doc.save('report.pdf')

    }, []);

    return (
        <div>
            <Button
                id="basic-button"
                variant='contained'
                sx={{ background: 'white', color: 'black', ":hover": {background: 'lightgrey', color: 'black'} }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Export
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={exportAsCsv}>Download As CSV</MenuItem>
                <MenuItem onClick={exportAsExcel}>Download As EXCEL</MenuItem>
                <MenuItem onClick={() => exportAsPdf(filterData)}>Download As PDF</MenuItem>
            </Menu>
        </div>
    );
}

const AgGridSelectTable = (props: any) => {
    const {
        title,
        buttonName,
        page,
        setPage,
        total,
        setTotal,
        sort,
        setSort,
        pageSize,
        setPageSize,
        rows,
        loading,
        columns,
        searchValue,
        setSearchValue,
        sortColumn,
        setSortColumn,
        onButtonClick,
        fetchTableData,
        isShadow,
        handleRowClick,
        isExport,
        isWrite,
        isFilter,
        getRowClassName,
        client_id,
        refresh,
    } = props;


    // const user_id: any = localStorage.getItem("user_id");
    const user_type: any = localStorage.getItem("user_type");
    
    // console.log("getRowClassName ", getRowClassName);
    const gridRef = useRef<AgGridReact>(null);
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    // const [gridApi, setGridAPI] = useState<any>(null);
    const [filterData, setFilterData] = useState<any>({});
    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    // useEffect(() => {
    //     fetchTableData(page, pageSize, sort, columns)
    // }, [fetchTableData])


    const getServerSideDatasource = useMemo(() => {
        return {
            getRows: (params: any) => {
                const { startRow, endRow, sortModel, filterModel } = params.request;
                const pageSize = endRow - startRow;
                const page = startRow / pageSize;
                const sort = sortModel && sortModel?.length ? sortModel[0].sort : ""
                const column = sortModel && sortModel?.length ? sortModel[0].colId : ""
                const filters = Object.keys(filterModel).length ? filterModel : "";
                console.log("[Datasource] - rows requested by grid: ", params.request, filters, client_id);
                setTimeout(() => {
                    if (isFilter) {
                        fetchTableData(page, pageSize, sort, column, filters, filterData).then((response: any) => {
                            return params.success({
                                rowData: response?.data.items || [],
                                rowCount: response?.data?.meta?.total || 0,
                            });
                        });
                    } else {
                        fetchTableData(page, pageSize, sort, column, filters, client_id).then((response: any) => {
                            return params.success({
                                rowData: response?.data.items || [],
                                rowCount: response?.data?.meta?.total || 0,
                            });
                        });
                    }
                }, 200);
            },
        }
    }, [client_id, filterData, refresh]);

    const onPaginationChanged = (params: any) => {
        const newPage = params.api.paginationGetCurrentPage();
        const newPageSize = params.api.paginationGetPageSize();
        setPage(newPage);
        setPageSize(newPageSize);
    };

    const onSortChanged = (params: any) => {
        const sortColumn = params.columns[0].colId;
        const sorting = params.columns[0].sort;
        setSort(sorting);
        setSortColumn(sortColumn);
    };

    const defaultColDef = useMemo(() => {
        return {
            suppressFloatingFilterButton: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        };
    }, []);

    const handleFilterDialog = () => {
        setOpenFilterDialog(!openFilterDialog)
    }

    // const onGridReady = (params: any) => {
    //     setGridAPI(params);
    // }

    return (
        <div>
            <Grow in={true}>
                <Card style={containerStyle} sx={{ boxShadow: isShadow ? 'none' : '0px 2px 10px 0px rgba(76, 78, 100, 0.22)' }}>
                    <CardHeader
                        style={{ background: "#1e90ff"}}
                        titleTypographyProps={{ style: { fontWeight: '800', color: 'white' } }}
                        title={title}
                        action={(
                            <Stack direction={'row'} spacing={3}>
                                {
                                    isFilter && (
                                        <Button variant='contained' sx={{ background: 'white', color: 'black', ":hover": {background: 'lightgrey', color: 'black'} }} onClick={handleFilterDialog} startIcon={<FilterListIcon />} color='primary' size='medium'>
                                            {
                                                filterData?.client?.id || filterData?.order_id || filterData?.start_date || filterData?.end_date ? (
                                                    <Badge variant='dot' color='primary' anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                                        Filter
                                                    </Badge>
                                                ) : "Filter"
                                            }
                                        </Button>
                                    )
                                }
                                {((isExport || user_type == "super_admin") ? (<CustomExportButton gridRef={gridRef} filterData={filterData} title={title}/>) : <></>)}
                                {buttonName && (
                                    <Button variant='contained' sx={{ background: 'white', color: 'black', ":hover": {background: 'lightgrey', color: 'black'} }} startIcon={<AddIcon />} onClick={onButtonClick}>
                                        {buttonName}
                                    </Button>
                                )}
                            </Stack>
                        )}
                    />
                    <div className="ag-theme-quartz" style={gridStyle}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columns}
                            rowModelType='serverSide'
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={pageSize}
                            cacheBlockSize={pageSize}
                            paginationPageSizeSelector={[25, 50, 75, 100]}
                            rowSelection='multiple'
                            domLayout='autoHeight'
                            onRowDoubleClicked={isWrite ? handleRowClick : undefined}
                            suppressRowClickSelection={true}
                            suppressCellFocus
                            // onGridReady={onGridReady}
                            serverSideDatasource={getServerSideDatasource}
                            onPaginationChanged={onPaginationChanged}
                            onSortChanged={onSortChanged}
                            rowClassRules={getRowClassName && getRowClassName()}
                        />
                    </div>
                </Card>
            </Grow>
            {
                openFilterDialog && (
                    <FilterDialog open={openFilterDialog} onClose={handleFilterDialog} filterData={filterData} setFilterData={setFilterData} />
                )
            }
        </div>
    );
};

AgGridSelectTable.defaultProps = {
    title: '',
    buttonName: '',
    page: 0,
    setPage: null,
    total: 0,
    setTotal: null,
    sort: 'asc',
    setSort: null,
    pageSize: 25,
    setPageSize: null,
    rows: [],
    columns: [],
    searchValue: '',
    setSearchValue: null,
    sortColumn: '',
    setSortColumn: null,
    onButtonClick: null,
    fetchTableData: null,
    isFilter: false,
    getRowClassName: null,
    client_id: null,
    refresh: false
};

export default AgGridSelectTable;


























// // ** Ag-Grid Imports
// import { AgGridReact } from '@ag-grid-community/react';
// import "@ag-grid-community/styles/ag-grid.css";
// import "@ag-grid-community/styles/ag-theme-quartz.css";
// import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
// import { IServerSideDatasource, ModuleRegistry } from "@ag-grid-community/core";
// // ** React Imports
// import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

// // ** MUI Imports
// import AddIcon from '@mui/icons-material/AddRounded';
// import { Button, Card, CardHeader, Grow } from '@mui/material';

// import { debounce } from "lodash";
// import { useAppDispatch } from 'store/store';
// import { getAllOrderList } from 'store/Thunk/orderManagementThunk';

// // ** Custom Components
// // import ServerSideToolbar from 'views/table/ServerSideToolbar';

// // ** Utils Import
// // import { TableProps } from '@core/utils/types';

// ModuleRegistry.registerModules([ServerSideRowModelModule]);

// const getServerSideDatasource: (response: any) => IServerSideDatasource = (
//     response: any,
// ) => {
//     return {
//         getRows: (params) => {
//             console.log("[Datasource] - rows requested by grid: ", params.request);
//             console.log("[Datasource] - rows requested by grid: response", response);
//             // var response = server.getData(params.request);
//             // adding delay to simulate real server call
//             // setTimeout(() => {
//             //   if (response.success) {
//             //     // call the success callback
//             params.success({
//                 rowData: response.data.items,
//                 rowCount: response.data.meta.total,
//             });
//             //   } else {
//             //     // inform the grid request failed
//             //     params.fail();
//             //   }
//             // }, 200);
//         },
//     };
// };

// const AgGridSelectTable = (props: any) => {
//     const {
//         title,
//         buttonName,
//         page,
//         setPage,
//         total,
//         setTotal,
//         sort,
//         setSort,
//         pageSize,
//         setPageSize,
//         rows,
//         loading,
//         columns,
//         searchValue,
//         setSearchValue,
//         sortColumn,
//         setSortColumn,
//         onButtonClick,
//         fetchTableData,
//         isShadow,
//         handleRowClick,
//         isExport,
//         isWrite,
//         isFilter,
//         isBadge,
//         onFilterButtonClick,
//         getRowClassName
//     } = props;
//     const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
//     const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
//     const dispatch = useAppDispatch();

//     // const [rowData, setRowData] = useState<any>([
//     //     { make: 'Tesla', model: 'Model Y', price: 64950, electric: true, month: 'June' },
//     //     { make: 'Ford', model: 'F-Series', price: 33850, electric: false, month: 'October' },
//     //     { make: 'Toyota', model: 'Corolla', price: 29600, electric: false, month: 'August' },
//     //     { make: 'Mercedes', model: 'EQA', price: 48890, electric: true, month: 'February' },
//     //     { make: 'Fiat', model: '500', price: 15774, electric: false, month: 'January' },
//     //     { make: 'Nissan', model: 'Juke', price: 20675, electric: false, month: 'March' },
//     //     { make: 'Vauxhall', model: 'Corsa', price: 18460, electric: false, month: 'July' },
//     //     { make: 'Volvo', model: 'EX30', price: 33795, electric: true, month: 'September' },
//     //     { make: 'Mercedes', model: 'Maybach', price: 175720, electric: false, month: 'December' },
//     //     { make: 'Vauxhall', model: 'Astra', price: 25795, electric: false, month: 'April' },
//     //     { make: 'Fiat', model: 'Panda', price: 13724, electric: false, month: 'November' },
//     //     { make: 'Jaguar', model: 'I-PACE', price: 69425, electric: true, month: 'May' },
//     //     { make: 'Tesla', model: 'Model Y', price: 64950, electric: true, month: 'June' },
//     //     { make: 'Ford', model: 'F-Series', price: 33850, electric: false, month: 'October' },
//     //     { make: 'Toyota', model: 'Corolla', price: 29600, electric: false, month: 'August' },
//     //     { make: 'Mercedes', model: 'EQA', price: 48890, electric: true, month: 'February' },
//     //     { make: 'Fiat', model: '500', price: 15774, electric: false, month: 'January' },
//     //     { make: 'Nissan', model: 'Juke', price: 20675, electric: false, month: 'March' },
//     //     { make: 'Vauxhall', model: 'Corsa', price: 18460, electric: false, month: 'July' },
//     //     { make: 'Volvo', model: 'EX30', price: 33795, electric: true, month: 'September' },
//     //     { make: 'Mercedes', model: 'Maybach', price: 175720, electric: false, month: 'December' },
//     //     { make: 'Vauxhall', model: 'Astra', price: 25795, electric: false, month: 'April' },
//     //     { make: 'Fiat', model: 'Panda', price: 13724, electric: false, month: 'November' },
//     //     { make: 'Jaguar', model: 'I-PACE', price: 69425, electric: true, month: 'May' },
//     //     { make: 'Tesla', model: 'Model Y', price: 64950, electric: true, month: 'June' },
//     //     { make: 'Ford', model: 'F-Series', price: 33850, electric: false, month: 'October' },
//     //     { make: 'Toyota', model: 'Corolla', price: 29600, electric: false, month: 'August' },
//     //     { make: 'Mercedes', model: 'EQA', price: 48890, electric: true, month: 'February' },
//     //     { make: 'Fiat', model: '500', price: 15774, electric: false, month: 'January' },
//     //     { make: 'Nissan', model: 'Juke', price: 20675, electric: false, month: 'March' },
//     //     { make: 'Vauxhall', model: 'Corsa', price: 18460, electric: false, month: 'July' },
//     //     { make: 'Volvo', model: 'EX30', price: 33795, electric: true, month: 'September' },
//     //     { make: 'Mercedes', model: 'Maybach', price: 175720, electric: false, month: 'December' },
//     //     { make: 'Vauxhall', model: 'Astra', price: 25795, electric: false, month: 'April' },
//     //     { make: 'Fiat', model: 'Panda', price: 13724, electric: false, month: 'November' },
//     //     { make: 'Jaguar', model: 'I-PACE', price: 69425, electric: true, month: 'May' },
//     // ]);
//     // const [columnDefs, setColumnDefs] = useState<any>([
//     //     {
//     //         field: "make",
//     //         checkboxSelection: true
//     //     },
//     //     { field: "model" },
//     //     { field: "price", filter: "agNumberColumnFilter" },
//     //     {
//     //         field: "electric"
//     //     }
//     // ]);
//     const defaultColDef = useMemo(() => {
//         return {
//             filter: "agTextColumnFilter",
//             floatingFilter: true,
//             flex: 1
//         };
//     }, []);

//     // useEffect(() => {
//     //     fetchTableData(sort, searchValue, sortColumn);
//     // }, [fetchTableData, sort, sortColumn, searchValue]);

//     // const handleSortChanged = (event: any) => {
//     //     const newSortModel = event.api.getSortModel();
//     //     if (newSortModel.length > 0) {
//     //         const sorting = sort === 'asc' ? 'desc' : 'asc';
//     //         setSort(sorting);
//     //         setSortColumn(newSortModel[0].colId);
//     //         fetchTableData(sorting, searchValue, newSortModel[0].colId);
//     //     } else {
//     //         const sorting = sort === 'asc' ? 'desc' : 'asc';
//     //         setSort(sorting);
//     //         fetchTableData(sorting, searchValue, sortColumn);
//     //     }
//     // };

//     const handlePageSizeChanged = (event: any) => {
//         console.log("event", event);
//         const newPageSize = event.api.paginationGetPageSize();
//         const currentPage = event.api.paginationGetCurrentPage();
//         console.log("newPageSize", newPageSize);
//         console.log("currentPage", currentPage);

//         setPageSize(newPageSize);
//         setPage(currentPage); // Reset to the first page when changing page size
//         fetchTableData(sort, searchValue, sortColumn);
//     };

//     // const handleSearch = useCallback(
//     //     debounce((value: string) => {
//     //         setSearchValue(value);
//     //         fetchTableData(sort, value, sortColumn);
//     //     }, 1000),
//     //     [fetchTableData, sort, sortColumn]
//     // );

//     const onGridReady = useCallback((params: any) => {
//         console.log("parrrr", params);

//         dispatch(getAllOrderList({ pageSize: 10, page: 0, q: "", sort: "", column: "" })).then((res) => {
//             // create datasource with a reference to the fake server
//             var datasource = getServerSideDatasource(res.payload.data);
//             // register the datasource with the grid
//             params.api!.setGridOption("serverSideDatasource", datasource);
//         })
//     }, []);

//     return (
//         <Grow in={true}>
//             <Card style={containerStyle} sx={{ boxShadow: isShadow ? 'none' : '0px 2px 10px 0px rgba(76, 78, 100, 0.22)' }}>
//                 <CardHeader
//                     title={title}
//                     action={buttonName && (
//                         <Button variant='contained' startIcon={<AddIcon />} onClick={onButtonClick}>
//                             {buttonName}
//                         </Button>
//                     )}
//                 />
//                 <div className="ag-theme-quartz" style={gridStyle}>
//                     <AgGridReact
//                         columnDefs={columns}
//                         // suppressCellSelection={true}
//                         rowModelType='serverSide'
//                         pagination={true}
//                         paginationPageSize={10}
//                         paginationPageSizeSelector={[7, 10, 25, 50]}
//                         domLayout='autoHeight'
//                         onPaginationChanged={(event: any) => handlePageSizeChanged(event)}
//                         // onSortChanged={handleSortChanged}
//                         // onPageSizeChanged={(event: any) => handlePageSizeChanged(event.pageSize)}
//                         onGridReady={onGridReady}
//                         // defaultColDef={defaultColDef}
//                         // overlayNoRowsTemplate='No data available'
//                         // loadingOverlayComponent={<div className="ag-overlay-loading-center">Loading...</div>}
//                         // rowSelection='multiple'
//                         // onRowDoubleClicked={isWrite ? handleRowClick : undefined}
//                         // suppressRowClickSelection={true}
//                         // suppressPaginationPanel={true} // To hide built-in pagination panel
//                     />
//                 </div>
//             </Card>
//         </Grow>
//     );
// };

// AgGridSelectTable.defaultProps = {
//     title: '',
//     buttonName: '',
//     page: 0,
//     setPage: () => { },
//     total: 0,
//     setTotal: () => { },
//     sort: 'asc',
//     setSort: () => { },
//     pageSize: 10,
//     setPageSize: () => { },
//     rows: [],
//     columns: [],
//     searchValue: '',
//     setSearchValue: () => { },
//     sortColumn: '',
//     setSortColumn: () => { },
//     onButtonClick: () => { },
//     fetchTableData: () => { },
//     isFilter: false,
//     isBadge: false
// };

// export default AgGridSelectTable;
