import { Box, IconButton, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import TableServerSide from 'views/table/TableServerSide'

// ** Custom Components
import CustomAvatar from '@core/components/mui/avatar'
import CustomChip from '@core/components/mui/chip'
import { ThemeColor } from '@core/layouts/types'
import { getInitials } from '@core/utils/get-initials'
import { SortType, StatusObj } from '@core/utils/types'

import AddUpdate from './add-update'
import DeleteDialog from './delete-dialog'
import { getAreaList, getPaperTypeList } from 'store/Thunk/masterDataThunk'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getProductList } from 'store/Thunk/masterDataThunk'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AgGridSelectTable from 'views/table/AgGridSelectTable'

// ** renders client column
const renderClient = (params: GridRenderCellParams) => {
  const { row } = params
  const stateNum = Math.floor(Math.random() * 6)
  const states = ['success', 'error', 'warning', 'info', 'primary', 'secondary']
  const color = states[stateNum]

  if (row.avatar.length) {
    return <CustomAvatar src={`/images/avatars/${row.avatar}`} sx={{ mr: 3, width: '1.875rem', height: '1.875rem' }} />
  } else {
    return (
      <CustomAvatar
        skin='light'
        color={color as ThemeColor}
        sx={{ mr: 3, fontSize: '.8rem', width: '1.875rem', height: '1.875rem' }}
      >
        {getInitials(row.full_name ? row.full_name : 'John Doe')}
      </CustomAvatar>
    )
  }
}

const statusObj: StatusObj = {
  1: { title: 'current', color: 'primary' },
  2: { title: 'professional', color: 'success' },
  3: { title: 'rejected', color: 'error' },
  4: { title: 'resigned', color: 'warning' },
  5: { title: 'applied', color: 'info' }
}

export default function Demo() {
  // ** State
  const [refresh, setRefresh] = useState(true); // Just a toggle. Does not any effect on true/false value
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false)
  const [formData, setFormData] = useState<any>({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);

  const { paperTypeList } = useAppSelector((state: any) => state.masterDataReducer);
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.Master_Data?.Paper_Type?.includes("export");


  const dispatch = useAppDispatch();

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      field: 'name',
      headerName: 'Paper Type',
      filter: 'agTextColumnFilter',
    },
    {
      field: "action",
      headerName: 'Action',
      floatingFilter: false,
      sortable: false,
      cellRenderer: (params: any) => {
        if (user_type != "super_admin" && !rolesPermission?.Master_Data?.Paper_Type?.includes("write") && !rolesPermission?.Master_Data?.Paper_Type?.includes("delete")) {
          return (<>-</>)
        }

        return (
          <Box>
            {
              (user_type == "super_admin" || rolesPermission?.Master_Data?.Paper_Type?.includes("write")) && (
                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setOpenDialog(!openDialog)
                  setFormData(params.data)
                }}>
                  <EditIcon />
                </IconButton>
              )}
            {
              (user_type == "super_admin" || rolesPermission?.Master_Data?.Paper_Type?.includes("delete")) && (
                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setDeleteDialog(!deleteDialog);
                  setDeleteId(params.data.id)
                }}>
                  <DeleteIcon />
                </IconButton>
              )
            }
          </Box>
        )
      }
    }
  ]);

  // const columns: GridColDef[] = [
  //   {
  //     minWidth: 90,
  //     field: 'id',
  //     headerName: 'Id',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params

  //       return (
  //         <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     minWidth: 320,
  //     field: 'name',
  //     headerName: 'Paper Type',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.name}
  //       </Typography>
  //     )
  //   },
  //   {
  //     minWidth: 140,
  //     field: '',
  //     headerName: 'Action',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => {
  //       if (user_id != 1 && !rolesPermission?.Master_Data?.Paper_Type?.includes("write") && !rolesPermission?.Master_Data?.Paper_Type?.includes("delete")) {
  //         return (<>-</>)
  //       }

  //       return (
  //         <Box sx={{m: "-10px"}}>
  //           {
  //             (user_id == 1 || rolesPermission?.Master_Data?.Paper_Type?.includes("write")) && (
  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setOpenDialog(!openDialog)
  //                 setFormData(params.row)
  //               }}>
  //                 <EditIcon />
  //               </IconButton>
  //             )}
  //           {
  //             (user_id == 1 || rolesPermission?.Master_Data?.Paper_Type?.includes("delete")) && (
  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setDeleteDialog(!deleteDialog);
  //                 setDeleteId(params.row.id)
  //               }}>
  //                 <DeleteIcon />
  //               </IconButton>
  //             )
  //           }
  //         </Box>
  //       )
  //     }
  //   }
  // ]

  useEffect(() => {
    if (paperTypeList && paperTypeList?.items) {
      setRows(paperTypeList?.items);
      setTotal(paperTypeList?.meta?.total);
    }
  }, [paperTypeList])

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      // setLoading(true);
      return dispatch(getPaperTypeList({ pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : "" })).then((res: any) => {
        // setLoading(false)
        return res.payload.data
      })
    },
    [page, pageSize]
  )

  const handleDialog = () => {
    if (openDialog) {
      setFormData({})
    }
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
    setOpenDialog(!openDialog)
  }

  const handleDeleteDialog = () => {
    setDeleteDialog(false);
    setDeleteId(null);
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
  }

  const handleRowClick = (value: any) => {
    setOpenDialog(!openDialog)
    setFormData(value.data)
  }

  // const user_id: any = localStorage.getItem("user_id");
  const user_type: any = localStorage.getItem("user_type");

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const attr = {
    title: 'Manage Paper Type',
    // buttonName: 'Add',
    buttonName: user_type == "super_admin" || rolesPermission?.Master_Data?.Paper_Type?.includes("write") ? 'Add' : "",

    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    loading,
    columns: columnDefs,
    searchValue,
    setSearchValue,
    sortColumn,
    setSortColumn,
    onButtonClick: handleDialog,
    fetchTableData,
    handleRowClick,
    getRowClassName,
    isExport,
    isWrite: user_type == "super_admin" || rolesPermission?.Master_Data?.Paper_Type?.includes("write"),
    refresh
  }
  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable  {...attr} />
      {openDialog && <AddUpdate open={openDialog} onClose={handleDialog} formData={formData} setFormData={setFormData} />}
      <DeleteDialog open={deleteDialog} onClose={handleDeleteDialog} deleteId={deleteId} />
    </Box>
  )
}
