import React, {
  SyntheticEvent,
  Fragment,
  useState,
  ComponentType,
} from "react";
import { Snackbar, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Slide, { SlideProps } from "@mui/material/Slide";

type TransitionProps = Omit<SlideProps, "direction">;

type paramsStatus = {
  msg: string;
  status: string;
  position: string;
};
const Notification = (params: paramsStatus) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = (event: SyntheticEvent | Event, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const TransitionDown = (props: TransitionProps) => {
    return <Slide {...props} direction="down" />;
  };

  return (
    <main>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        autoHideDuration={10000}
        sx={{ mt: "40px" }}
        TransitionComponent={TransitionDown}
      >
        <Alert severity={params.status === "success" ? "success" : "error"}>
          {params.msg}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Notification;
