import moment from "moment-timezone";

export const isoStringNextDay = (e: any) => {
  const currentDate = new Date(e);
  const nextDay = new Date(currentDate);
  nextDay.setDate(currentDate.getDate() + 1);
  return nextDay.toISOString();
};

export const isoStringPrevDay = (e: any) => {
  const currentDate = new Date(e);
  const nextDay = new Date(currentDate);
  // nextDay.setDate(currentDate.getDate() - 1);
  nextDay.setDate(currentDate.getDate());
  return nextDay.toISOString();
};

export const getLocalDateTime = (date: string, format: string = "MMMM D, YYYY, HH:mm") => {
    if (!date) {
      return "-";
    }
    const d = new Date(date);
    // const timezone = getUserTimeZone(null);
    // if (timezone) {
    //   return moment.tz(d, timezone).format(format);
    // }
    return moment(d).format(format);
};
  
export const getAuthUser = () =>
  localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData") || "") : null;

// display multiple dropdown values in view or listing page.
export const displayMultipleValue = (obj: any, key: string) => {
  if (obj && obj.length > 0) {
    return obj
      .map((val: any) => val[key].name)
      .join(", ")
      .replace(/, ([^,]*)$/, " and $1");
  }
  return "-";
};

export const convertPermissionToArray: any = (permissions: any, parentKey: any = '') => {

  return Object.entries(permissions).flatMap(([key, value]: any) => {
    const currentKey = parentKey ? `${parentKey}::${key}` : key;

    if(value != false){
      if (typeof value === 'object' && value !== null) {
        return convertPermissionToArray(value, currentKey);
      }
       else {
        return { name: currentKey };
      }
    }else{
      return false
    }
  });
};

export const convertArrayToPermission = (permissionArray: any) => {
  let result: any = {};

  permissionArray?.forEach(({ name }: any) => {
    const keys = name.split('::');
    let currentLevel: any = {};
    
    
    keys.forEach((key: any, index: any) => {
      if (index == 0) {
        currentLevel[key] = result[key] ? result[key] : {};
      } else if(index == 1) {
        currentLevel[keys[0]][key] = result[keys[0]][key] ? result[keys[0]][key] : {} 
      } else {
        currentLevel[keys[0]][keys[1]][key] = true
      }
    });
    result = { ...result, ...currentLevel}
  });  
  return result ;
};

export const extractFileNameFromUrl = (imageUrl: any) => {
  const urlParts = imageUrl.split('/');
  
  const filename = urlParts[urlParts.length - 1];

  // // Extract filename and remove timestamp
  // const filename = filenameWithTimestamp.split('_')[0];
  
  return removeFileExtension(filename);
}

//previously used this method. Commented for backup purpose
// export const extractFileNameFromUrl = (imageUrl: any) => {
//   const urlParts = imageUrl.split('/');
  
//   const filenameWithTimestamp = urlParts[urlParts.length - 1];

//   // Extract filename and remove timestamp
//   const filename = filenameWithTimestamp.split('_')[0];
  
//   return removeFileExtension(filename);
// }

function removeFileExtension(filename: any) {
  // Remove the last occurrence of any file extension
  const modifiedFilename = filename.replace(/(\.[^.]+)(.*)$/, '$1');

  return modifiedFilename;
}

export function removePlus(phoneNumber: string): string {
  // Remove all non-numeric characters
  const numericPhoneNumber = phoneNumber?.replace(/\D/g, '');

  // Check if the length is greater than 10
  if (numericPhoneNumber?.length > 10) {
    // If so, remove the '+91' prefix
    return numericPhoneNumber?.slice(3);
  } else {
    // Otherwise, remove any leading '+'
    return numericPhoneNumber?.replace(/^\+/, '');
  }
}

export function getFileExtension(url: any) {
  // Use a regular expression to extract the file extension
  const match = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
  return match ? match[1] : null;
}