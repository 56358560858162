// ** React Imports
import React, { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
  styled
} from "@mui/material";

// ** Third Party Library
import { useLocation, useNavigate } from "react-router-dom";

// ** Store
// import { useAppDispatch, useAppSelector } from "store/store";
// import { AuthLoading } from "store/Slice/AuthSlice";
// import {
//   loginWithGoogle,
//   register,
//   updateUser,
//   verifyRegisterOtp,
//   verifyEmail,
//   verifyTrialExtend,
// } from "store/Thunk/authThunk";

// // ** Custom Components
// import { route_info } from "navigation/route";
// import LogoImage from "components/logo/logo";
// import ScreenLoading from "components/ScreenLoading";
// import ScreenLayout from "components/screenLayout/screen-layout";

// ** Icons
import ScreenLayout from "@core/components/screen-layout";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import EyeOutline from "mdi-material-ui/EyeOutline";

// ** Helper
// import config from "config/config";

interface State {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  showPassword: boolean;
  business: string;
  company_name: string;
}

interface FormValidation {
  privacy?: boolean;
  firstName?: string;
  lastName?: string;
  password?: string;
  email?: string;
}

interface BusinessFormValidation {
  // business?: string;
  company_name?: string;
  last_name?: string;
  first_name?: string;
  reason?: string;
}

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: "400px",
  [theme.breakpoints.down("md")]: {
    maxWidth: 400,
  },
}));

export default function Register() {
//   const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const code: any = new URLSearchParams(location.search).get("code");
  const platform: any = new URLSearchParams(location.search).get("state");
//   const { authLoading } = useAppSelector((state) => state.authReducer);
  const [activeScreen, setActiveScreen] = useState(
    code && platform ? "" : "register"
  );
  const [params, setParams] = useState({ code: "", platform: "" });
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    showPassword: false,
    business: "",
    privacy: false,
    company_name: "",
    reasons: "",
  });
  const [formError, setFormError] = React.useState<FormValidation>();
  const [businessFormError, setBusinessFormError] =
    React.useState<BusinessFormValidation>();
  const [loading, setLoading] = React.useState<boolean>();
  const [isMusicSelect, setIsMusicSelect] = useState(false);
  const [isBusinessType, setIsBusinessType] = useState(false);
  const useridb64: any = new URLSearchParams(location.search).get("useridb64");
  const otpb64: any = new URLSearchParams(location.search).get("otpb64");
  const type: any = new URLSearchParams(location.search).get("type");
  const [screenLoading, setScreenLoading] = React.useState<boolean>(
    useridb64 && otpb64 ? true : false
  );
  const [isSocialLogin, setIsSocialLogin] = useState(false);

  const useArray = [
    "Bulk scheduling ads",
    "Monitor ad campaigns",
    "Testing creatives and settings across platforms"
  ];

  useEffect(() => {
    if (useridb64 && otpb64 && type == "EVL") {
      setScreenLoading(true);
      const _request = {
        useridb64: useridb64,
        otpb64: otpb64,
      };
    //   dispatch(verifyRegisterOtp({ _request, navigate })).then((res: any) => {
    //     if (res.payload.response.data.error) {
    //       setScreenLoading(false);
    //       navigate(route_info.auth_route.login);
    //     }
    //   });
    } else if(useridb64 && otpb64 && type == "EXFT"){
      setScreenLoading(true);
      const _request = {
        useridb64: useridb64,
        otpb64: otpb64,
      };
    //   dispatch(verifyTrialExtend({ _request, navigate })).then((res: any) => {
    //     if (res.payload.response.data.error) {
    //       setScreenLoading(false);
    //       navigate(route_info.auth_route.login);
    //     }
    //   });
    }
  }, []);

//   useEffect(() => {
//     setLoading(authLoading);
//   }, [authLoading]);

  useEffect(() => {
    setParams({ code: code, platform: platform });
  }, []);

  useEffect(() => {
    if (params.code && params.platform) {
      let _request: any = {
        code: params.code,
        platform: params.platform,
      };
      setScreenLoading(true);
    //   dispatch(loginWithGoogle({ _request, navigate }))
    //     .then((res: any) => {
    //       setScreenLoading(false);
    //       if (res.payload.status == 201 || res.payload.status == 200) {
    //         setIsSocialLogin(true);
    //         localStorage.setItem(
    //           "header",
    //           JSON.stringify(res.payload.data.data[0])
    //         );

    //         if (!res.payload.data.data[0].reason_to_use_everywhereai) {
    //           setActiveScreen("create");
    //           setIsBusinessType(true);
    //         } else {
    //           localStorage.setItem(
    //             "userData",
    //             JSON.stringify(res.payload.data.data[0])
    //           );
    //           setIsSocialLogin(false);
    //           navigate(route_info.market_planner.dashboard);
    //         }
    //       } else {
    //         setIsSocialLogin(false);
    //         navigate("/");
    //       }
    //     })
    //     .catch((err: any) => {
    //       setScreenLoading(false);
    //       setIsSocialLogin(false);
    //       navigate("/");
    //     });
    }
  }, [params.code, params.platform]);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let _request: any = {
      email: values.email.trim(),
      password: values.password.trim(),
      privacy: values.privacy,
    };

    // let allError = Validations.validateRegisterForm(_request);
    // if (
    //   Object.entries(allError).length === 0 &&
    //   allError.constructor === Object
    // ) {
    //   handleVerifyEmail();
    // } else {
    //   setFormError(allError);
    // }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, business: event.target.value });

    if ((event.target as HTMLInputElement).value === "music label") {
      setIsMusicSelect(true);
    } else {
      setIsMusicSelect(false);
    }
  };

  const handledCreateAccount = (event: any) => {
    event.preventDefault();
    let validate: any = {};
    let update_request: any = {};
    let _request: any = {};

    if (!isSocialLogin) {
      validate = {
        first_name: values.firstName.trim(),
        last_name: values.lastName.trim(),
        company_name: values.company_name.trim(),
        reason: values?.reasons.trim(),
      };
      update_request = {
        company_name: values.company_name.trim(),
        first_name: values.firstName.trim(),
        last_name: values.lastName.trim(),
        reason_to_use_everywhereai: values?.reasons,
      };

      _request = {
        first_name: values.firstName.trim(),
        last_name: values.lastName.trim(),
        reason_to_use_everywhereai: values?.reasons,
        email: values.email.trim(),
        password: values.password.trim(),
        company_name: values.company_name.trim(),
      };
    } else {
      validate = {
        company_name: values.company_name.trim(),
        reason: values?.reasons.trim(),
      };
      update_request = {
        company_name: values.company_name.trim(),
        reason_to_use_everywhereai: values?.reasons,
      };
      _request = {
        company_name: values.company_name.trim(),
        reason_to_use_everywhereai: values?.reasons,
      };
    }

    // let allError = Validations.validateBusinessForm(validate, isSocialLogin);

    // if (
    //   Object.entries(allError).length === 0 &&
    //   allError.constructor === Object
    // ) {
    //   dispatch(AuthLoading(true));
    //   if (isBusinessType) {
    //     dispatch(updateUser({ update_request, navigate }));
    //   } else {
    //     dispatch(register({ _request, navigate })).then((res: any) => {
    //       if (res?.payload?.response?.status != 201) {
    //         setActiveScreen("create");
    //       }
    //     });
    //   }
    // } else {
    //   setBusinessFormError(allError);
    // }
  };

  const handleVerifyEmail = () => {
    let data = {
      email: values.email,
    };

    // dispatch(verifyEmail({ data })).then((res: any) => {
    //   if (res.payload.status === 200) {
    //     setActiveScreen("create");
    //   }
    // });
  };

  return (
    <>
      {screenLoading ? (
        <Box sx={{ height: "100vh", width: "100%" }}>
          {/* <ScreenLoading /> */}
        </Box>
      ) : (
        <>
          {activeScreen && (
            <ScreenLayout>
              {/* {activeScreen == "register" ? ( */}
                <BoxWrapper>
                  <Box
                    className="sync-align-justify-center"
                    sx={{
                      mb: 8,
                    }}
                  >
                    {/* <LogoImage /> */}
                  </Box>
                  <Box sx={{ mb: 6 }}>
                    <Typography
                      variant="h5"
                      sx={{ mb: 1.5, letterSpacing: "0.18px", fontWeight: 600 }}
                    >
                      Get ready for hyperspeed 🚀
                    </Typography>
                    <Typography variant="body2">
                      Let us save you a lot of time and trouble with scheduling
                    </Typography>
                  </Box>
                  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {/* <TextField
                      autoFocus
                      fullWidth
                      id="firstname"
                      value={values.firstName}
                      label="First Name"
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      sx={{ mb: 4 }}
                      onChange={handleChange("firstName")}
                      error={formError && formError.firstName ? true : false}
                      helperText={formError && formError.firstName}
                    />
                    <TextField
                      fullWidth
                      id="lastname"
                      label="Last Name"
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      value={values.lastName}
                      sx={{ mb: 4 }}
                      onChange={handleChange("lastName")}
                      error={formError && formError.lastName ? true : false}
                      helperText={formError && formError.lastName}
                    /> */}
                    <TextField
                      fullWidth
                      type="email"
                      label="Email"
                      value={values.email}
                      sx={{ mb: 4 }}
                      onChange={handleChange("email")}
                      error={formError && formError.email ? true : false}
                      helperText={formError && formError.email}
                    />
                    <FormControl fullWidth>
                      <InputLabel htmlFor="auth-register-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        label="Password"
                        value={values.password}
                        id="auth-register-password"
                        onChange={handleChange("password")}
                        type={values.showPassword ? "text" : "password"}
                        error={formError && formError.email ? true : false}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              aria-label="toggle password visibility"
                            >
                              {values.showPassword ? (
                                <EyeOutline fontSize="small" />
                              ) : (
                                <EyeOffOutline fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {formError && formError.password && (
                        <FormHelperText error id="password-error">
                          {formError.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.privacy}
                          onChange={() =>
                            setValues({ ...values, privacy: !values.privacy })
                          }
                        />
                      }
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <span>I agree to </span>
                          <Link
                            target="_blank"
                            rel="noopener"
                            href="https://fruitsmusic.com/privacy-policy"
                            sx={{ ml: "8px" }}
                          >
                            <Typography
                              variant="body2"
                              component={Link}
                              sx={{ color: "primary.main" }}
                            >
                              privacy policy
                            </Typography>
                          </Link>
                          <Typography
                            variant="body2"
                            sx={{ color: "#aaa", mx: "8px" }}
                          >
                            &
                          </Typography>
                          <Link
                            href="https://www.fruitsmusic.com/terms-and-conditions"
                            target="_blank"
                            rel="noopener"
                          >
                            <Typography
                              variant="body2"
                              component={Link}
                              sx={{ color: "primary.main" }}
                            >
                              terms & conditions
                            </Typography>
                          </Link>
                        </Box>
                      }
                    />
                    {formError && formError.privacy && !values.privacy && (
                      <FormHelperText
                        error
                        id="password-error"
                        sx={{ mb: 4, ml: "14px" }}
                      >
                        {formError.privacy}
                      </FormHelperText>
                    )}

                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{ mb: 7 }}
                    >
                      Sing In
                    </Button>
                    <Box
                      className="sync-align-justify-center"
                      sx={{
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography sx={{ mr: 2, color: "text.secondary" }}>
                        Already familiar with us?{" "}
                      </Typography>
                      <Typography>
                        <Link onClick={() => navigate("/")}>
                          <Typography
                            component={Link}
                            sx={{ color: "primary.main" }}
                          >
                            Sign in here
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                  </form>
                </BoxWrapper>
             
            </ScreenLayout>
          )}
        </>
      )}
    </>
  );
}
