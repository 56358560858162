// ** React Imports
import { useEffect, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, InputLabel } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Validations from 'helper/validations'
import { postRolePermission, updateRolePermission } from 'store/Thunk/rolePermissionThunk'
import { useAppDispatch } from 'store/store'
import { convertArrayToPermission, convertPermissionToArray } from 'helper/service'


const permissionToObject = (array: any) => {
  const a = array?.map((item: any) => {
    return item.name;
  })

  const inputArray = a?.map((item: any) => {
    return item.split("::")
  })

  const outputJson: any = {};

  inputArray?.forEach((item: any) => {
    const [mainKey, subKey, action] = item;
    const actionArray = ['read', 'write', 'delete', 'export'];
    if (!outputJson[mainKey]) {
      outputJson[mainKey] = actionArray.includes(subKey) ? [] : {};
    }
    if (!Array.isArray(outputJson[mainKey]) && !outputJson[mainKey][subKey]) {
      outputJson[mainKey][subKey] = [];
    }

    if (outputJson[mainKey].constructor === Object) {
      outputJson[mainKey][subKey] = { ...outputJson[mainKey][subKey], [action]: true };
    } else {
      outputJson[mainKey] = { ...outputJson[mainKey], [action]: true };
    }

  });

  return outputJson

}
export default function Index({ open, onClose, updateData }: any) {
  const [formData, setFormData] = useState<any>({});
  // const [formError, setFormError] = useState<any>({role_name: "", permissions: {}});
  const [loading, setLoading] = useState<any>(false);

  const [expanded, setExpanded] = useState("");

  const dispatch: any = useAppDispatch();

  useEffect(() => {
    if (updateData) {
      const permission = permissionToObject(updateData?.Permission);
      setFormData({
        role_name: updateData?.name,
        permission
      })
    }
  }, [updateData])

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : "");
  };

  const rolesArr = [{
    label: "Order Management",
    name: "order_management",
    children: [{
      label: "All Order",
      name: "all_order",
      action: ["read", "write", "delete", "export"]
    }, {
      label: "Cash Memo",
      name: "cash_memo",
      action: ["read", "write", "delete", "export"]
    }, {
      label: "Outstanding",
      name: "outstanding",
      action: ["read", "export"]
    }, {
      label: "Order History",
      name: "history",
      action: ["read", "export"]
    }]
  }, {
    label: "Client Management",
    name: "client_management",
    children: [{
      label: "All Client",
      name: "all_Client",
      action: ["read", "write", "delete", "export"]
    }, {
      label: "Client Login Report",
      name: "Client_Login_Report",
      action: ["read", "export"]
    },]
  }, {
    label: "Staff Management",
    name: "Staff_management",
    children: [{
      label: "Staff",
      name: "all_Staff",
      action: ["read", "write", "delete", "export"]
    },]
  }, {
    label: "Master Data",
    name: "Master_Data",
    children: [{
      label: "Area",
      name: "Area",
      action: ["read", "write", "delete", "export"]
    }, {
      label: "Category",
      name: "Category",
      action: ["read", "write", "delete", "export"]
    }, {
      label: "Client Products",
      name: "Client_Products",
      action: ["read", "write", "delete", "export"]
    },
    {
      label: "Products",
      name: "Products",
      action: ["read", "write", "delete", "export"]
    },
    {
      label: "Paper-Type",
      name: "Paper_Type",
      action: ["read", "write", "delete", "export"]
    },
    {
      label: "Lamination",
      name: "Lamination",
      action: ["read", "write", "delete", "export"]
    },
    ]
  }, {
    label: "A/C Entry",
    name: "AC_Entry",
    children: [{
      label: "A/C Entry",
      name: "AC_Entry",
      action: ["read", "write", "delete", "export"]
    },]
  },
  {
    label: "Inquiry",
    name: "Inquiry",
    children: [{
      label: "Inquiry",
      name: "Inquiry",
      action: ["read", "write", "delete", "export"]
    },]
  },
  {
    label: "Contact Us",
    name: "Contact_Us",
    children: [{
      label: "Contact Us",
      name: "Contact_Us",
      action: ["read", "delete", "export"]
    },]
  },]

  const handleChangeField = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleChangeCheckbox = (mainMenu: any, subMenu: any) => (e: any) => {
    
    if (mainMenu && subMenu) {
      setFormData({
        ...formData,
        permission: {
          ...formData?.permission,
          [mainMenu]: {
            ...(formData?.permission?.[mainMenu] || {}),
            [subMenu]: {
              ...(formData?.permission?.[mainMenu]?.[subMenu] || {}),
              ...((e.target.name == "write" || e.target.name == "delete" || e.target.name == "export") && e.target.checked && { "read": true }),
              [e.target.name]: e.target.checked
            }
          }
        }
      })
    }
  }

  const getValue = (mainMenu: any, subMenu: any, permissionType: any) => {

    if (formData?.permission?.[mainMenu]?.[subMenu]?.[permissionType] !== undefined) {
      return formData?.permission[mainMenu][subMenu][permissionType]
    } else {
      return false
    }
  }

  const handleSave = (event: any) => {
    event.preventDefault()
    const temp = convertPermissionToArray(formData?.permission);
    const filteredData = temp.filter((value: any) => value !== false);

    let _request: any = {
      name: formData?.role_name || '',
      permission: filteredData
    }

    // let allError = Validations.validatePaperTypeForm(_request)
    // if (Object.entries(allError).length === 0 && allError.constructor === Object) {
    setLoading(true);
    if (updateData?.id) {
      dispatch(updateRolePermission({ _request, id: updateData?.id })).then((res: any) => {
        setLoading(false);

        if (res.payload.status == 200) {
          onClose();
        }
      })
    } else {
      dispatch(postRolePermission({ _request })).then((res: any) => {
        setLoading(false);

        if (res.payload.status == 201) {
          onClose();
        }
      })
    }
    // } else {
    //   setFormError(allError)
    // }
  }

  return (
    <Box>
      <Dialog fullWidth maxWidth='md' scroll='body' onClose={onClose} open={open}>
        <form onSubmit={() => console.log("")}>
          <DialogTitle sx={{ textAlign: 'center' }}>
            <Typography variant='h4' component='span'>
              {`Manage Role`}
            </Typography>
            <Typography variant='body2'>Set Role Permissions</Typography>
          </DialogTitle>
          <DialogContent sx={{ p: { xs: 6, sm: 12 } }}>
            <Box sx={{ my: 4 }}>
              <InputLabel>Role Name</InputLabel>
              <TextField
                fullWidth
                size='small'
                placeholder='Role Name'
                name='role_name'
                onChange={handleChangeField}
                value={formData?.role_name || ''}
              // error={formError && formError?.name}
              // helperText={formError && formError?.name ? formError.name : ''}
              />
            </Box>
            <Typography variant='h6'>Role Permissions</Typography>
            <Box>
              {rolesArr?.map((val: any, index: number) => {
                return (
                  <Accordion expanded={expanded === val?.name} onChange={handleChange(val?.name)} sx={{ border: "1px solid lightgrey" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{ borderBottom: "1px solid lightgrey" }}
                    >
                      <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        {val?.label || ""}
                      </Typography>
                      {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails sx={{ mt: 4 }}>
                      {
                        val?.children?.map((child: any, index: any) => {
                          return (
                            <Grid container>
                              <Grid item md={4} sm={4} xs={12}>
                                <Typography variant='subtitle1'>
                                  {child?.label || ""}
                                </Typography>
                              </Grid>
                              <Grid item md={8} sm={8} xs={12}>
                                {
                                  child?.action?.map((action: any, index: any) => {
                                    return (
                                      <FormControlLabel
                                        sx={{ width: "20%" }}
                                        control={
                                          <Checkbox
                                            size='small'
                                            name={action.toLowerCase()}
                                            checked={getValue(val?.name, child?.name, action.toLowerCase())}
                                            onChange={handleChangeCheckbox(val?.name, child?.name)}
                                          />}
                                        label={action} />
                                    )
                                  })
                                }
                              </Grid>
                            </Grid>
                          )
                        })
                      }
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </Box>
          </DialogContent>
          <DialogActions sx={{ pt: 0, display: 'flex', justifyContent: 'center' }}>
            <Box className='demo-space-x'>
              <Button size='large' type='submit' variant='contained' disabled={loading} onClick={handleSave}>
                {loading ? <CircularProgress size={24} color={'secondary'} /> : 'Submit'}
              </Button>
              <Button size='large' color='secondary' variant='outlined' onClick={onClose}>
                Discard
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
