import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { setRolePermissionList } from "store/Slice/rolePermissionSlice";

export const getRolePermissionList = createAsyncThunk(
  "getRolePermissionList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/role_permission?page=${page + 1}&limit=${pageSize}` + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setRolePermissionList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postRolePermission = createAsyncThunk(
  "postRolePermission",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/role_permission`;
      const response: any = await API.post(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateRolePermission = createAsyncThunk(
  "updateRolePermission",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/role_permission/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

// export const deleteRolePermission = createAsyncThunk(
//   "deleteRolePermission",
//   async ({ deleteId }: any, { dispatch }) => {
//     try {
//       let url = `/api/v1/staff/${deleteId}`;
//       const response: any = await API.delete(
//         url
//       );
//       dispatch(notificationSuccess(response?.data?.message));
//       return response;
//     } catch (err: any) {
//       return err;
//     }
//   }
// );
