import { Box } from "@mui/material";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function ImagePreview(props: any): JSX.Element {
  const { imageData, open, onCloseImage } = props;

  return <Box>{open && <Lightbox mainSrc={imageData} onCloseRequest={onCloseImage} />}</Box>;
}

export default ImagePreview;
