import { Box, IconButton, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import TableServerSide from 'views/table/TableServerSide'
// ** Custom Components
import { SortType, StatusObj } from '@core/utils/types'

import AddRoles from './add-role'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getRolePermissionList } from 'store/Thunk/rolePermissionThunk'
import EditIcon from '@mui/icons-material/Edit';
import AgGridSelectTable from 'views/table/AgGridSelectTable'

const statusObj: StatusObj = {
  1: { title: 'current', color: 'primary' },
  2: { title: 'professional', color: 'success' },
  3: { title: 'rejected', color: 'error' },
  4: { title: 'resigned', color: 'warning' },
  5: { title: 'applied', color: 'info' }
}

export default function Demo() {
  const dispatch = useAppDispatch();
  // ** State
  const [refresh, setRefresh] = useState(true); // Just a toggle. Does not any effect on true/false value
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState({});

  const { rolePermissionListing } = useAppSelector((state: any) => state.rolePermissionReducer);
 
  const user_type: any = localStorage.getItem("user_type");
  
  const isExport = user_type == "super_admin" ? true : false;

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      field: "name",
      headerName: "Role Name",
      filter: 'agTextColumnFilter'
    },
    {
      field: "action",
      headerName: 'Action',
      floatingFilter: false,
      sortable: false,
      cellRenderer: (params: any) => {
        const { data } = params

        return (
          <Box>
            <IconButton onClick={(e) => {
              e.stopPropagation()
              setOpenDialog(!openDialog)
              setUpdateData(data)
            }}>
              <EditIcon />
            </IconButton>
          </Box>
        )
      }
    }
  ]);

  // const columns: GridColDef[] = [
  //   {
  //     minWidth: 100,
  //     field: 'id',
  //     headerName: 'Id',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params
  
  //       return (
  //         <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     minWidth: 290,
  //     field: 'name',
  //     headerName: 'Role Name',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params
  
  //       return (
  //         <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {row.name}
  //         </Typography>
  //       )
  //     }
  //   },
  //   // {
  //   //   flex: 0.175,
  //   //   // minWidth: 290,
  //   //   field: 'id',
  //   //   headerName: 'Id',
  //   //   renderCell: (params: GridRenderCellParams) => {
  //   //     const { row } = params
  
  //   //     return (
  //   //       <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //   //         {row.id}
  //   //       </Typography>
  //   //     )
  //   //   }
  //   // },
    
  //   {
  //     minWidth: 120,
  //     field: '',
  //     headerName: 'Action',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params

  //       return (
  //         <Box sx={{m: "-10px"}}>
  //           <IconButton onClick={(e) => {
  //             e.stopPropagation()
  //             setOpenDialog(!openDialog)
  //             setUpdateData(row)
  //           }}>
  //             <EditIcon />
  //           </IconButton>
  //         </Box>
  //       )
  //     }
  //   }
  // ]

  useEffect(() => {    
    if (rolePermissionListing && rolePermissionListing?.items) {
      setRows(rolePermissionListing?.items);
      setTotal(rolePermissionListing?.meta?.total);
    }
  }, [rolePermissionListing])

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      // if(page && pageSize){
        // setLoading(true);
        return dispatch(getRolePermissionList({ pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : ""})).then((res) => {
          // setLoading(false)
          return res.payload.data
          
        })
      // }
    },
    []
  )

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const handleDialog = () => {
    if (openDialog) {
      setUpdateData({})
    }
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
    setOpenDialog(!openDialog)
  }

  const attr = {
    title: 'Roles And Permission',
    // buttonName: 'Add Role',
    buttonName: user_type == "super_admin" ? 'Add Role' : "",
    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    loading,
    setPageSize,
    rows,
    columns: columnDefs,
    searchValue,
    setSearchValue,
    sortColumn,
    setSortColumn,
    onButtonClick: handleDialog,
    fetchTableData,
    getRowClassName,
    isExport,
    isWrite: user_type == "super_admin" ? true : false,
    refresh
  }

  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable  {...attr} />
      {openDialog && <AddRoles open={openDialog} onClose={handleDialog} updateData={updateData} />}
    </Box>
  )
}
