// *** React Imports
import React, { SyntheticEvent, useEffect, useState } from 'react'

//MUI Imports
import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  CircularProgress,
  FormHelperText,
  Grid,
  GridProps,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import CustomChip from '@core/components/mui/chip'

import CheckIcon from '@mui/icons-material/CheckRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'

// ** Icon Imports
import Close from 'mdi-material-ui/Close'
import Plus from 'mdi-material-ui/Plus'

import MDDialog from '@core/components/MDDialog'
import Repeater from '@core/components/repeater'
import Validations from 'helper/validations'

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { postAccountEntryList, updateAccountEntryList } from 'store/Thunk/accountEntryThunk'
import { useAppDispatch, useAppSelector } from 'store/store'
import CustomDateInput from 'views/customeDateInput/CustomDateInput'
import { isoStringPrevDay } from 'helper/service'
import { getClientList } from 'store/Thunk/clientManagmentThunk'
import config from 'configs/config'

interface Props {
  open?: any
  onClose?: any
  formData?: any 
  setFormData?: any
  handleViewClick?: any
  handleApproveDialog?: any
  handleDeclineDialog?: any
  setAcEntryId?: any
}

// const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
//   paddingRight: 0,
//   marginTop: theme.spacing(3),
//   display: 'flex',
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   border: `1px solid ${theme.palette.divider}`,
//   '& .col-title': {
//     top: '-1.5rem',
//     position: 'absolute'
//   },
//   '& .MuiInputBase-input': {
//     color: theme.palette.text.secondary
//   },
//   [theme.breakpoints.down('lg')]: {
//     '& .col-title': {
//       top: '0',
//       position: 'relative'
//     }
//   }
// }))

// const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
//   paddingTop: theme.spacing(6),
//   paddingBottom: theme.spacing(5.5),
//   '& .repeater-wrapper + .repeater-wrapper': {
//     marginTop: theme.spacing(8)
//   }
// }))

// const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'flex-start',
//   padding: theme.spacing(2, 0),
//   borderLeft: `1px solid ${theme.palette.divider}`
// }))

function Index({ open, onClose, formData, setFormData, handleViewClick, handleApproveDialog, 
  handleDeclineDialog, setAcEntryId }: Props) {
  const [count, setCount] = useState<number>(1)
  const [formError, setFormError] = useState<any>({})
  const [loading, setLoading] = useState(false);
  
  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [openACClient, setOpenACClient] = useState<boolean>(false);
  const [clientOptions, setClientOptions] = useState<any[]>([]);
  const dispatch = useAppDispatch();

  const user_type: any = localStorage.getItem("user_type");
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);

  const { clientListing } = useAppSelector((state: any) => state.clientReducer);

  useEffect(() => {
    setClientLoading(false);
    setClientOptions(clientListing?.items ? clientListing?.items?.filter((item: any) => item?.email !== config.SUPER_ADMIN) : []);
  }, [clientListing]);

  useEffect(() => {
    if (!openACClient) {
      setClientOptions([]);
    } else {
      dispatch(getClientList({ pageSize: 1000, page: 0 }));
    }
    setClientLoading(openACClient);
  }, [openACClient]);

  const handleOnChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setFormData({
      ...formData,
      [name]: value ? value : null
    })
  }

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSave = (event: any) => {
    event.preventDefault()
    
    let _request: any = {
        journal_item_name: formData?.journal_item_name || '',
        credit_debit: formData?.credit_debit || '',
        client_id: formData?.client?.id || '',
        debitor_creditor_type: formData?.debitor_creditor_type || '',
        amount: formData?.amount || '',
        date: formData?.date || '',
        hours: formData?.hours || '',
        payment_type: formData?.payment_type || '',
        remarks: formData?.remarks || '',
    }

    let allError = Validations.validateACEntryForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      setLoading(true);
      if (formData?.id) {
        const id = formData?.id
        dispatch(updateAccountEntryList({ _request, id })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 200) {
            onClose();
          }
        })
      } else {
        dispatch(postAccountEntryList({ _request })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 201) {
            onClose();
          }
        })
      }
    } else {
      setFormError(allError)
    }
  }

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: formData?.id ? `Edit A/C Entry` : `Add A/C Entry`,
    size: 'md',
    saveTbtText: 'Save'
  }
  console.log("formData ", formData);
  
  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Journal Item Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Journal Item Name'
              name='journal_item_name'
              onChange={handleChange}
              value={formData?.journal_item_name || ''}
              error={formError && formError?.journal_item_name}
              helperText={formError && formError?.journal_item_name ? formError.journal_item_name : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client Name</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACClient}
              onOpen={() => {
                setOpenACClient(true);
              }}
              onClose={() => {
                setOpenACClient(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("client")}
              value={formData?.client ? formData?.client : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={clientOptions}
              loading={clientLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError.client ? true : false}
                  helperText={formError && formError.client}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {clientLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {formError && formError?.client_id && (<FormHelperText error>{formError?.client_id}</FormHelperText>)}
            {/* <TextField
              fullWidth
              size='small'
              placeholder='Client Name'
              name='client_id'
              onChange={handleChange}
              value={formData?.client_id || ''}
              error={formError && formError?.client_id}
              helperText={formError && formError?.client_id ? formError.client_id : ''}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Debitor/Creditor Type</InputLabel>
            <Select fullWidth size='small' name='debitor_creditor_type' 
            value={formData?.debitor_creditor_type} error={formError.debitor_creditor_type} onChange={handleChange}>
                            <MenuItem value='client'>Client</MenuItem>
                            <MenuItem value='admin'>Admin</MenuItem>
                          </Select>
                          {formError && formError?.debitor_creditor_type && 
                          <FormHelperText error>{formError?.debitor_creditor_type}</FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Amount</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Amount'
              name='amount'
              onChange={handleChange}
              value={formData?.amount || ''}
              error={formError && formError?.amount}
              helperText={formError && formError?.amount ? formError.amount : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Hours</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Hours'
              name='hours'
              onChange={handleChange}
              value={formData?.hours || ''}
              error={formError && formError?.hours}
              helperText={formError && formError?.hours ? formError.hours : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Payment Type</InputLabel>
            <Select fullWidth size='small'  name='payment_type' 
            value={formData?.payment_type} error={formError.payment_type} onChange={handleChange}>
                            <MenuItem value='cash'>Cash</MenuItem>
                            <MenuItem value='online'>Online</MenuItem>
                          </Select>
                          {formError && formError?.payment_type && 
                          <FormHelperText error>{formError?.payment_type}</FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Credit/Debit</InputLabel>
           
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formData?.gender} onChange={(e, value) =>  setFormData({...formData, credit_debit: value})}
              >
                <FormControlLabel value="credit" control={<Radio />} label="Credit" />
                <FormControlLabel value="debit" control={<Radio />} label="Debit" />
              </RadioGroup>
              {formError && formError?.credit_debit && 
                          <FormHelperText error>{formError?.credit_debit}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Remark</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={2}
              size='small'
              placeholder='Remarks'
              name='remarks'
              onChange={handleChange}  
              value={formData?.remarks || ''}
              // error={formError && formError?.remarks}
              // helperText={formError && formError?.remarks ? formError.remarks : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Date</InputLabel>
            {/* <TextField
              fullWidth
              size='small'
              placeholder='Date'
              name='date'
              onChange={handleChange}
              value={formData?.date || ''}
              error={formError && formError?.date}
              helperText={formError && formError?.date ? formError.date : ''}
            /> */}
            <CustomDateInput 
            error={formError && formError?.date} 
            helperText={formError && formError?.date} 
            value={formData?.date ? isoStringPrevDay(formData?.date) : ""} 
            onChange={(e: any) =>setFormData({ ...formData, date: isoStringPrevDay(e) })} />
          </Grid>

          <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', alignItems: 'center', mt: 5 }}>
        <InputLabel>Status: </InputLabel>
            
            <Box sx={{ marginLeft: '10px'}}>
            {formData?.status && <CustomChip
              size='small'
              skin='light'
              color={formData.status == "approved" ? "success" : "error"}
              label={formData.status}
              sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
            />}
            {user_type != "super_admin" && !rolesPermission?.order_management?.all_order?.includes("write") && (<CustomChip
              size='small'
              skin='light'
              color={"info"}
              label={"Pending"}
              sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
            />)}

        {!formData?.status && (
          <Box sx={{mb: 1}}>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            handleApproveDialog();
            setAcEntryId(formData)
          }}
            size='small' sx={{
              width: "34px", height: "36px",
              borderRadius: "14px", color: "#fff", backgroundColor: "#4caf50", "&:hover": {
                backgroundColor: "#45a049"
              }
            }}>
            <CheckIcon />
          </IconButton>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            handleDeclineDialog();
            setAcEntryId(formData)
          }} sx={{
            ml: "10px",
            width: "34px", height: "36px",
            borderRadius: "14px", color: "#fff", backgroundColor: "#ff5252", "&:hover": {
              backgroundColor: "#d13a3a"
            }
          }}>
            <ClearIcon />
          </IconButton>
        </Box>
          )}
            </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', alignItems: 'center', mt: 5 }}>
        <InputLabel>Order Image: </InputLabel>
        <Box sx={{ marginLeft: '10px'}}>{formData?.receipt_img ?  <Button variant='outlined' onClick={handleViewClick(formData?.receipt_img)}>
              View
            </Button> : <Box>--</Box>}</Box>
        </Grid>
        </Grid>    
      </Box>
    </MDDialog>
  )
}

export default Index
