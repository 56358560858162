// *** React Imports
import { SyntheticEvent, useState } from 'react'

//MUI Imports
import {
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  GridProps,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'

// ** Icon Imports
import Close from 'mdi-material-ui/Close'
import Plus from 'mdi-material-ui/Plus'

import MDDialog from '@core/components/MDDialog'
import Repeater from '@core/components/repeater'
import Validations from 'helper/validations'

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAppDispatch } from 'store/store'
import { postAreaData, postCategoryData, updateCategoryList } from 'store/Thunk/masterDataThunk'

interface Props {
  open?: any
  onClose?: any
  formData?: any 
  setFormData?: any
}

const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
  paddingRight: 0,
  marginTop: theme.spacing(3),
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .col-title': {
    top: '-1.5rem',
    position: 'absolute'
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.down('lg')]: {
    '& .col-title': {
      top: '0',
      position: 'relative'
    }
  }
}))

const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(5.5),
  '& .repeater-wrapper + .repeater-wrapper': {
    marginTop: theme.spacing(8)
  }
}))

const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

function Index({ open, onClose, formData, setFormData }: Props) {
  const [count, setCount] = useState<number>(1)
  // const [formData, setFormData] = useState<any>({})
  const [formError, setFormError] = useState<any>({})
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleFileChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] })
  }

  const dispatch = useAppDispatch();
  
  const onSave = (event: any) => {
    event.preventDefault()
    let _request: any = {
      name: formData?.name || '',   
      sort: parseInt(formData?.sort) || '' ,
      category_image: formData?.category_image,
      image: formData?.image
    }

    let allError = Validations.validateCategoryForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      setLoading(true);
      if(formData?.id){
        const id = formData?.id
        dispatch(updateCategoryList({_request,id })).then((res: any) => {
          setLoading(false);
          
        if(res.payload.status == 200){
          onClose();
        }
        })
      }else{
        dispatch(postCategoryData({_request})).then((res: any) => {
          setLoading(false);
          
        if(res.payload.status == 201){
          onClose();
        }
        })
      }
    } else {
      setFormError(allError)
    }
  }

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: formData?.id ? `Edit Category` : `Add Category`,
    size: 'md',
    saveTbtText: 'Save',
    loading
  }

  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Name'
              name='name'
              onChange={handleChange}
              value={formData?.name || ''}
              error={formError && formError?.name}
              helperText={formError && formError?.name ? formError.name : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Sort</InputLabel>
            <TextField
              fullWidth
              size='small'
              type='number'
              placeholder='Sort'
              name='sort'
              onChange={handleChange}
              value={formData?.sort || ''}
              error={formError && formError?.sort}
              helperText={formError && formError?.sort ? formError.sort : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Category Image</InputLabel>
            <TextField
              fullWidth
              type='file'
              size='small'
              placeholder='Category Image'
              name='image'
              onChange={handleFileChange}
              // error={formError && formError?.category_image}
              // helperText={formError && formError?.category_image ? formError.category_image : ''}
            />
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  )
}

export default Index
