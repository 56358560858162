import { Box, IconButton, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import TableServerSide from 'views/table/TableServerSide'

// ** Custom Components
import CustomAvatar from '@core/components/mui/avatar'
import { ThemeColor } from '@core/layouts/types'
import { getInitials } from '@core/utils/get-initials'
import { SortType, StatusObj } from '@core/utils/types'

import DeleteDialog from './delete-dialog'
import { useAppDispatch, useAppSelector } from 'store/store'  
import DeleteIcon from '@mui/icons-material/Delete';
import { getContactUsList } from 'store/Thunk/contactUs'
import AgGridSelectTable from 'views/table/AgGridSelectTable'

export default function Index() {
  // ** State
  const [refresh, setRefresh] = useState(true); // Just a toggle. Does not any effect on true/false value
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false)
  const [formData, setFormData] = useState<any>({});
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);

  //   const { acEntryListing } = useAppSelector((state: any) => state.acEntryReducer);
  const { contactUsListing } = useAppSelector((state: any) => state.contactUsReducer);
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.Contact_Us?.Contact_Us?.includes("export");
  // const user_id: any = localStorage.getItem("user_id");
  const user_type: any = localStorage.getItem("user_type");

  const dispatch = useAppDispatch();

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      width: "120px",
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      width: "170px",
      field: "name",
      headerName: "Name",
      filter: 'agTextColumnFilter',
    },
    {
      width: "170px",
      field: "phone",
      headerName: "Phone",
      filter: 'agTextColumnFilter',
    },
    {
      width: "200px",
      field: "email",
      headerName: "Email",
      filter: 'agTextColumnFilter',
    },
    {
      width: "200px",
      field: "subject",
      headerName: "Subject",
      filter: 'agTextColumnFilter',
    },
    {
      width: "200px",
      field: "message",
      headerName: "Message",
      filter: 'agTextColumnFilter',
    },
    {
      width: "150px",
      field: "action",
      headerName: 'Action',
      floatingFilter: false,
      sortable: false,
      cellRenderer: (params: any) => {
        
        if (user_type == "super_admin" || rolesPermission?.Contact_Us?.Contact_Us?.includes("delete")) {
          
          return (
            <Box>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                setDeleteDialog(!deleteDialog);
                setDeleteId(params.data.id)
              }}>
                <DeleteIcon />
              </IconButton>
            </Box>
          )
        } else {
          return (<>-</>)
        }

      }
    }
  ]);

  // const columns: GridColDef[] = [
  //   {
  //     // flex: 0.175,
  //     maxWidth: 80,
  //     field: 'id',
  //     headerName: 'Id',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params

  //       return (
  //         <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.175,
  //     // minWidth: 120,
  //     headerName: 'Name',
  //     field: 'name',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.name}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.175,
  //     // minWidth: 110,
  //     field: 'phone',
  //     headerName: 'Phone',
  //     type: "number",
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.phone}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.125,
  //     field: 'email',
  //     // minWidth: 80,
  //     headerName: 'Email',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.email}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.125,
  //     field: 'subject',
  //     // minWidth: 80,
  //     headerName: 'Subject',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.subject}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.125,
  //     field: 'message',
  //     // minWidth: 80,
  //     headerName: 'Message',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.message}
  //       </Typography>
  //     )
  //   },
  //   {
  //     minWidth: 120,
  //     field: '',
  //     headerName: 'Action',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => {

  //       if (user_id == 1 || rolesPermission?.Contact_Us?.Contact_Us?.includes("delete")) {
          
  //         return (
  //           <Box sx={{m: "-10px"}}>
  //             <IconButton onClick={(e) => {
  //               e.stopPropagation();
  //               setDeleteDialog(!deleteDialog);
  //               setDeleteId(params.row.id)
  //             }}>
  //               <DeleteIcon />
  //             </IconButton>
  //           </Box>
  //         )
  //       } else {
  //         return (<>-</>)
  //       }

  //     }
  //   }
  // ]

  useEffect(() => {
    if (contactUsListing && contactUsListing?.items) {
      setRows(contactUsListing?.items);
      setTotal(contactUsListing?.meta?.total);
    }
  }, [contactUsListing])

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      // setLoading(true)
      return dispatch(getContactUsList({ pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : ""})).then((res) => {
        // setLoading(false)
        return res.payload.data
      })
    },
    []
  )

  const handleDeleteDialog = () => {
    setDeleteDialog(false);
    setDeleteId(null);
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
  }

  const handleRowClick = (value: any) => {
    setOpenDialog(!openDialog)
    setFormData(value.data)
  }

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const attr = {
    title: 'Contact Us',
    buttonName: '',
    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    columns: columnDefs,
    searchValue,
    setSearchValue,
    sortColumn,
    setSortColumn,
    getRowClassName,
    loading,
    fetchTableData,
    handleRowClick,
    isExport,
    isWrite: false,
    refresh
  }
  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable {...attr} />
      <DeleteDialog open={deleteDialog} onClose={handleDeleteDialog} deleteId={deleteId} />
    </Box>
  )
}
