// *** React Imports
import React, { useEffect, useState } from 'react'

//MUI Imports
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  GridProps,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import MDDialog from '@core/components/MDDialog'
import Validations from 'helper/validations'

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { postStaffList, updateStaffList } from 'store/Thunk/staffManagmentThunk'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getAreaList } from 'store/Thunk/masterDataThunk'
import CustomDateInput from 'views/customeDateInput/CustomDateInput'
import { getClientList } from 'store/Thunk/clientManagmentThunk'
import { getRolePermissionList } from 'store/Thunk/rolePermissionThunk'
import { isoStringNextDay, isoStringPrevDay } from 'helper/service'

interface Props {
  open?: any
  onClose?: any
  formData?: any
  setFormData?: any
}

const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
  paddingRight: 0,
  marginTop: theme.spacing(3),
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .col-title': {
    top: '-1.5rem',
    position: 'absolute'
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.down('lg')]: {
    '& .col-title': {
      top: '0',
      position: 'relative'
    }
  }
}))

function Index({ open, onClose, formData, setFormData }: Props) {
  const dispatch = useAppDispatch();
  const [formError, setFormError] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const [areaLoading, setAreaLoading] = useState<boolean>(false);
  const [openACArea, setOpenACArea] = useState<boolean>(false);
  const [areaOptions, setAreaOptions] = useState<any[]>([]);
  const [openACClient, setOpenACClient] = useState<boolean>(false);
  const [rolesLoading, setRolesLoading] = useState<boolean>(false);
  const [rolesOptions, setRolesOptions] = useState<any[]>([]);

  const { areaListing } = useAppSelector((state: any) => state.masterDataReducer)
  // const { clientListing } = useAppSelector((state: any) => state.clientReducer)
  const { rolePermissionListing } = useAppSelector((state: any) => state.rolePermissionReducer);

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleChangeFile = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e?.target?.files[0] })
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  useEffect(() => {
    setAreaLoading(false);
    setAreaOptions(areaListing?.items ? areaListing?.items : []);
  }, [areaListing]);

  useEffect(() => {
    if (!openACArea) {
      setAreaOptions([]);
    } else {
      dispatch(getAreaList({ pageSize: 1000, page: 0 }));
    }
    setAreaLoading(openACArea);
  }, [openACArea]);

  const handleOnChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setFormData({
      ...formData,
      [name]: value ? value : null
    })
  }

  const onSave = (event: any) => {

    event.preventDefault()
    let _request: any = {
      username: formData?.username || "",
      password: formData?.password || "",
      name: formData?.name || "",
      address: formData?.address || "",
      city: formData?.city || "",
      language: formData?.language || "",
      phone: formData?.phone || "",
      birth_date: formData?.birth_date || "",
      gender: formData?.gender || "",
      blood_group: formData?.blood_group || "",
      emergency_contact: formData?.emergency_contact || "",
      salary: formData?.salary || "",
      join_date: formData?.join_date || "",
      reference: formData?.reference || "",
      guarantor: formData?.guarantor || "",
      marital: formData?.marital || "",
      hobbies: formData?.hobbies || "",
      photo: formData?.photo || "",
      id_proof: formData?.id_proof || "",
      ...(formData?.area?.id && {area_id: formData?.area?.id || ""}),
      return_charge: formData?.return_charge || "",
      commission: formData?.commission || "",
      zone: formData?.zone || "",
      tiffin_capacity: formData?.tiffin_capacity || "",
      remarks: formData?.remarks || "",
      role_id: formData?.role?.id || ""
    }

    let allError = Validations.validateStaffForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      const _reqFormData = new FormData();

      Object.keys(_request).map((val: any, index: any) => {
        _reqFormData.append(val,_request[val]);
      });
      setLoading(true);
      if (formData?.id) {
        const id = formData?.id
        dispatch(updateStaffList({ _reqFormData, id })).then((res: any) => {
          setLoading(false);
          if (res.payload.status == 200) {
            onClose();
          }
        })
      } else {
        dispatch(postStaffList({ _reqFormData })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 201) {
            onClose();
          }
        })
      }
    } else {
      setFormError(allError)
    }
  }

  useEffect(() => {
    setRolesLoading(false);
    setRolesOptions(rolePermissionListing?.items ? rolePermissionListing?.items : []);
  }, [rolePermissionListing]);

  useEffect(() => {
    if (!openACClient) {
      setRolesOptions([]);
    } else {
      const pageSize = 1000;
      const page = 0;
      const qq = "";
      const sort = "";
      const column = "";
      dispatch(getRolePermissionList({ pageSize, page }));
    }
    setRolesLoading(openACClient);
  }, [openACClient]);

  const dialogProps = {
    open,
    onClose,
    loading,
    onSave,
    title: formData?.id ? `Edit Delivery Boy` : `Add New Delivery Boy` ,
    size: 'md',
    saveTbtText: 'Save'
  }

  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>User Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Select User Name'
              name='username'
              onChange={handleChange}
              value={formData?.username || ''}
              error={formError && formError?.username}
              helperText={formError && formError?.username ? formError.username : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <TextField
              fullWidth
              size="small"

              name='password'
              onChange={handleChange}
              value={formData?.password || ''}
              error={formError && formError?.password}
              helperText={formError && formError?.password ? formError.password : ''}
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Name'
              name='name'
              onChange={handleChange}
              value={formData?.name || ''}
              error={formError && formError?.name}
              helperText={formError && formError?.name ? formError.name : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Address</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={2}
              size='small'
              placeholder='Address'
              name='address'
              onChange={handleChange}
              value={formData?.address || ''}
              error={formError && formError?.address}
              helperText={formError && formError?.address ? formError.address : ''}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Roll</InputLabel>
            <Select fullWidth size='small' defaultValue='Select Product'
            value={formData?.category} name="category" onChange={handleChange} error={formError?.category}>
                            <MenuItem value='Accountant'>Accountant</MenuItem>
                            <MenuItem value='Seniour Accountant'>Seniour Accountant</MenuItem>
                            <MenuItem value='Juniour Accountant'>Juniour Accountant</MenuItem>
                          </Select>
                          {formError && formError.client_id && (
              <FormHelperText error id='password-error'
              value={formData?.category} name="category" onChange={handleChange} error={formError?.category}>
                {formError.client_id}
              </FormHelperText>
            )}
          </Grid> */}
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>City</InputLabel>
            <Select fullWidth size='small' defaultValue='Select Product'
              value={formData?.city} name="city" onChange={handleChange} error={formError?.city}>
              <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
              <MenuItem value='Baroda'>Baroda</MenuItem>
              <MenuItem value='Gandhinagar'>Gandhinagar</MenuItem>
            </Select>
            {formError && formError.city && (
              <FormHelperText error id='password-error'>
                {formError.city}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Language</InputLabel>
            <Select fullWidth size='small' defaultValue='Select Language'
              value={formData?.language} name="language" onChange={handleChange} error={formError?.language}>
              <MenuItem value='Gujarati'>Gujarati</MenuItem>
              <MenuItem value='Hindi'>Hindi</MenuItem>
              <MenuItem value='English'>English</MenuItem>
            </Select>
            {formError && formError.language && (
              <FormHelperText error id='password-error'>
                {formError.language}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* dropdown */}
            <InputLabel>Roles </InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACClient}
              onOpen={() => {
                setOpenACClient(true);
              }}
              onClose={() => {
                setOpenACClient(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("role")}
              value={formData?.role ? formData?.role : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={rolesOptions}
              loading={rolesLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError?.role ? true : false}
                  // helperText={formError && formError?.role}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {rolesLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {formError && formError.role && (
              <FormHelperText error id='password-error'>
                {formError.role}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Phone</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Phone'
              name='phone'
              onChange={handleChange}
              value={formData?.phone || ''}
              error={formError && formError?.phone}
              helperText={formError && formError?.phone ? formError.phone : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Birth Date</InputLabel>
            {/* <TextField
              fullWidth
              size='small'
              placeholder='Birth Date'
              name='birth_date'
              onChange={handleChange}
              value={formData?.birth_date || ''}
              error={formError && formError?.birth_date}
              helperText={formError && formError?.birth_date ? formError.birth_date : ''}
            /> */}
             <CustomDateInput 
                error={formError && formError?.birth_date} 
                helperText={formError && formError?.birth_date} 
                value={formData?.birth_date ? isoStringPrevDay(formData?.birth_date) : ""} 
                onChange={(e: any) => setFormData({ ...formData, birth_date: isoStringNextDay(e) })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Gender</InputLabel>

            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formData?.gender} onChange={(e, value) => setFormData({ ...formData, gender: value })}
              >
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="female" control={<Radio />} label="Female" />
              </RadioGroup>
              {formError && formError.gender && (
                <FormHelperText error id='password-error'>
                  {formError.gender}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Blood Group</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Blood Group'
              name='blood_group'
              onChange={handleChange}
              value={formData?.blood_group || ''}
              error={formError && formError?.blood_group}
              helperText={formError && formError?.blood_group ? formError.blood_group : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Emergency Contact</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Emergency Contact'
              name='emergency_contact'
              onChange={handleChange}
              value={formData?.emergency_contact || ''}
              error={formError && formError?.emergency_contact}
              helperText={formError && formError?.emergency_contact ? formError.emergency_contact : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Salary</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Salary'
              name='salary'
              onChange={handleChange}
              value={formData?.salary || ''}
              error={formError && formError?.salary}
              helperText={formError && formError?.salary ? formError.salary : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Join Date</InputLabel>
            {/* <TextField
              fullWidth
              size='small'
              placeholder='Join Date'
              name='join_date'
              onChange={handleChange}
              value={formData?.join_date || ''}
              error={formError && formError?.join_date}
              helperText={formError && formError?.join_date ? formError.join_date : ''}
            /> */}
            <CustomDateInput 
            error={formError && formError?.join_date} 
            helperText={formError && formError?.join_date} 
            value={formData?.birth_date ? isoStringPrevDay(formData?.birth_date) : ""} 
            onChange={(e: any) => setFormData({ ...formData, join_date: isoStringNextDay(e) })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Referance</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='reference'
              name='reference'
              onChange={handleChange}
              value={formData?.reference || ''}
              error={formError && formError?.reference}
              helperText={formError && formError?.reference ? formError.reference : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Guarantor</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Guarantor'
              name='guarantor'
              onChange={handleChange}
              value={formData?.guarantor || ''}
              error={formError && formError?.guarantor}
              helperText={formError && formError?.guarantor ? formError.guarantor : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Hobbies</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Hobbies'
              name='hobbies'
              onChange={handleChange}
              value={formData?.hobbies || ''}
              error={formError && formError?.hobbies}
              helperText={formError && formError?.hobbies ? formError.hobbies : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Marital</InputLabel>

            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={formData?.marital} onChange={(e, value) => setFormData({ ...formData, marital: value })}
              >
                <FormControlLabel value="married" control={<Radio />} label="Married" />
                <FormControlLabel value="unmarried" control={<Radio />} label="Unmarried" />
              </RadioGroup>
              {formError && formError.marital && (
                <FormHelperText error id='password-error'>
                  {formError.marital}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Photo</InputLabel>
            <TextField
              fullWidth
              type='file'
              size='small'
              placeholder='Photo'
              name='photo'
              onChange={handleChangeFile}
            // value={formData?.photo || ''}
            // error={formError && formError?.photo}
            // helperText={formError && formError?.photo ? formError.photo : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>ID Proof</InputLabel>
            <TextField
              fullWidth
              type='file'
              size='small'
              placeholder='ID Proof'
              name='id_proof'
              onChange={handleChangeFile}
            // value={formData?.id_proof || ''}
            // error={formError && formError?.id_proof}
            // helperText={formError && formError?.id_proof ? formError.id_proof : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Area</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACArea}
              onOpen={() => {
                setOpenACArea(true);
              }}
              onClose={() => {
                setOpenACArea(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("area")}
              value={formData?.area ? formData?.area : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={areaOptions}
              loading={areaLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError?.area ? true : false}
                  helperText={formError && formError?.area}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {areaLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {formError && formError.area_id && (
              <FormHelperText error id='password-error'>
                {formError.area_id}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Return Charge</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Return Charge'
              name='return_charge'
              onChange={handleChange}
              value={formData?.return_charge || ''}
              error={formError && formError?.return_charge}
              helperText={formError && formError?.return_charge ? formError.return_charge : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Commission</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Commission'
              name='commission'
              onChange={handleChange}
              value={formData?.commission || ''}
              error={formError && formError?.commission}
              helperText={formError && formError?.commission ? formError.commission : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Zone</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Zone'
              name='zone'
              onChange={handleChange}
              value={formData?.zone || ''}
              error={formError && formError?.zone}
              helperText={formError && formError?.zone ? formError.zone : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Tiffin Capacity</InputLabel>
            <TextField
              type='text'
              fullWidth
              placeholder='24'
              defaultValue='24'
              InputProps={{ inputProps: { min: 0 } }}
              size='small'
              name='tiffin_capacity'
              onChange={handleChange}
              value={formData?.tiffin_capacity || ''}
              error={formError && formError?.tiffin_capacity}
              helperText={formError && formError?.tiffin_capacity ? formError.tiffin_capacity : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Remark</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={2}
              size='small'
              placeholder='Remark'
              name='remarks'
              onChange={handleChange}
              value={formData?.remarks || ''}
              // error={formError && formError?.remarks}
              // helperText={formError && formError?.remarks ? formError.remarks : ''}
            />
          </Grid>
        </Grid>


      </Box>
    </MDDialog>
  )
}

export default Index
