import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { setClientList } from "store/Slice/clientSlice";
import { setAreaList, setCategoryList, setClientProductList, setProductDropdownList, setProductList } from "store/Slice/masterDataSlice";
import { notificationFail, notificationSuccess } from "store/Slice/notificationSlice";
import { setInquiryList } from "store/Slice/inquirySlice";

   /*--------------------------------- Product Apis ---------------------------------------------------*/

export const getInquiryList = createAsyncThunk(
  "getInquiryList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/inquiry?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setInquiryList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postInquiryData = createAsyncThunk(
  "postInquiryData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/inquiry`;
      const response: any = await API.post(
        url,
        _request
      );
      if(response?.status == 201){
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        dispatch(notificationFail(response?.data?.message));
      }
      return response;
    } catch (err: any) {
      dispatch(notificationFail("Something went wrong"));
      return err;
    }
  }
);

export const updateInquiryList = createAsyncThunk(
  "updateInquiryList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/inquiry/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      if(response?.status == 200){
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        dispatch(notificationFail(response?.data?.message));
      }
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteInquiryData = createAsyncThunk(
  "deleteInquiryData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/inquiry/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);