//MUI Imports
import {
  Box,
  Typography
} from '@mui/material'

import MDDialog from '@core/components/MDDialog'
import { useAppDispatch } from 'store/store'
import { deleteClientData } from 'store/Thunk/clientManagmentThunk'
import { useState } from 'react'
import { deleteAllOrderData } from 'store/Thunk/orderManagementThunk'

interface Props {
  open?: any
  onClose?: any
  deleteId?: any
}

function Index({ open, onClose, deleteId }: Props) {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

  const onSave = () => {
    setLoading(true)
    if (deleteId) {
      dispatch(deleteAllOrderData({ deleteId })).then((res: any) => {
        setLoading(false);
        onClose();
      })
    }
  }
  
  const dialogProps = {
    open,
    onClose,
    onSave,
    loading,
    title: `Delete Client`,
    size: 'sm',
    saveTbtText: 'Delete'
  }

  

  return (
    <MDDialog {...dialogProps}>
      <Box p={4} sx={{mb: 10}}>
        <Typography variant='h6'>Are you sure want to delete?</Typography>
      </Box>
    </MDDialog>
  )
}

export default Index
