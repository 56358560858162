const MESSAGES = {
  //login
  LOGIN: {
    ERROR: {
      EMAIL: {
        EMPTY: 'Email is required.',
        EMAIL_VALIDATION: 'Please enter valid email address'
      },
      PASSWORD: {
        EMPTY: 'Password is required.',
        STRONG_PASSWORD: 'Please enter valid password.'
      }
    }
  },
  //register
  REGISTER: {
    ERROR: {
      FIRST_NAME: {
        EMPTY: 'Please enter first name.',
        INVALID: 'Please enter valid first name',
        WHITE_SPACE: 'Name should not contain space'
      },
      LAST_NAME: {
        EMPTY: 'Please enter last name.',
        INVALID: 'Please enter valid last name',
        WHITE_SPACE: 'Name should not contain space'
      },
      EMAIL: {
        EMPTY: 'Please enter email.',
        EMAIL_VALIDATION: 'Please enter valid email address'
      },
      CONFIRM_EMAIL: {
        EMPTY: 'Please enter confirm email.',
        INVALID: "Emails don't match"
      },
      PASSWORD: {
        EMPTY: 'Please enter password.',
        STRONG_PASSWORD: 'Please enter valid password.'
      },
      BUSINESS: {
        EMPTY: 'Please Select One Of Above.'
      },
      PRIVACY: {
        EMPTY: 'Please Select Privacy Policy & terms'
      },
      NOT_MATCH: {
        INVALID: 'Password Did Not Match'
      },
      COMPANY_NAME: {
        EMPTY: 'Please enter company name.'
      }
    }
  },
  //add child
  //clientName, remark, orderDate, mobileNo, creditLimit
  ORDER: {
    ERROR: {
      CLIENT_ID: {
        EMPTY: 'Please enter client id.'
      },
      CLIENT_NAME: {
        EMPTY: 'Please enter client name.'
      },
      REMARK: {
        EMPTY: 'Please enter remark.'
      },
      ORDER_DATE: {
        EMPTY: 'Please enter order date.'
      },
      MOBILE_NO: {
        EMPTY: 'Please enter mobile no.'
      },
      CREDIT_LIMIT: {
        EMPTY: 'Please enter credit limit.'
      }
    }
  },
  CASH_MEMO: {
    ERROR: {
      CLIENT_NAME: {
        EMPTY: 'Please enter client name.'
      },
      REMARK: {
        EMPTY: 'Please enter remark.'
      },
      ORDER_DATE: {
        EMPTY: 'Please enter order date.'
      },
      GST_NO:{
        EMPTY: 'Please enter gst No.'
      },
      INVOICE_NO: {
        EMPTY: 'Please enter invoice No.'
      },
      MOBILE_NO: {
        EMPTY: 'Please enter mobile no.'
      },
      CREDIT_LIMIT: {
        EMPTY: 'Please enter credit limit.'
      }
    },
    
  },
  OUTSTANDING: {
    ERROR: {
      TYPE: {
        EMPTY: 'Please select type.'
      },
      CLIENT: {
        EMPTY: 'Please select client.'
      },
      
    },
    
  },
  CLIENT: {
    ERROR: {
      USER_NAME: {
        EMPTY: 'Please enter user name.'
      },
      PASSWORD: {
        EMPTY: 'Please enter password.'
      },
      NAME: {
        EMPTY: 'Please enter name.'
      },
      CLIENT_CATEGORY: {
        EMPTY: 'Please enter client category.'
      },
      FILE_CODE: {
        EMPTY: 'Please enter file code.'
      },
      CLIENT_CODE: {
        EMPTY: 'Please enter client code.'
      },
      EMAIL: {
        EMPTY: 'Please enter email.'
      },
      HOME_ADDRESS: {
        EMPTY: 'Please enter home address.'
      },
      TIN_NO: {
        EMPTY: 'Please enter tin no.'
      },
      GST_NO: {
        EMPTY: 'Please enter gst no.'
      },
      CITY: {
        EMPTY: 'Please enter city.'
      },
      STATE: {
        EMPTY: 'Please enter client state.'
      },
    
    MOBILE: {
      EMPTY: 'Please enter user mobile.'
    },
    PHONE: {
      EMPTY: 'Please enter phone.'
    },
    REMARKS: {
      EMPTY: 'Please enter remarks.'
    },
    CREDIT_LIMIT: {
      EMPTY: 'Please enter credit limit.'
    },
    CREDIT_LIMIT_HOUR: {
      EMPTY: 'Please enter credit limit hour.'
    },
      
    },
    
  },
  STAFF: {
    ERROR: {
      USERNAME: {
        EMPTY: 'Please enter user name.'
      },
      PASSWORD: {
        EMPTY: 'Please enter password.'
      },
      NAME: {
        EMPTY: 'Please enter name.'
      },
      ADDRESS: {
        EMPTY: 'Please enter address.'
      },
      CITY: {
        EMPTY: 'Please enter city.'
      },
      LANGUAGE: {
        EMPTY: 'Please enter language.'
      },
      PHONE: {
        EMPTY: 'Please enter phone.'
      },
      BIRTH_DATE: {
        EMPTY: 'Please enter birth_date.'
      },
      GENDER: {
        EMPTY: 'Please enter gender.'
      },
      BLOOD_GROUP: {
        EMPTY: 'Please enter blood group.'
      },
      EMERGENCY_CONTACT: {
        EMPTY: 'Please enter emergency contact.'
      },
      SALARY: {
        EMPTY: 'Please enter salary.'
      },
    
    JOIN_DATE: {
      EMPTY: 'Please enter join date.'
    },
    REFERENCE: {
      EMPTY: 'Please enter reference.'
    },
    GUARANTOR: {
      EMPTY: 'Please enter guarantor.'
    },
    MARITAL: {
      EMPTY: 'Please enter marital.'
    },
  HOBBIES: {
    EMPTY: 'Please enter hobbies.'
  },
  PHOTO: {
    EMPTY: 'Please enter photo.'
  },
  ID_PROOF: {
    EMPTY: 'Please enter id proof.'
  },
  AREA: {
    EMPTY: 'Please enter area.'
  },
  RETURN_CHARGE: {
    EMPTY: 'Please enter return charge.'
  },

COMMISION: {
  EMPTY: 'Please enter commission.'
},
ZONE: {
  EMPTY: 'Please enter zone.'
},
TIFIN_CAPACITY: {
  EMPTY: 'Please enter tiffin capacity.'
},
ROLE: {
  EMPTY: 'Please enter role.'
},
REMARKS: {
  EMPTY: 'Please enter remarks.'
},
    },
    
  },
  AREA: {
    ERROR: {
      AREA_NAME: {
        EMPTY: 'Please enter area name.'
      }
      
    },
    
  },
  PAPER_TYPE: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter paper type name.'
      }
      
    },
    
  },

  CLIENT_PRODUCT: {
    ERROR: {
      CLIENT_NAME: {
        EMPTY: 'Please enter client name.'
      },
      PRODUCT_NAME: {
        EMPTY: 'Please enter product name.'
      },
      PRICE: {
        EMPTY: 'Please enter price.'
      },
      EFFECTIVE_DATE: {
        EMPTY: 'Please enter effective date.'
      },
      STATUS: {
        EMPTY: 'Please enter status.'
      },
      STOP_DATE: {
        EMPTY: 'Please enter stop date.'
      }
      
    },
    
  },
  CATEGORY: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter name.'
      },
      SORT: {
        EMPTY: 'Please enter sort.'
      }
      
    },
    
  },
  PRODUCT: {
    ERROR: {
      NAME: {
        EMPTY: 'Please enter name.'
      },
      CODE: {
        EMPTY: 'Please enter code.'
      },
      PRICE_A: {
        EMPTY: 'Please enter price a.'
      },
      PRICE_B: {
        EMPTY: 'Please enter price b.'
      },
      PRICE_C: {
        EMPTY: 'Please enter price c.'
      },
      PRICE_D: {
        EMPTY: 'Please enter price d.'
      },
      PRICE_E: {
        EMPTY: 'Please enter price e.'
      },
      PRICE_F: {
        EMPTY: 'Please enter price f.'
      },
      COURIER_CHARGES: {
        EMPTY: 'Please enter courier charges.'
      },
      TRANSPORT_CHARGES: {
        EMPTY: 'Please enter transport charges.'
      },
      DISCOUNT: {
        EMPTY: 'Please enter discount.'
      },
      CGST: {
        EMPTY: 'Please enter cgst.'
      },
      SGST: {
        EMPTY: 'Please enter sgst.'
      },
      IGST: {
        EMPTY: 'Please enter igst.'
      },
      SORT: {
        EMPTY: 'Please enter sort.'
      },
      HSN_CODE: {
        EMPTY: 'Please enter hsn_code.'
      },
      PRINT_COPY: {
        EMPTY: 'Please enter print copy.'
      },
      IMAGE: {
        EMPTY: 'Please enter image.'
      },
      BANNER: {
        EMPTY: 'Please enter banner.'
      },
      CATEGORY: {
        EMPTY: 'Please enter category.'
      },
      HEIGHT: {
        EMPTY: 'Please select height and width.'
      }
    },
    
  },
  // journal_item_name, credit_debit, client_id, debitor_creditor_type, amount, 
  // date, hours, payment_type, remarks
  AC_ENTRY: {
    ERROR: {
      JOURNAL_ITEM_NAME: {
        EMPTY: 'Please enter journal item name.'
      },
      CREDIT_DEBIT: {
        EMPTY: 'Please enter credit_debit.'
      },
      CLIENT_ID: {
        EMPTY: 'Please enter client_id.'
      },
      DEBITOR_CREDITOR_TYPE: {
        EMPTY: 'Please enter debitor_creditor_type.'
      },
      AMOUNT: {
        EMPTY: 'Please enter amount.'
      },
      DATE: {
        EMPTY: 'Please enter date.'
      },
      HOURS: {
        EMPTY: 'Please enter hours.'
      },
      PAYMENT_TYPE: {
        EMPTY: 'Please enter payment_type.'
      },
      REMARKS: {
        EMPTY: 'Please enter remarks.'
      },
    },
  },
  INQUIRY: {
    ERROR: {
      CLIENT_ID: {
        EMPTY: 'Please select the client.'
      },
      CLIENT_NAME: {
        EMPTY: 'Please enter client name.'
      },
      PAPER_TYPE_ID: {
        EMPTY: 'Please select paper type.'
      },
      LAMINATION_ID: {
        EMPTY: 'Please select lamination.'
      },
      MOBILE: {
        EMPTY: 'Please enter mobile.'
      },
      JOB_WIDTH: {
        EMPTY: 'Please enter job width.'
      },
      JOB_HEIGHT: {
        EMPTY: 'Please enter job height.'
      },
      QUANTITY: {
        EMPTY: 'Please enter quantity.'
      },
      OTHER_DETAILS: {
        EMPTY: 'Please enter other_details.'
      }
    },
    
  },
}

export default MESSAGES
