import { Box, Button, Tooltip, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import TableServerSide from 'views/table/TableServerSide'

// ** Custom Components
import CustomAvatar from '@core/components/mui/avatar'
import { getInitials } from '@core/utils/get-initials'
import { SortType, StatusObj } from '@core/utils/types'

import AddUpdate from 'views/dialog/order/AddUpdate'
import FilterDialog from './filter-dialog'
import { getHistoryList } from 'store/Thunk/orderManagementThunk'
import { useAppDispatch, useAppSelector } from 'store/store'
import { extractFileNameFromUrl } from 'helper/service'
import AgGridSelectTable from 'views/table/AgGridSelectTable'

// ** renders client column

const statusObj: StatusObj = {
  1: { title: 'current', color: 'primary' },
  2: { title: 'professional', color: 'success' },
  3: { title: 'rejected', color: 'error' },
  4: { title: 'resigned', color: 'warning' },
  5: { title: 'applied', color: 'info' }
}

const onDownload = async (imageUrl: any, orderId: any, fileCode: any) => {
 console.log("imageUrl: any, orderId: any, fileCode: any ", imageUrl, orderId, fileCode);
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);

  const filename = extractFileNameFromUrl(decodeURIComponent(imageUrl));
  downloadLink.download = `${orderId}${fileCode ? "-" + fileCode : ""}-${filename}`; // Adjust the filename based on the image type

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

}


export default function Demo() {

  const dispatch = useAppDispatch();
  // ** State
  // const [filterData, setFilterData] = useState<any>({});
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false)
  // const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const { historyListing } = useAppSelector((state: any) => state.orderManagementReducer);
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.order_management?.history?.includes("export");

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      width: "100px",
      field: "order_id",
      headerName: "Order Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      width: "150px",
      field: "client_name",
      headerName: "Client Name",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.order?.client?.name || ""
      }
    },
    {
      field: "product_name",
      headerName: "Product Name",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.product?.name || ""
      }
    },
    {
      width: "120px",
      field: "hsn_code",
      headerName: "HSN Code",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.product?.hsn_code || ""
      }
    },
    {
      width: "130px",
      field: 'image',
      headerName: 'Image',
      autoHeight: true,
      sortable: false,
      floatingFilter: false,
      cellRenderer: (params: any) => (
        <>
          {
            params?.data?.order_image ? 
            <Button onClick={(e) => {
              e.stopPropagation();
              onDownload(params?.data?.order_image, params?.data?.order_id, params?.data?.order?.client?.file_code)
            }}>
              Download
            </Button> : <Box>--</Box>
          }
        </>
      )
    },
    {
      width: "100px",
      field: "quantity",
      headerName: "Qty",
      filter: 'agNumberColumnFilter',
    },
    {
      width: "100px",
      field: "height",
      headerName: "Height",
      filter: 'agNumberColumnFilter',
    },
    {
      width: "100px",
      field: "width",
      headerName: "Width",
      filter: 'agNumberColumnFilter',
    },
    {
      width: "100px",
      field: "rate",
      cellStyle: { textAlign: "right" },
      headerName: "Rate",
      filter: 'agNumberColumnFilter',
    },
    {
      width: "100px",
      field: "amount",
      cellStyle: { textAlign: "right" },
      headerName: "Amount",
      filter: 'agTextColumnFilter',
    },
  ]);
  
// const columns: GridColDef[] = [
//   {
//     flex: 0.1,
//     minWidth: 130,
//     field: 'client_name',
//     headerName: 'Client Name',
//     renderCell: (params: GridRenderCellParams) => {
//       const { row } = params

//       return (
//         <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
//           <Tooltip title={row?.order?.name}>
//             {row?.order?.client?.name}
//           </Tooltip>
//         </Typography>
//       )
//     }
//   },
//   // {
//   //   // flex: 0.175,
//   //   // minWidth: 120,
//   //   headerName: 'Date',
//   //   field: 'start_date',
//   //   renderCell: (params: GridRenderCellParams) => (
//   //     <Typography variant='body2' sx={{ color: 'text.primary' }}>
//   //       {params.row.start_date}
//   //     </Typography>
//   //   )
//   // },
//   {
//     flex: 0.1,
//     // minWidth: 110,
//     field: 'order_id',
//     headerName: 'Order Id',
//     type: "number",
//     renderCell: (params: GridRenderCellParams) => (
//       <Typography variant='body2' sx={{ color: 'text.primary' }}>
//         {params?.row?.order?.id}
//       </Typography>
//     )
//   },
//   {
//     flex: 0.2,
//     field: 'product',
//     minWidth: 200,
//     headerName: 'Product',
//     renderCell: (params: GridRenderCellParams) => (
//       <Typography variant='body2' sx={{ color: 'text.primary' }}>
//         {params?.row?.product?.name}
//       </Typography>
//     )
//   },
//   {
//     flex: 0.1,
//     field: 'hsn_code',
//     // minWidth: 80,
//     headerName: 'HSN Code',
//     type: "number",
//     renderCell: (params: GridRenderCellParams) => (
//       <Typography variant='body2' sx={{ color: 'text.primary' }}>
//         {params.row?.product?.hsn_code}
//       </Typography>
//     )
//   },
//   {
//     flex: 0.1,
//     minWidth: 180,
//     field: 'image',
//     headerName: 'Image',
//     sortable: false,
//     renderCell: (params: GridRenderCellParams) => (
//       <>{params?.row?.order_image ? <Button sx={{m: "-12px"}} onClick={(e) => {
//         e.stopPropagation();
//         onDownload(params?.row?.order_image, params?.row?.order_id, params?.row?.order?.client?.file_code)
//       }}>
//         Download
//       </Button> : <Box>--</Box>}</>
//     )
//   },
//   {
//     flex: 0.05,
//     field: 'quantity',
//     // minWidth: 80,
//     headerName: 'Qty',
//     type: "number",
//     renderCell: (params: GridRenderCellParams) => (
//       <Typography variant='body2' sx={{ color: 'text.primary' }}>
//         {params?.row?.quantity || "-"}
//       </Typography>
//     )
//   },
//   {
//     flex: 0.075,
//     // minWidth: 80,
//     field: 'height',
//     headerName: 'Height',
//     type: "number",
//     renderCell: (params: GridRenderCellParams) => (
//       <Typography variant='body2' sx={{ color: 'text.primary' }}>
//         {params.row?.height || "-"}
//       </Typography>
//     )
//   },
//   {
//     flex: 0.075,
//     field: 'width',
//     // minWidth: 80,
//     headerName: 'width',
//     type: "number",
//     renderCell: (params: GridRenderCellParams) => (
//       <Typography variant='body2' sx={{ color: 'text.primary' }}>
//         {params.row?.width || "-"}
//       </Typography>
//     )
//   },
//   {
//     flex: 0.075,
//     field: 'rate',
//     // minWidth: 80,
//     headerName: 'Rate',
//     type: "number",
//     renderCell: (params: GridRenderCellParams) => (
//       <Typography variant='body2' sx={{ color: 'text.primary' }}>
//         {params.row?.rate || "-"}
//       </Typography>
//     )
//   },
//   {
//     flex: 0.075,
//     field: 'amount',
//     // minWidth: 80,
//     headerName: 'Amount',
//     type: "number",
//     renderCell: (params: GridRenderCellParams) => (
//       <Typography variant='body2' sx={{ color: 'text.primary' }}>
//         {params?.row?.amount || "-"}
//       </Typography>
//     )
//   },
//   // {
//   //   // flex: 0.125,
//   //   field: 'order_total',
//   //   // minWidth: 80,
//   //   headerName: 'Order Total',
//   //   renderCell: (params: GridRenderCellParams) => (
//   //     <Typography variant='body2' sx={{ color: 'text.primary' }}>
//   //       {params.row?.amount}
//   //     </Typography>
//   //   )
//   // },
//   {
//     flex: 0.05,
//     field: 'print_receipt',
//     sortable: false,
//     minWidth: 150,
//     headerName: 'Print Receipt',
//     renderCell: (params: GridRenderCellParams) => (
//       <Typography variant='body2' sx={{ color: 'text.primary' }}>
//         {params?.row?.print_receipt || "-"}
//       </Typography>
//     )
//   },
// ]

  useEffect(() => {
    if (historyListing && historyListing?.items) {
      setRows(historyListing?.items);
      setTotal(historyListing?.meta?.total);
    }
  }, [historyListing]);
  
  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "", filterData: any = {}) => {
      // setLoading(true)
      return dispatch(getHistoryList({  pageSize, page, sort, column, 
        filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : "", 
        order_id: filterData?.order_id?.toString(), 
        client_id: filterData?.client?.id?.toString(), 
        start_date: filterData?.start_date ? new Date(filterData?.start_date).toISOString() : "", 
        end_date: filterData?.end_date ? new Date(filterData?.end_date)?.toISOString() : "" 
      })).then((res: any) => {
        // setLoading(false)
        return res.payload.data
      })
    },
    []
  )
  // const fetchTableData = useCallback(
  //   async (sort: SortType, q: string, column: string) => {
  //     setLoading(true)
  //     dispatch(getHistoryList({ pageSize, page, q, sort, column, client_id: filterData?.client?.id?.toString(), order_id: filterData?.order_id?.toString(), start_date: filterData?.start_date ? new Date(filterData?.start_date).toISOString() : "", end_date: filterData?.end_date ? new Date(filterData?.end_date)?.toISOString() : "" })).then(() => {
  //       setLoading(false)
  //     })
  //   },
  //   [page, pageSize, filterData?.client?.id, filterData?.order_id, filterData?.start_date, filterData?.end_date]
  // )

  const handleDialog = () => {
    setOpenDialog(!openDialog)
  }

  // const handleFilterDialog = () => {
  //   setOpenFilterDialog(!openFilterDialog)
  // }

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const attr = {
    title: 'Order History',
    // buttonName: 'Add',
    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    columns: columnDefs,
    searchValue,
    setSearchValue,
    sortColumn,
    loading,
    setSortColumn,
    onButtonClick: handleDialog,
    fetchTableData,
    getRowClassName,
    isExport,
    isWrite: false,
    isFilter: true,
    // isBadge: filterData?.client?.id || filterData?.order_id || filterData?.start_date || filterData?.end_date ? true : false,
    // onFilterButtonClick: handleFilterDialog
  }
  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable  {...attr} />
      <AddUpdate open={openDialog} onClose={handleDialog} />
      {/* {
        openFilterDialog && (
          <FilterDialog open={openFilterDialog} onClose={handleFilterDialog} filterData={filterData} setFilterData={setFilterData} />
        )
      } */}
    </Box>
  )
}
