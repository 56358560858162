import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ResponseData } from 'store/interface';

const intialState: any = {
	reportData: {},
	top10LowestBalanceClient: []
};

const DashboardSlice = createSlice({
	name: 'dashboard',
	initialState: intialState,
	reducers: {
        ReportData: (
			state: Draft<any>,
			action: PayloadAction<ResponseData>
		) => {
			state.reportData = action.payload;
		},
		Top10LowestBalanceClient: (
			state: Draft<any>,
			action: PayloadAction<ResponseData>
		) => {
			state.top10LowestBalanceClient = action.payload
		},
	}
});


export const {
	ReportData,
	Top10LowestBalanceClient
} = DashboardSlice.actions;

export default DashboardSlice.reducer;