import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { notificationSuccess } from "store/Slice/notificationSlice";
import { ReportData, Top10LowestBalanceClient } from "store/Slice/DashboardSlice";

export const getReport = createAsyncThunk(
    "getReport",
    async ({ filterBy }: any, { dispatch }) => {
        try {
            const response: any = await API.get(
                `users/report/visitor?filterBy=${filterBy}`
            );
            if (response?.status == 200) {
                dispatch(ReportData(response.data.data))
                dispatch(notificationSuccess(response?.data?.message));
            }
            return response;
        } catch (err: any) {
            return err;
        }
    }
);

export const getTop10LowestBalanceClient = createAsyncThunk(
    "getTop10LowestBalanceClient",
    async (_, { dispatch }) => {
        try {
            const response: any = await API.get(
                `api/v1/report/lowest-balance`
            );
            if (response?.status == 200) {
                dispatch(Top10LowestBalanceClient(response.data.data));
                dispatch(notificationSuccess(response?.data?.message));
            }
            return response;
        } catch (err: any) {
            return err;
        }
    }
);
