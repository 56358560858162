// ** Icon imports
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DatasetIcon from '@mui/icons-material/Dataset';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GradingIcon from '@mui/icons-material/Grading';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HomeIcon from '@mui/icons-material/Home';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

// ** Type import
import { HorizontalNavItemsType } from '@core/layouts/types'

const navigation = (): HorizontalNavItemsType => {
  return [
    {
      title: 'Dashboard',
      icon: HomeIcon,
      path: '/dashboard'
    },
    // {
    //   title: 'User Management',
    //   icon: PersonIcon
    // },
    {
      title: 'Order Management',
      icon: GradingIcon,
      children: [
        {
          title: 'All Order',
          path: '/order/all'
        },
        {
          title: 'Cash Memo',
          path: '/order/memo'
        },
        {
          title: 'Outstanding',
          path: '/order/outstanding'
        },
        {
          title: 'Order History',
          path: '/order/history'
        }
      ]
    },
    {
      title: 'Client Management',
      icon: HandshakeIcon,
      children: [
        {
          title: 'All Client',
          path: '/client/all'
        },
        {
          title: 'Client Login Report',
          path: '/client/report'
        },
      ]
    },
    {
      title: 'Staff Management',
      icon: Diversity3Icon,
      path: '/staff'
    },
    {
      title: 'Master Data',
      icon: DatasetIcon ,
      children: [
        {
          title: 'Area',
          path: '/master/area'
        },
        {
          title: 'Category',
          path: '/master/category'
        },
        {
          title: 'Client Products',
          path: '/master/client-product'
        },
        {
          title: 'Products',
          path: '/master/products'
        },
        {
          title: 'Roles & Permission',
          path: '/master/roles-permission'
        },
      ]
    },
    {
      title: 'A/C Entry',
      icon: AccountBalanceWalletIcon,
      path: '/ac-entry'
    },
    {
      title: 'Inquiry',
      icon: LiveHelpIcon,
      path: '/inquiry'
    },
  ]
}

export default navigation
