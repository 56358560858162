// *** React Imports
import React from 'react'
import { SyntheticEvent, useEffect, useState } from 'react'

//MUI Imports
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import MDDialog from '@core/components/MDDialog'
import Validations from 'helper/validations'

import { getLaminationList, getPaperTypeList, getProductList, postCategoryData, postClientProductData, updateClientProductList } from 'store/Thunk/masterDataThunk'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getClientList } from 'store/Thunk/clientManagmentThunk'
import { postInquiryData, updateInquiryList } from 'store/Thunk/inquiryThunk'
interface Props {
  open?: any
  onClose?: any
  formData?: any
  setFormData?: any
}

function Index({ open, onClose, formData, setFormData }: Props) {
  const dispatch = useAppDispatch();
  const [formError, setFormError] = useState<any>({})
  const [loading, setLoading] = useState(false);  

  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [openACClient, setOpenACClient] = useState<boolean>(false);
  const [clientOptions, setClientOptions] = useState<any[]>([]);

  const [paperTypeLoading, setPaperTypeLoading] = useState<boolean>(false);
  const [openACpaperType, setOpenACPaperType] = useState<boolean>(false);
  const [paperTypeOptions, setPaperTypeOptions] = useState<any[]>([]);

  const [laminationLoading, setLaminationLoading] = useState<boolean>(false);
  const [openACLamination, setOpenACLamination] = useState<boolean>(false);
  const [laminationOptions, setLaminationOptions] = useState<any[]>([]);

  const { clientListing } = useAppSelector((state: any) => state.clientReducer)
  const { paperTypeList, laminationList } = useAppSelector((state: any) => state.masterDataReducer)

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleOnChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setFormData({
      ...formData,
      [name]: value ? value : null
    })
  }

  useEffect(() => {
    setClientLoading(false);
    setClientOptions(clientListing?.items ? clientListing?.items : []);
  }, [clientListing]);

  useEffect(() => {
    if (!openACClient) {
      setClientOptions([]);
    } else {
      dispatch(getClientList({ pageSize: 1000, page: 0 }));
    }
    setClientLoading(openACClient);
  }, [openACClient]);

  useEffect(() => {
    setPaperTypeLoading(false);
    setPaperTypeOptions(paperTypeList?.items ? paperTypeList?.items : []);
  }, [paperTypeList]);

  useEffect(() => {
    if (!openACpaperType) {
      setPaperTypeOptions([]);
    } else {
      dispatch(getPaperTypeList({ pageSize: 1000, page: 0 }));
    }
    setPaperTypeLoading(openACpaperType);
  }, [openACpaperType]);
  
  useEffect(() => {
    setLaminationLoading(false);
    setLaminationOptions(laminationList?.items ? laminationList?.items : []);
  }, [laminationList]);

  useEffect(() => {
    if (!openACLamination) {
      setLaminationOptions([]);
    } else {
      dispatch(getLaminationList({ pageSize: 1000, page: 0 }));
    }
    setLaminationLoading(openACLamination);
  }, [openACLamination]);

  const onSave = (event: any) => {
    event.preventDefault()
    let _request: any = {
      client_id: formData?.client?.id || '',
      paper_type_id: formData?.paper_type?.id || '',
      lamination_id: formData?.lamination?.id || '',
      client_name: formData?.client_name || '',
      job_width: formData?.job_width || '',
      job_height: formData?.job_height || '',
      quantity: formData?.quantity || '',
      mobile: formData?.mobile || '',
      other_details: formData?.other_details || '',
    }

    let allError = Validations.validateInquiryForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      setLoading(true);
      if (formData?.id) {
        const id = formData?.id
        dispatch(updateInquiryList({ _request, id })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 200) {
            onClose();
          }
        })
      } else {
        dispatch(postInquiryData({ _request })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 201) {
            onClose();
          }
        })
      }
    } else {
      setFormError(allError)
    }
  }

  const dialogProps = {
    loading,
    open,
    onClose,
    onSave,
    title: formData?.id ? `Edit Inquiry` : `Add Inquiry`,
    size: 'md',
    saveTbtText: 'Save'
  }

  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            {/* dropdown */}
            <InputLabel>Client</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACClient}
              onOpen={() => {
                setOpenACClient(true);
              }}
              onClose={() => {
                setOpenACClient(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("client")}
              value={formData?.client ? formData?.client : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={clientOptions}
              loading={clientLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError.client_id ? true : false}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {clientLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {formError && formError.client_id && (
              <FormHelperText error id='password-error'>
                {formError.client_id}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* dropdown */}
            <InputLabel>Paper Type</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACpaperType}
              onOpen={() => {
                setOpenACPaperType(true);
              }}
              onClose={() => {
                setOpenACPaperType(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("paper_type")}
              value={formData?.paper_type ? formData?.paper_type : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={paperTypeOptions}
              loading={paperTypeLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError?.paper_type_id ? true : false}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {paperTypeLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}/>
              {formError && formError.paper_type_id && (
                <FormHelperText error id='password-error'>
                  {formError.paper_type_id}
                </FormHelperText>
              )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* dropdown */}
            <InputLabel>Lamination</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACLamination}
              onOpen={() => {
                setOpenACLamination(true);
              }}
              onClose={() => {
                setOpenACLamination(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("lamination")}
              value={formData?.lamination ? formData?.lamination : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={laminationOptions}
              loading={laminationLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError.lamination_id ? true : false}
                  helperText={formError && formError.lamination_id}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {laminationLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}/>
              {/* {formError && formError.lamination_id && (
                <FormHelperText error id='password-error'>
                  {formError.lamination_id}
                </FormHelperText>
              )} */}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {/* TextField */}
            <InputLabel>Client Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Client Name'
              name='client_name'
              onChange={handleChange}
              value={formData?.client_name || ''}
              error={formError && formError?.client_name}
              helperText={formError && formError?.client_name ? formError.client_name : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Job Width</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='job_width'
              name='job_width'
              onChange={handleChange}
              value={formData?.job_width || ''}
              error={formError && formError?.job_width}
              helperText={formError && formError?.job_width ? formError.job_width : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Job Height</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='job_height'
              name='job_height'
              onChange={handleChange}
              value={formData?.job_height || ''}
              error={formError && formError?.job_height}
              helperText={formError && formError?.job_height ? formError.job_height : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Quantity</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='quantity'
              name='quantity'
              onChange={handleChange}
              value={formData?.quantity || ''}
              error={formError && formError?.quantity}
              helperText={formError && formError?.quantity ? formError.quantity : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Mobile</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Mobile'
              name='mobile'
              onChange={handleChange}
              value={formData?.mobile || ''}
              error={formError && formError?.mobile}
              helperText={formError && formError?.mobile ? formError.mobile : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Other Details</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='other_details'
              name='other_details'
              onChange={handleChange}
              value={formData?.other_details || ''}
              error={formError && formError?.other_details}
              helperText={formError && formError?.other_details ? formError.other_details : ''}
            />
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  )
}

export default Index
