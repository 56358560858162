import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import EyeOutline from 'mdi-material-ui/EyeOutline'
import React from 'react'
import ScreenLayout from '@core/components/screen-layout'
import { LoadingButton } from '@mui/lab'
import LoginIllustrator from 'icons/loginillustration'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store/store'
import { login } from 'store/Thunk/authThunk'
import LoginBanner from 'asset/login_banner.jpg'

interface FormValidation {
  password?: string
  email?: string
}

interface State {
  email: string
  password: string
  showPassword: boolean
}

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary
  }
}))

const Login = () => {
  const navigate = useNavigate()
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    showPassword: false
  })
  const [formError, setFormError] = React.useState<FormValidation>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const dispatch: any = useAppDispatch();

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    })
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleSubmit = (event: any) => {
    setLoading(true)
    event.preventDefault()
    let _request: any = {
      email: values.email.trim(),
      password: values.password.trim()
    }

    dispatch(login({_request, navigate})).then((res: any)=> {
      setLoading(false)
    })
  }

  return (
    <ScreenLayout>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ backgroundColor: '#EFF6FE', padding: 10 }}>
            {/* <LoginIllustrator /> */}
            {/* <img src="login_banner" /> */}
            <Box component={"img"} src={LoginBanner} sx={{height: '100%', width: '100%', borderRadius: '15px'}} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ p: theme => `${theme.spacing(13, 7, 6.5)} !important` }}>
            <Box
              className='sync-align-justify-center'
              sx={{
                mb: 8
              }}
            ></Box>
            <Box sx={{ mb: 6 }}>
              <Typography variant='h5' sx={{ mb: 1.5, fontWeight: 600, letterSpacing: '0.18px' }}>
                {`Welcome to Akshar Graphics and Printers! 👋🏻`}
              </Typography>
              <Typography variant='body2'>Sign in and monitor orders, products, staff and customers</Typography>
            </Box>
            <form noValidate autoComplete='off' onSubmit={handleSubmit}>
              <TextField
                fullWidth
                id='email'
                sx={{ mb: 4 }}
                placeholder='Email'
                label='Email'
                onChange={handleChange('email')}
                error={formError && formError.email ? true : false}
                helperText={formError && formError.email}
              />

              <FormControl fullWidth>
                <InputLabel htmlFor='auth-login-password'>Password</InputLabel>
                <OutlinedInput
                  label='Password'
                  value={values.password}
                  id='auth-login-password'
                  onChange={handleChange('password')}
                  type={values.showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  error={formError && formError.password ? true : false}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-label='toggle password visibility'
                      >
                        {values.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formError && formError.password && (
                  <FormHelperText error id='password-error'>
                    {formError.password}
                  </FormHelperText>
                )}
              </FormControl>
              <Box
                className='sync-align-center'
                sx={{
                  mb: 4,
                  flexWrap: 'wrap',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  label='Remember Me'
                  control={<Checkbox />}
                  sx={{ '& .MuiFormControlLabel-label': { color: 'text.primary' } }}
                />
                {/* <Link onClick={() => navigate('/forgot-password')}>
                  <Typography component={Link} variant='body2' sx={{ color: 'primary.main' }}>
                    Forgot Password?
                  </Typography>
                </Link> */}
              </Box>

              <LoadingButton
                fullWidth
                size='large'
                type='submit'
                loading={loading}
                variant='contained'
                disabled={loading}
                sx={{ mb: 7 }}
              >
                Login
              </LoadingButton>
              {/* <Box
                className='sync-align-justify-center'
                sx={{
                  flexWrap: 'wrap'
                }}
              >
                <Typography sx={{ mr: 2, color: 'text.secondary' }}>New here?</Typography>
                <Typography>
                  <Link onClick={() => navigate('/register')}>
                    <Typography component={Link} sx={{ color: 'primary.main' }}>
                      Create an account
                    </Typography>
                  </Link>
                </Typography>
              </Box> */}
            </form>
          </Box>
        </Grid>
      </Grid>
    </ScreenLayout>
  )
}

export default Login
