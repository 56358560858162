import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { setClientDropdownList, setClientList, setClientLoginReport } from "store/Slice/clientSlice";
import { notificationSuccess } from "store/Slice/notificationSlice";

export const getClientList = createAsyncThunk(
  "getClientList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const getClientDrodownList = createAsyncThunk(
  "getClientDrodownList",
  async ({ }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client?page=1&limit=1000`;
      const response: any = await API.get(
        url,
      );
      dispatch(setClientDropdownList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postClientList = createAsyncThunk(
  "postClientList",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client`;
      const response: any = await API.post(
        url,
        _request
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateClientList = createAsyncThunk(
  "updateClientList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteClientData = createAsyncThunk(
  "deleteClientData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const getClientLoginReport = createAsyncThunk(
  "getClientLoginReport",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client_login_report?page=${page + 1}&limit=${pageSize}` + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setClientLoginReport(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);
