import { SortType } from "@core/utils/types"
import { Box, Tooltip, Typography } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getSummaryOutstandingList } from "store/Thunk/orderManagementThunk"
import { useAppDispatch, useAppSelector } from "store/store"
import AgGridSelectTable from "views/table/AgGridSelectTable"
import TableServerSide from "views/table/TableServerSide"


function Index({handleClientIdChange, setValue, handleRowClick}: any) {
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([]) // set data from the json object
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const dispatch = useAppDispatch();

  const { outstandingSummaryListing } = useAppSelector((state: any) => state.orderManagementReducer);
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.order_management?.outstanding?.includes("export");

  useEffect(() => {
    if (outstandingSummaryListing && outstandingSummaryListing?.items) {
      setRows(outstandingSummaryListing?.items);
      setTotal(outstandingSummaryListing?.meta?.total);
    }
  }, [outstandingSummaryListing]);

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      width: "100px",
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      width: "180px",
      field: "name",
      headerName: "Name",
      filter: 'agTextColumnFilter',
    },
    {
      width: "180px",
      field: "phone",
      headerName: "Mobile",
      filter: 'agTextColumnFilter',
    },
    {
      width: "180px",
      field: "home_address",
      headerName: "Address",
      filter: 'agTextColumnFilter',
    },
    {
      width: "220px",
      field: "email",
      headerName: "Email",
      filter: 'agTextColumnFilter',
    },
    {
      width: "120px",
      field: 'debit',
      headerName: 'Debit',
      cellStyle: { textAlign: "right" },
      floatingFilter: false,
      sortable: false,
      valueFormatter: (p: any) => {
        const debit = p?.data?.Ledger?.length ? p?.data?.Ledger[p?.data?.Ledger?.length - 1].debit?.toString() : "0"
        return debit
      }
    },
    {
      width: "120px",
      field: 'credit',
      headerName: 'Credit',
      floatingFilter: false,
      cellStyle: { textAlign: "right" },
      sortable: false,
      valueFormatter: (p: any) => {
        const credit = p?.data?.Ledger?.length ? p?.data?.Ledger[p?.data?.Ledger?.length - 1].credit?.toString() : "0"
        return credit
      }
    }
  ]);

  // const columns: GridColDef[] = [
  //   {
  //     // flex: 0.1,
  //     maxWidth: 90,
  //     field: 'id',
  //     headerName: 'Id',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params
  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {params.row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     // flex: 0.175,
  //     // minWidth: 120,
  //     flex: 0.1,
  //     headerName: 'Name',
  //     field: 'name',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.name}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.175,
  //     // minWidth: 110,
  //     flex: 0.15,
  //     field: 'phone',
  //     headerName: 'Mobile',
  //     type: "number",
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //         {params.row.phone}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.125,
  //     // flex: 0.15,
  //     field: 'home_address',
  //     minWidth: 200,
  //     headerName: 'Address',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.home_address}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.125,
  //     // flex: 0.15,
  //     field: 'email',
  //     minWidth: 200,
  //     headerName: 'Email',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         <Tooltip title={params.row.email}>
  //           {params.row.email}
  //         </Tooltip>
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.125,
  //     flex: 0.15,
  //     minWidth: 120,
  //     field: 'Category',
  //     headerName: 'Category',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params?.row?.Category || "-"}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.125,
  //     flex: 0.15,
  //     minWidth: 80,
  //     type: "number",
  //     field: 'debit',
  //     headerName: 'Debit',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const debit = params?.row?.Ledger?.length ? params?.row?.Ledger[params?.row?.Ledger?.length - 1].debit : "-"
  //       return (
  //       <Link onClick={() => {
  //         setValue('2');
  //         handleClientIdChange(params?.row);
  //       }} to={""}>
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {debit}
  //       </Typography>
  //       </Link>)
  //     }
  //   },
  //   {
  //     // flex: 0.125,
  //     flex: 0.15,
  //     minWidth: 80,
  //     type: "number",
  //     headerName: 'Credit',
  //     field: 'credit_limit',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const credit = params?.row?.Ledger?.length ? params?.row?.Ledger[params?.row?.Ledger?.length - 1].credit : "-"
  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {credit}
  //         </Typography>
  //       )
  //     }
  //   },

  // ]

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      // setLoading(true)
      return dispatch(getSummaryOutstandingList({ pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : "" })).then((res: any) => {
        // setLoading(false)
        return res.payload.data
      })
    },
    []
  )

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const attr = {
    title: 'Manage Summary',
    // buttonName: 'Add Order',
    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    columns: columnDefs,
    // loading,
    searchValue,
    setSearchValue,
    sortColumn,
    setSortColumn,
    // onButtonClick: handleDialog,
    handleRowClick,
    getRowClassName,
    fetchTableData,
    isShadow: true,
    isExport,
    isWrite: true
  }

  return (
    <Box >
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable  {...attr} />
    </Box>
  )
}

export default Index
