import { Box, Button, Chip, IconButton, Stack, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import TableServerSide from 'views/table/TableServerSide'

// ** Custom Components
import CustomAvatar from '@core/components/mui/avatar'
import CustomChip from '@core/components/mui/chip'
import { ThemeColor } from '@core/layouts/types'
import { getInitials } from '@core/utils/get-initials'
import { SortType, StatusObj } from '@core/utils/types'

import AddUpdate from './add-update'
import ApproveDialog from './approve-dialog'
import DeclineDialog from './decline-dialog'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getAccountEntryList } from 'store/Thunk/accountEntryThunk'
import DeleteDialog from './delete-dialog'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/CheckRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import moment from "moment-timezone";
import ImagePreview from 'views/ImagePreview'
import { getFileExtension } from 'helper/service'
import AgGridSelectTable from 'views/table/AgGridSelectTable'

export default function Index() {
  // ** State
  const [refresh, setRefresh] = useState(true); // Just a toggle. Does not any effect on true/false value
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false)
  const [formData, setFormData] = useState<any>({
    date: new Date()
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDialogApprove, setOpenDialogApprove] = useState(false);
  const [openDialogDecline, setOpenDialogDecline] = useState(false);
  const [isPreview, setImagePreviewPopup] = useState<boolean>(false);
  const [imageToPreview, setImageToPreview] = useState("");
  const [acEntryId, setAcEntryId] = useState("");

  const { acEntryListing } = useAppSelector((state: any) => state.acEntryReducer);
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.AC_Entry?.AC_Entry?.includes("export");

  const dispatch = useAppDispatch();

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      width: "100px",
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      width: "140px",
      headerName: 'Type',
      field: 'payment_type',
      filter: 'agTextColumnFilter',
    },
    {
      width: "140px",
      field: "client_name",
      headerName: "Client Name",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.client?.name || ""
      }
    },
    {
      width: "100px",
      field: 'amount',
      cellStyle: { textAlign: "right" },
      headerName: 'Amount',
      filter: 'agTextColumnFilter',
    },
    {
      width: "120px",
      field: "date",
      headerName: "Date",
      valueFormatter: (p: any) => p.value ? moment(p.value).format('DD/MM/YYYY') : "-",
      filter: "agDateColumnFilter",
    },
    {
      width: "120px",
      field: 'current_balance',
      cellStyle: { textAlign: "right" },
      headerName: 'Current Balance',
      valueGetter: (p: any) => {
        return p.data?.client?.Ledger?.length ? p.data?.client?.Ledger[0]?.balance : "0"
      }
    },
    {
      width: "120px",
      field: 'remarks',
      headerName: 'Remarks',
      filter: 'agTextColumnFilter',
    },
    {
      width: "120px",
      field: 'approved',
      headerName: 'Approved',
      sortable: false,
      autoHeight: true,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        const status = params.data.status

        if (status) {
          return (
            <CustomChip
              size='small'
              skin='light'
              color={status == "approved" ? "success" : "error"}
              label={params.data.status}
              sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
            />
          )
        } else if (user_type != "super_admin" && !rolesPermission?.AC_Entry?.AC_Entry?.includes("write")) {
          return (
            <CustomChip
              size='small'
              skin='light'
              color={"info"}
              label={"Pending"}
              sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
            />
          )
        }  else {
          return (
            <Box sx={{mb: 1}}>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                handleApproveDialog();
                setAcEntryId(params.data)
              }}
                size='small' sx={{
                  width: "34px", height: "36px",
                  borderRadius: "14px", color: "#fff", backgroundColor: "#4caf50", "&:hover": {
                    backgroundColor: "#45a049"
                  }
                }}>
                <CheckIcon />
              </IconButton>
              <IconButton onClick={(e) => {
                e.stopPropagation();
                handleDeclineDialog();
                setAcEntryId(params.data)
              }} sx={{
                ml: "10px",
                width: "34px", height: "36px",
                borderRadius: "14px", color: "#fff", backgroundColor: "#ff5252", "&:hover": {
                  backgroundColor: "#d13a3a"
                }
              }}>
                <ClearIcon />
              </IconButton>
            </Box>
          )
        }

      }
    },
    {
      width: "120px",
      field: 'view',
      headerName: 'View',
      sortable: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        const url = params.data.receipt_img || ""
        if(url){
          return (
            <Button onClick={handleViewClick(url)}>
              View
            </Button>
          )
        }
      }
    },
    {
      width: "120px",
      field: "action",
      headerName: 'Action',
      floatingFilter: false,
      sortable: false,
      cellRenderer: (params: any) => {
        if (user_type != "super_admin" && !rolesPermission?.AC_Entry?.AC_Entry?.includes("write") && !rolesPermission?.AC_Entry?.AC_Entry?.includes("delete")) {
          return (<>-</>)
        }

        return (
          <Box>
            {
              (user_type == "super_admin" || rolesPermission?.AC_Entry?.AC_Entry?.includes("write")) && (
                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setOpenDialog(!openDialog)
                  setFormData(params.data)
                }}>
                  <EditIcon />
                </IconButton>
              )
            }
            {
              (user_type == "super_admin" || rolesPermission?.AC_Entry?.AC_Entry?.includes("delete")) && (
                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setDeleteDialog(!deleteDialog);
                  setDeleteId(params.data.id)
                }}>
                  <DeleteIcon />
                </IconButton>
              )
            }
          </Box>
        )
      }
    }
  ]);

  // const columns: GridColDef[] = [
  //   {
  //     // flex: 0.175,
  //     maxWidth: 80,
  //     field: 'id',
  //     headerName: 'Id',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params

  //       return (
  //         <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.175,
  //     // minWidth: 120,
  //     headerName: 'Type',
  //     field: 'payment_type',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.payment_type}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.175,
  //     // minWidth: 110,
  //     field: 'name',
  //     headerName: 'Client',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params?.row?.client?.name}
  //       </Typography>
  //     )
  //   },
  //   {
  //     // flex: 0.125,
  //     field: 'amount',
  //     minWidth: 100,
  //     headerName: 'Amount',
  //     type: "number",
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.amount}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.125,
  //     field: 'date',
  //     // minWidth: 80,
  //     headerName: 'Date',
  //     valueGetter: (params: any) => {
  //       return params ? moment(params).format('DD/MM/YYYY') : "-";
  //     },
  //     renderCell: (params: GridRenderCellParams) => {
  //       return(
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params?.value}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.125,
  //     field: 'remarks',
  //     // minWidth: 80,
  //     headerName: 'Remarks',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.remarks}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.175,
  //     // minWidth: 140,
  //     field: 'approved',
  //     headerName: 'Approved',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => {
  //       const status = params.row.status

  //       if (status) {
  //         return (
  //           <CustomChip
  //             size='small'
  //             skin='light'
  //             color={status == "approved" ? "success" : "error"}
  //             label={params.row.status}
  //             sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
  //           />
  //         )
  //         // return (
            
  //         //   <Chip
  //         //     size='small'
  //         //     label={status || ""}
  //         //     color={'primary'}
  //         //     sx={{ mr: 0.75, '& .MuiChip-label': { px: 2.5, lineHeight: 1.385, textTransform: 'capitalize' } }}
  //         //   />
  //         // )
  //       } else if (user_id != 1 && !rolesPermission?.AC_Entry?.AC_Entry?.includes("write")) {
  //         return (
  //           <CustomChip
  //             size='small'
  //             skin='light'
  //             color={"info"}
  //             label={"Pending"}
  //             sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
  //           />
  //         )
  //       }  else {
  //         return (
  //           <Box sx={{mt: "-10px"}}>
  //             <IconButton onClick={(e) => {
  //               e.stopPropagation();
  //               handleApproveDialog();
  //               setAcEntryId(params.row)
  //             }}
  //               size='small' sx={{
  //                 width: "34px", height: "36px",
  //                 borderRadius: "14px", color: "#fff", backgroundColor: "#4caf50", "&:hover": {
  //                   backgroundColor: "#45a049"
  //                 }
  //               }}>
  //               <CheckIcon />
  //             </IconButton>
  //             <IconButton onClick={(e) => {
  //               e.stopPropagation();
  //               handleDeclineDialog();
  //               setAcEntryId(params.row)
  //             }} sx={{
  //               ml: "10px",
  //               width: "34px", height: "36px",
  //               borderRadius: "14px", color: "#fff", backgroundColor: "#ff5252", "&:hover": {
  //                 backgroundColor: "#d13a3a"
  //               }
  //             }}>
  //               <ClearIcon />
  //             </IconButton>
  //           </Box>
  //         )
  //       }

  //     }
  //   },
  //   {
  //     flex: 0.175,
  //     // minWidth: 140,
  //     field: 'view',
  //     headerName: 'View',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => {
  //       const url = params.row.receipt_img || ""
  //       if(url){
  //         return (
  //           <Button sx={{mt: "-10px"}} onClick={handleViewClick(url)}>
  //             View
  //           </Button>
  //         )
  //       }
  //       // <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //       //   {params.row.view}
  //       // </Typography>
  //     }
  //   },
  //   {
  //     minWidth: 120,
  //     field: '',
  //     headerName: 'Action',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => {
  //       if (user_id != 1 && !rolesPermission?.AC_Entry?.AC_Entry?.includes("write") && !rolesPermission?.AC_Entry?.AC_Entry?.includes("delete")) {
  //         return (<>-</>)
  //       }

  //       return (
  //         <Box sx={{mt: "-10px"}}>
  //           {
  //             (user_id == 1 || rolesPermission?.AC_Entry?.AC_Entry?.includes("write")) && (
  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setOpenDialog(!openDialog)
  //                 setFormData(params.row)
  //               }}>
  //                 <EditIcon />
  //               </IconButton>
  //             )
  //           }
  //           {
  //             (user_id == 1 || rolesPermission?.AC_Entry?.AC_Entry?.includes("delete")) && (
  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setDeleteDialog(!deleteDialog);
  //                 setDeleteId(params.row.id)
  //               }}>
  //                 <DeleteIcon />
  //               </IconButton>
  //             )
  //           }
  //         </Box>
  //       )
  //     }
  //   }
  // ]

  const handleViewClick = (url: any) => (e: any) => {
    e.stopPropagation()
    if (getFileExtension(url)?.toLowerCase() == "jpg") {
      previewImage(url);
    } else {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  useEffect(() => {
    if (acEntryListing && acEntryListing?.items) {
      setRows(acEntryListing?.items);
      setTotal(acEntryListing?.meta?.total);
    }
  }, [acEntryListing])

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      // setLoading(true)
      return dispatch(getAccountEntryList({  pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : ""})).then((res) => {
        // setLoading(false)
        return res.payload.data
      })
    },
    []
  );

  const handleDialog = () => {
    if (openDialog) {
      setFormData({})
    }
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
    setOpenDialog(!openDialog)
  }

  const handleDeleteDialog = () => {
    setDeleteDialog(false);
    setDeleteId(null);
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
  }

  const handleApproveDialog = () => {
    setOpenDialogApprove(!openDialogApprove);
    setAcEntryId("")
    if(openDialogApprove){
      setRefresh(!refresh);
    }
    // fetchTableData(page, pageSize, sort, sortColumn);
  }

  const handleDeclineDialog = () => {
    setAcEntryId("");
    setOpenDialogDecline(!openDialogDecline);
    if(openDialogDecline){
    setRefresh(!refresh);
    }
  }

  const handleRowClick = (value: any) => {
    setOpenDialog(!openDialog)
    setFormData(value.data)
  }
  const user_type: any = localStorage.getItem("user_type");
  console.log("user_type123 super_admin", user_type);

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };
  
  const attr = {
    title: 'A/C Entry',
    buttonName: user_type == "super_admin" || rolesPermission?.AC_Entry?.AC_Entry?.includes("write") ? 'Add A/C Entry' : "",

    page,
    setPage,
    getRowClassName,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    columns: columnDefs,
    searchValue,
    setSearchValue,
    sortColumn,
    setSortColumn,
    loading,
    onButtonClick: handleDialog,
    fetchTableData,
    handleRowClick,
    isExport,
    isWrite: user_type == "super_admin" || rolesPermission?.AC_Entry?.AC_Entry?.includes("write"),
    refresh
  }

  const previewImage = (image: any) => {
    setImagePreviewPopup(true);
    setImageToPreview(image);
  };

  const closeImagePreview = () => {
    setImagePreviewPopup(false);
    setImageToPreview("");
  };

  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable {...attr} />
      {openDialog && <AddUpdate handleViewClick={handleViewClick} setAcEntryId={setAcEntryId} handleDeclineDialog={handleDeclineDialog} handleApproveDialog={handleApproveDialog} open={openDialog} onClose={handleDialog} formData={formData} setFormData={setFormData} />}
      <DeleteDialog open={deleteDialog} onClose={handleDeleteDialog} deleteId={deleteId} />
      {openDialogApprove && <ApproveDialog setOpenDialog={setOpenDialog} open={openDialogApprove} onClose={handleApproveDialog} acEntryId={acEntryId} />}
      {openDialogDecline && <DeclineDialog setOpenDialog={setOpenDialog} open={openDialogDecline} onClose={handleDeclineDialog} acEntryId={acEntryId} />}
      {isPreview && (
        <ImagePreview
          imageData={imageToPreview}
          open={isPreview}
          onCloseImage={() => {
            closeImagePreview();
          }}
        />
      )}
    </Box>
  )
}
