import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	staffListing: any[]
}

const initialState: InitialState = {
	staffListing: []
};

const staffManagmentSlice = createSlice({
	name: 'staffManagmentSlice',
	initialState: initialState,
	reducers: {
		setStaffList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.staffListing = action.payload
		},
		
	}
});

export const { 
	setStaffList
} =
staffManagmentSlice.actions;

export default staffManagmentSlice.reducer;
