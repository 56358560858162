// ** Icon imports
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DatasetIcon from '@mui/icons-material/Dataset';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GradingIcon from '@mui/icons-material/Grading';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HomeIcon from '@mui/icons-material/Home';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

// ** Type import
import { VerticalNavItemsType } from '@core/layouts/types';
import { useAppSelector } from 'store/store';

const navigation = (): VerticalNavItemsType => {
  // const { rolesPermission } = useAppSelector ((state: any) => state.rolePermissionReducer);
  // rolesPermission?.order_management?.all_order?.includes("write")
  return [
    {
      title: 'Dashboard',
      // subject: '',
      icon: HomeIcon,
      path: '/dashboard'
    },
    // {
    //   title: 'User Management',
    //   icon: PersonIcon
    // },
    {
      title: 'Order Management',
      subject: 'order_management',
      icon: GradingIcon,
      children: [
        {
          title: 'All Order',
          subject: 'all_order',
          path: '/order/all'
        },
        {
          title: 'Cash Memo',
          subject: 'cash_memo',
          path: '/order/memo'
        },
        {
          title: 'Outstanding',
          subject: 'outstanding',
          path: '/order/outstanding'
        },
        {
          title: 'Order History',
          subject: 'history',
          path: '/order/history'
        }
      ]
    },
    {
      title: 'Client Management',
      subject: 'client_management',
      icon: HandshakeIcon,
      children: [
        {
          title: 'All Client',
          subject: 'all_Client',
          path: '/client/all'
        },
        {
          title: 'Client Login Report',
          subject: 'Client_Login_Report',
          path: '/client/report'
        },
      ]
    },
    {
      title: 'Staff Management',
      subject: 'Staff_management',
      icon: Diversity3Icon,
      path: '/staff'
    },
    {
      title: 'Master Data',
      subject: 'Master_Data',
      icon: DatasetIcon ,
      children: [
        {
          title: 'Area',
          subject: 'Area',
          path: '/master/area'
        },
        {
          title: 'Category',
          subject: 'Category',
          path: '/master/category'
        },
        {
          title: 'Client Products',
          subject: 'Client_Products',
          path: '/master/client-product'
        },
        {
          title: 'Products',
          subject: 'Products',
          path: '/master/products'
        },
        {
          title: 'Paper-Type',
          subject: 'Paper_Type',
          path: '/master/paper-type'
        },
        {
          title: 'Lamination',
          subject:'Lamination',
          path: '/master/lamination'
        },
        {
          title: 'Roles & Permission',
          
          path: '/master/roles-permission'
        },
      ]
    },
    {
      title: 'A/C Entry',
      subject: 'AC_Entry',
      icon: AccountBalanceWalletIcon,
      path: '/ac-entry'
    },
    {
      title: 'Inquiry',
      subject: 'Inquiry',
      icon: LiveHelpIcon,
      path: '/inquiry'
    },
    {
      title: 'Contact Us',
      subject: 'Contact_Us',
      icon: PermContactCalendarIcon,
      path: '/contact-us'
    },
  ]
}

export default navigation
