// *** React Imports
import { SyntheticEvent, useEffect, useState } from 'react'

//MUI Imports
import {
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  GridProps,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'

// ** Icon Imports
import Close from 'mdi-material-ui/Close'
import Plus from 'mdi-material-ui/Plus'

import MDDialog from '@core/components/MDDialog'
import Repeater from '@core/components/repeater'
import Validations from 'helper/validations'

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAppDispatch } from 'store/store'
import { postClientList, updateClientList } from 'store/Thunk/clientManagmentThunk'
import axios from 'axios'
import moment from 'moment'

interface Props {
  open?: any
  onClose?: any
  fetchTableData?: any
  formData?: any
  setFormData?: any
  creditLimit?: any, creditLimitHour?: any
}

const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
  paddingRight: 0,
  marginTop: theme.spacing(3),
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .col-title': {
    top: '-1.5rem',
    position: 'absolute'
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.down('lg')]: {
    '& .col-title': {
      top: '0',
      position: 'relative'
    }
  }
}))

const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(5.5),
  '& .repeater-wrapper + .repeater-wrapper': {
    marginTop: theme.spacing(8)
  }
}))

const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

function Index({ open, onClose, formData, setFormData, creditLimit, creditLimitHour }: Props) {
  const [count, setCount] = useState<number>(1)
  // const [formData, setFormData] = useState<any>({})
  const [formError, setFormError] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  // const creditLimit = formData?.credit_limit;
  // const creditLimitHours = formData?.credit_limit_hours;

  const dispatch = useAppDispatch();

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    if (e.target.name == "state") {
      setCities([])
      handledCitiesData(e.target.value)
    }
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {

    var config = {
      method: 'post',
      url: 'https://countriesnow.space/api/v0.1/countries/states',
      data: {
        country: "India"
      },
    };

    axios(config).then(function (response: any) {
      setStates(response.data.data.states)
    })
    .catch(function (error: any) {
      console.log(error);
    });
    
    if(formData?.state){
      handledCitiesData(formData?.state);
    }
  },[])


  const handledCitiesData = (state: any) => {
    var config = {
      method: 'post',
      url: 'https://countriesnow.space/api/v0.1/countries/state/cities',
      data: {
        country: "India",
        state: state
      },

    };

    axios(config).then(function (response: any) {
      setCities(response.data.data)
    })
      .catch(function (error: any) {
        console.log(error);
      });
  }

  // ** Deletes form
  const deleteForm = (e: SyntheticEvent) => {
    e.preventDefault()

    // @ts-ignore
    e.target.closest('.repeater-wrapper').remove()
  }

  const onSave = (event: any) => {
    setLoading(true);
    event.preventDefault()
    let _request: any = {
      username: formData?.username || '',
      password: formData?.password || '',
      name: formData?.name || '',
      client_category: formData?.client_category || '',
      file_code: formData?.file_code || '',
      client_code: formData?.client_code || '',
      email: formData?.email || '',
      home_address: formData?.home_address || '',
      tin_no: formData?.tin_no || '',
      gst_no: formData?.gst_no || '',
      city: formData?.city || '',
      state: formData?.state || '',
      phone: formData?.phone || '',
      remarks: formData?.remarks || '',
      credit_limit: formData?.credit_limit || '',
      email_reminder: formData?.email_reminder || false,
      sms_reminder: formData?.sms_reminder || false,
      is_credit_limit: formData?.is_credit_limit || false,
      credit_limit_hour: formData?.credit_limit_hour || "",
      ...((creditLimitHour != formData?.credit_limit_hour || creditLimit != formData?.credit_limit) && { credit_hour_timestamp: moment().unix().toString() })
    }

    let allError = Validations.validateClientForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      setLoading(true);
      if (formData?.id) {
        const id = formData?.id
        dispatch(updateClientList({ _request, id })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 200) {
            onClose();
          }
        })
      } else {
        dispatch(postClientList({ _request })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 201) {
            onClose();
          }
        })
      }
    } else {
      setLoading(false);
      setFormError(allError)
    }
  }

  const dialogProps = {
    open,
    onClose,
    loading,
    onSave,
    title: formData?.id ? `Edit Client` : `Add Client`,
    size: 'md',
    saveTbtText: 'Save'
  }

  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>User Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='User Name'
              name='username'
              onChange={handleChange}
              value={formData?.username || ''}
              error={formError && formError?.username}
              helperText={formError && formError?.username ? formError.username : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>

            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <TextField
              fullWidth
              size="small"
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              name='password'
              onChange={handleChange}
              value={formData?.password || ''}
              error={formError && formError?.password}
              helperText={formError && formError?.password ? formError.password : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {/* </FormControl> */}
          </Grid>
          {/* // mr, ms dropdown and TextField */}
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Name'
              name='name'
              onChange={handleChange}
              value={formData?.name || ''}
              error={formError && formError?.name}
              helperText={formError && formError?.name ? formError.name : ''}
            />
          </Grid>
          {/* ------------dropdown ------------*/}
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client Category</InputLabel>
            <Select
              fullWidth
              size='small'
              placeholder='Client Category'
              name='client_category'
              onChange={handleChange}
              value={formData?.client_category || ''}
              error={formError && formError?.client_category}>
              <MenuItem value="category_a">A (Cash Bulk)</MenuItem>
              <MenuItem value="category_b">B (GST+ Courier)</MenuItem>
              <MenuItem value="category_c">C (CASH + Courier)</MenuItem>
              <MenuItem value="category_d">D (CASH)</MenuItem>
              <MenuItem value="category_e">E (GST)</MenuItem>
              <MenuItem value="category_g">G (General Cash)</MenuItem>
              {/* <MenuItem value="categoty_1">categoty 1</MenuItem>
              <MenuItem value="categoty_2">categoty 2</MenuItem>
              <MenuItem value="categoty_3">categoty 3</MenuItem> */}
            </Select>
            {formError?.client_category && <FormHelperText error>{formError?.client_category}</FormHelperText>}
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>File Code</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='File Code'
              name='file_code'
              onChange={handleChange}
              value={formData?.file_code || ''}
              error={formError && formError?.file_code}
              helperText={formError && formError?.file_code ? formError.file_code : ''}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client Code</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Client Code'
              name='client_code'
              onChange={handleChange}
              value={formData?.client_code || ''}
              error={formError && formError?.client_code}
              helperText={formError && formError?.client_code ? formError.client_code : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Email</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Email'
              name='email'
              onChange={handleChange}
              value={formData?.email || ''}
              error={formError && formError?.email}
              helperText={formError && formError?.email ? formError.email : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Home Address</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Home Address'
              name='home_address'
              onChange={handleChange}
              value={formData?.home_address || ''}
              error={formError && formError?.home_address}
              helperText={formError && formError?.home_address ? formError.home_address : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Tin No</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Tin No'
              name='tin_no'
              onChange={handleChange}
              value={formData?.tin_no || ''}
              error={formError && formError?.tin_no}
              helperText={formError && formError?.tin_no ? formError.tin_no : ''}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>GST No</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='GST No'
              name='gst_no'
              onChange={handleChange}
              value={formData?.gst_no || ''}
              error={formError && formError?.gst_no}
              helperText={formError && formError?.gst_no ? formError.gst_no : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>State</InputLabel>
            <Select
              fullWidth
              size='small'
              placeholder='Credit Limit'
              name='state'
              onChange={handleChange}
              value={formData?.state || ''}
              error={formError?.state}
            >
              {states?.map((item: any) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>

              ))}
              {/* <MenuItem value="state_2">Maharastra</MenuItem>
              <MenuItem value="state_3">Madhya Pradesh</MenuItem> */}

            </Select>
            {formError?.state && <FormHelperText error>{formError?.state}</FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>City</InputLabel>
            <Select
              fullWidth
              size='small'
              placeholder='Credit Limit'
              name='city'
              onChange={handleChange}
              value={formData?.city || ''}
              error={formError?.city}>
              {cities?.map((item: any) => (
                <MenuItem value={item}>{item}</MenuItem>

              ))}
              {/* <MenuItem value="city_1">Ahmedabad</MenuItem>
              <MenuItem value="city_2">Baroda</MenuItem>
              <MenuItem value="city_3">Surat</MenuItem> */}
            </Select>
            {formError?.city && <FormHelperText error>{formError?.city}</FormHelperText>}

          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Phone</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Phone'
              name='phone'
              onChange={handleChange}
              value={formData?.phone || ''}
              error={formError && formError?.phone}
              helperText={formError && formError?.phone ? formError.phone : ''}
            />
          </Grid>
          {/* phone remarks credit_limit */}


          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Remarks</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Remarks'
              name='remarks'
              onChange={handleChange}
              value={formData?.remarks || ''}
              // error={formError && formError?.remarks}
              // helperText={formError && formError?.remarks ? formError.remarks : ''}
              multiline
              maxRows={4}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Credit Limit</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Credit Limit'
              name='credit_limit'
              disabled={formData?.is_credit_limit || false}
              onChange={handleChange}
              value={formData?.credit_limit || ''}
              error={formError && formError?.gst_no}
              helperText={formError && formError?.gst_no ? formError.gst_no : ''}
              sx={{
                '& .MuiOutlinedInput-root.Mui-disabled': {
                  backgroundColor: '#e9e9e9',  // Set your desired background color
                  borderRadius: "8px"
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Credit Limit Hour</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Credit Limit Hour'
              name='credit_limit_hour'
              onChange={handleChange}
              disabled={formData?.is_credit_limit || false}
              value={formData?.credit_limit_hour || ''}
              error={formError && formError?.credit_limit_hour}
              helperText={formError && formError?.credit_limit_hour ? formError.credit_limit_hour : ''}
              sx={{
                '& .MuiOutlinedInput-root.Mui-disabled': {
                  backgroundColor: '#e9e9e9',  // Set your desired background color
                  borderRadius: "8px"
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}><Checkbox
              checked={formData?.is_credit_limit}
              onChange={(e) => 
                setFormData({ 
                  ...formData,
                  credit_limit: "",
                  credit_limit_hour: "", 
                  is_credit_limit: e.target.checked 
                })
              } />
              <InputLabel>Enable Unlimited Credit limit for Client</InputLabel>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}><Checkbox
              checked={formData?.email_reminder}
              onChange={(e) => setFormData({ ...formData, email_reminder: e.target.checked })
              } />
              <InputLabel>Email Reminder</InputLabel>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}><Checkbox
              checked={formData?.sms_reminder}
              onChange={(e) => setFormData({ ...formData, sms_reminder: e.target.checked })
              } />
              <InputLabel>SMS Reminder</InputLabel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  )
}

export default Index
