// ** React Import
import { ReactElement, useEffect, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import { useAppSelector } from 'store/store'
import { CardHeader } from '@mui/material'


const ClientTable = () => {
  const [rows, setRows] = useState<any[]>([])
  
  const columns: GridColDef[] = [
    {
      flex: 0.25,
      field: 'index',
      minWidth: 200,
      headerName: '#',
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
              {row.index}
            </Typography>
          </Box>
        )
      }
    },
    {
      flex: 0.25,
      field: 'name',
      minWidth: 200,
      headerName: 'Name',
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
              {row.name}
            </Typography>
          </Box>
        )
      }
    },
    {
      flex: 0.3,
      minWidth: 250,
      field: 'email',
      headerName: 'Email',
      sortable: false,
      renderCell: ({ row }: any) => <Typography variant='body2'>{row.email}</Typography>
    },
    {
      flex: 0.3,
      field: 'balance',
      minWidth: 200,
      headerName: 'Balance',
      sortable: false,
      renderCell: (params: any) => {
        return <Typography variant='body2'>{params.row.balance}</Typography>
      }
    },
    {
      flex: 0.25,
      field: 'phone',
      minWidth: 200,
      headerName: 'Phone No.',
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
              {row.phone}
            </Typography>
          </Box>
        )
      }
    },
    {
      flex: 0.3,
      minWidth: 250,
      field: 'home_address',
      headerName: 'Address',
      sortable: false,
      renderCell: ({ row }: any) => <Typography variant='body2'>{row.home_address}</Typography>
    }
  ]
  const { top10LowestBalanceClient } = useAppSelector((state: any) => state.dashboardReducer);

  useEffect(() => {
    if (top10LowestBalanceClient?.length) {
      const data = top10LowestBalanceClient?.map((item: any, index: any) => ({
        "index": index,
        "id": item.id,
        "balance": item.balance,
        "name": item.name,
        "email": item.email,
        "home_address": item.home_address,
        "phone": item.phone, 
      }));
      
      setRows(data);
    }

  }, [top10LowestBalanceClient])

  return (
    <Card>
      <CardHeader
        title='Top 10 Lowest Balane Client'
      />
      <DataGrid autoHeight hideFooter rows={rows} columns={columns} disableColumnFilter disableRowSelectionOnClick />
    </Card>
  )
}

export default ClientTable
