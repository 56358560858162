// eslint-disable-next-line
export default {
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: 8
      }
    }
  }
}
