// ** MUI Imports
import Box from '@mui/material/Box'

// ** Type Import
import { Settings } from '@core/context/settingsContext'

// ** Components
import NotificationDropdown from '@core/layouts/components/shared-components/NotificationDropdown'
import UserDropdown from '@core/layouts/components/shared-components/UserDropdown'
import Autocomplete from 'layouts/components/Autocomplete'

interface Props {
  hidden: boolean
  settings: Settings
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete hidden={hidden} settings={settings} />
      {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
      {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}
      {/* <NotificationDropdown settings={settings} /> */}
      <UserDropdown settings={settings} />
    </Box>
  )
}

export default AppBarContent
