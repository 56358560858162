// *** React Imports
import React, { SyntheticEvent, useEffect, useState } from 'react'

//MUI Imports
import {
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  Divider,
  Grid,
  GridProps,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  Autocomplete,
  CircularProgress
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'

// ** Icon Imports
import Close from 'mdi-material-ui/Close'
import Plus from 'mdi-material-ui/Plus'

import MDDialog from '@core/components/MDDialog'
import Repeater from '@core/components/repeater'
import Validations from 'helper/validations'
import CustomDateInput from 'views/customeDateInput/CustomDateInput'
import { useAppDispatch, useAppSelector } from 'store/store'
import { postCashMemoData, updateCashMemoList } from 'store/Thunk/orderManagementThunk'
import { getClientDrodownList, getClientList } from 'store/Thunk/clientManagmentThunk'
import { getProductDropdownList, getProductList } from 'store/Thunk/masterDataThunk'
import { isoStringNextDay, isoStringPrevDay, removePlus } from 'helper/service'
import config from 'configs/config'
import { BorderRadius } from 'mdi-material-ui'

interface Props {
  open?: any
  onClose?: any
  formData?: any
  setFormData?: any
}

const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
  paddingRight: 0,
  marginTop: theme.spacing(3),
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .col-title': {
    top: '-1.5rem',
    position: 'absolute'
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.down('lg')]: {
    '& .col-title': {
      top: '0',
      position: 'relative'
    }
  }
}))

const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(5.5),
  '& .repeater-wrapper + .repeater-wrapper': {
    marginTop: theme.spacing(8)
  }
}))

const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

const CalcWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '15px',
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(2)
  }
}))

function Index({ open, onClose, formData, setFormData }: Props) {
  // const [count, setCount] = useState<number>(1)
  // const [formData, setFormData] = useState<any>({})
  const [formError, setFormError] = useState<any>({})
  const [orderDetails, setOrderDetails] = useState<any>([{
    product_id: "",
    description: "",
    quantity: "",
    rate: "0",
    width: "1",
    height: "1",
    gst_percentage: "0",
    gst_amount: "0",
    amount: "0"
  }]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [clientLoading, setClientLoading] = useState<boolean>(false);
  const [openACClient, setOpenACClient] = useState<boolean>(false);
  const [clientOptions, setClientOptions] = useState<any[]>([]);
  const [productOptions, setProductOptions] = useState<any[]>([]);

  const { clientListing } = useAppSelector((state: any) => state.clientReducer);
  const { productListing } = useAppSelector((state: any) => state.masterDataReducer)

  useEffect(() => {
    dispatch(getProductList({ pageSize: 1000, page: 0 }));
    if (formData?.CashMemoProduct) {
      setOrderDetails(formData?.CashMemoProduct);
    }
  }, []);

  useEffect(() => {
    setClientLoading(false);
    setClientOptions(clientListing?.items ? clientListing?.items?.filter((item: any) => item?.email !== config.SUPER_ADMIN) : []);
  }, [clientListing]);

  useEffect(() => {
    if (formData?.client) {
      setFormData({...formData, client_name: formData?.client?.name, mobile_no: removePlus(formData?.client?.phone)})
    }

  },[formData.client])

  useEffect(() => {
    setProductOptions(productListing?.items ? productListing?.items : []);
  }, [productListing]);

  useEffect(() => {
    if (!openACClient) {
      setClientOptions([]);
    } else {
      dispatch(getClientList({ pageSize: 1000, page: 0 }));
    }
    setClientLoading(openACClient);
  }, [openACClient]);

  useEffect(() => {
    if (formData?.client) {
      setFormData({ ...formData, products: [...orderDetails], client_name: formData?.client?.name, mobile_no: removePlus(formData?.client?.phone) });
    } else {
      setFormData({ ...formData, products: [...orderDetails]});
    }
  }, [orderDetails])

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleOnChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setFormData({
      ...formData,
      [name]: value ? value : null
    })
  }

  // ** Deletes form
  const deleteForm = (e: SyntheticEvent, i: any) => {
    e.preventDefault();
    const updatedOrderDetails = orderDetails.filter((_: any, index: any) => index !== i);
    setOrderDetails(updatedOrderDetails);

    // @ts-ignore
    // e.target.closest('.repeater-wrapper').remove()
  }

  const calculateRemainingAmount = (orderDetails: any, advance_amount: string) => {
    const remainingAmount = calculateTotalProductAmount(orderDetails) - Number(advance_amount);
    return remainingAmount?.toString() || ""
  }

  const onSave = (event: any) => {
    event.preventDefault();
    const productArr = orderDetails.map((val: any) => ({
      product_id: val?.product_id,
      quantity: Number(val?.quantity),
      width: Number(val?.width),
      height: Number(val?.height),
      rate: Number(val?.rate),
      amount: val?.amount?.toString(),
      gst_percentage: val?.gst_percentage?.toString(),
      gst_amount: val?.gst_amount?.toString(),
      description: val?.description,
    }))
    let _request: any = {
      client_id: formData?.client?.id || '',
      client_name: formData?.client_name || '',
      mobile_no: formData?.mobile_no || '',
      gst_no: formData?.gst_no || '',
      invoice_no: formData?.invoice_no || '',
      remarks: formData?.remarks || '',
      credit_limit: formData?.credit_limit || '',
      cash_memo_date: formData?.cash_memo_date || '',
      advance_amount: formData?.advance_amount?.toString() || '',
      remaining_amount: calculateRemainingAmount(orderDetails, formData?.advance_amount),
      products: productArr || []
    }

    let allError = Validations.validateCashMemoForm(_request)
    if (Object.entries(allError?.products[0]).length === 0) {
      delete allError['products']
    }
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      setLoading(true);
      if (formData?.id) {
        const id = formData?.id
        dispatch(updateCashMemoList({ _request, id })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 200) {
            onClose();
          }
        })
      } else {
        dispatch(postCashMemoData({ _request })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 201) {
            onClose();
          }
        })
      }
    } else {
      setFormError(allError)
    }
  }

  // const handleProductChange = (e: any, i: any) => {
  //   const selectedProduct = e.target.value;
  //   const updatedOrderDetails = [...orderDetails];
  //   updatedOrderDetails[i] = {
  //     ...updatedOrderDetails[i],
  //     [e.target.name]: selectedProduct,
  //   };
  //   setOrderDetails(updatedOrderDetails);
  // };

  const handleProductChange = (value: any, i: any, name: any) => {
    const selectedProduct = value;
    const updatedOrderDetails = [...orderDetails];
    updatedOrderDetails[i] = {
      ...updatedOrderDetails[i],
      [name]: selectedProduct,
    };
    let totalAmount = (Number(updatedOrderDetails[i]["rate"]))*(updatedOrderDetails[i]["quantity"])*(Number(updatedOrderDetails[i]["height"]))*(Number(updatedOrderDetails[i]["width"]))
      console.log("totalAmount ", totalAmount, Number(updatedOrderDetails[i]["rate"]), updatedOrderDetails[i]["quantity"], Number(updatedOrderDetails[i]["height"]), Number(updatedOrderDetails[i]["width"]));
    if (name == "gst_percentage") {
      updatedOrderDetails[i]["gst_amount"] = calculateGSTAmount(updatedOrderDetails[i]["gst_percentage"] || 0,totalAmount || 0);
      updatedOrderDetails[i]["amount"] = calculateTotal(updatedOrderDetails[i]) + calculateGSTAmount(updatedOrderDetails[i]["gst_percentage"] || 0, totalAmount);
      // updatedOrderDetails[i]["remaining_amount"] = calculateTotal(updatedOrderDetails[i]) + calculateGSTAmount(updatedOrderDetails[i]["gst_percentage"] || 0, totalAmount);

    } else {
      if (updatedOrderDetails[i]["gst_percentage"]) {
        updatedOrderDetails[i]["gst_amount"] = calculateGSTAmount(updatedOrderDetails[i]["gst_percentage"] || 0, totalAmount || 0);
        updatedOrderDetails[i]["amount"] = calculateTotal(updatedOrderDetails[i]) + calculateGSTAmount(updatedOrderDetails[i]["gst_percentage"] || 0, totalAmount);
        // updatedOrderDetails[i]["remaining_amount"] = calculateTotal(updatedOrderDetails[i]) + calculateGSTAmount(updatedOrderDetails[i]["gst_percentage"] || 0, totalAmount);
      } else {
        updatedOrderDetails[i]["amount"] = calculateTotal(updatedOrderDetails[i]);
        // updatedOrderDetails[i]["remaining_amount"] = calculateTotal(updatedOrderDetails[i]);

      }
    }
    setOrderDetails(updatedOrderDetails);
  };

  const getPrice = (product: any) => {
    if (product && product?.ClientProduct?.length) {
      return product?.ClientProduct[0]?.price
    } else {
      if (formData?.client?.client_category == "category_a") {
        return product?.price_A
      }
      else if (formData?.client?.client_category == "category_b") {
        return product?.price_B
      }
      else if (formData?.client?.client_category == "category_c") {
        return product?.price_C
      }
      else if (formData?.client?.client_category == "category_d") {
        return product?.price_D
      }
      else if (formData?.client?.client_category == "category_e") {
        return product?.price_E
      }
      else if (formData?.client?.client_category == "category_g") {
        return product?.price_F
      } else {
        return product?.price_A
      }
    }
  }

  const handleOnChangeProduct = (name: string, i: any) => (event: any) => {
    const value = event.target.value;
    const find = productListing?.items?.find((item: any) => item?.id == value)
    
    let updatedProducts = [...orderDetails];
    const updatedProduct = { ...updatedProducts[i] };

    updatedProduct["product_id"] = value;
    updatedProduct["quantity"] = 1;
    updatedProduct["rate"] = getPrice(find);
    updatedProduct["gst_amount"] = calculateGSTAmount(updatedProduct["gst_percentage"] || 0, updatedProduct["rate"]);
    updatedProduct["amount"] = calculateTotal(updatedProduct) + calculateGSTAmount(updatedProduct["gst_percentage"] || 0, updatedProduct["rate"]);

    updatedProducts[i] = updatedProduct;
    setOrderDetails(updatedProducts);
  }

  const calculateTotal = (product: any) => {
    const quantity = Number(product["quantity"]);
    const width = Number(product["width"]);
    const height = Number(product["height"]);
    const rate = Number(product["rate"]);

    let total = quantity * rate;
  
    // if (width !== 0 ) {
      total =  total * width;
    // } 
    // if (height !== 0) {
      total =  total * height;
    // }
    return total;
  };

  const calculateGSTAmount = (percentage: any, total: any) => {
    const amount = (percentage * total)/100;
    return amount;
  };

  const calculateTotalProductAmount = (products: any) => {
    const temp = products.reduce((total: any, product: any) => total + parseFloat(product?.amount || "0"), 0);
    return Math.ceil(temp);
  }

  const dialogProps = {
    open,
    onClose,
    loading,
    onSave,
    title: formData?.id ? `Edit Cash Memo` : `Add Cash Memo`,
    size: 'lg',
    saveTbtText: 'Save'
  }

  return (
    <MDDialog  {...dialogProps} >
      <Box p={4} >
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACClient}
              onOpen={() => {
                setOpenACClient(true);
              }}
              onClose={() => {
                setOpenACClient(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("client")}
              value={formData.client ? formData.client : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={clientOptions}
              loading={clientLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError.client ? true : false}
                  helperText={formError && formError.client}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {clientLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            {formError && formError?.client_id && (<FormHelperText error>{formError?.client_id}</FormHelperText>)}

          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Cash Memo Date</InputLabel>
            {/* <TextField
              fullWidth
              size='small'
              placeholder='Order Date'
              name='cash_memo_date'
              onChange={handleChange}
              value={formData?.cash_memo_date || ''}
              error={formError && formError?.cash_memo_date}
              helperText={formError && formError?.cash_memo_date ? formError.cash_memo_date : ''}
            /> */}
            <CustomDateInput 
              error={formError && formError?.cash_memo_date} 
              helperText={formError && formError?.cash_memo_date} 
              value={formData?.cash_memo_date ? isoStringPrevDay(formData?.cash_memo_date) : ""} 
              onChange={(e: any) => setFormData({ ...formData, cash_memo_date: isoStringPrevDay(e) })} />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Client Name'
              name='client_name'
              onChange={handleChange}
              value={formData?.client_name || ''}
              error={formError && formError?.client_name}
              helperText={formError && formError?.client_name ? formError.client_name : ''}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Mobile No</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Mobile No'
              name='mobile_no'
              onChange={handleChange}
              value={formData?.mobile_no || ''}
              error={formError && formError?.mobile_no}
              helperText={formError && formError?.mobile_no ? formError.mobile_no : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>GST No.</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='GST No.'
              name='gst_no'
              onChange={handleChange}
              value={formData?.gst_no || ''}
              error={formError && formError?.gst_no}
              helperText={formError && formError?.gst_no ? formError.gst_no : ''}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Invoice No.</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Invoice No.'
              name='invoice_no'
              onChange={handleChange}
              value={formData?.invoice_no || ''}
              error={formError && formError?.invoice_no}
              helperText={formError && formError?.invoice_no ? formError.invoice_no : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Remarks</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Remarks'
              name='remarks'
              onChange={handleChange}
              value={formData?.remarks || ''}
              // error={formError && formError?.remarks}
              // helperText={formError && formError?.remarks ? formError.remarks : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Credit Limit</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Credit Limit'
              name='credit_limit'
              onChange={handleChange}
              value={formData?.credit_limit || ''}
              error={formError && formError?.credit_limit}
              helperText={formError && formError?.credit_limit ? formError.credit_limit : ''}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 4.75, display: 'flex', justifyContent: 'flex-end'  }}>
          <Grid item xs={12} sx={{ px: 0 }}>
            <Button
              size='small'
              variant='contained'
              startIcon={<Plus fontSize='small' />}
              onClick={() => {
                // setCount(count + 1)
                setOrderDetails([...orderDetails, {
                  product_id: "",
                  description: "",
                  quantity: "",
                  rate: "0",
                  width: "1",
                  height: "1",
                  gst_percentage: "0",
                  gst_amount: "0",
                  amount: "0"
                }])
              }}
            >
              Add Item
            </Button>
          </Grid>
        </Grid>

        {/* <RepeaterWrapper>
          <Repeater count={orderDetails?.length || 0}>
            {(i: number) => { */}
            {orderDetails.map((val: any, i: any) => {
              // const Tag = i === 0 ? Box : Collapse
              
              return (
                // <Tag key={i} className='repeater-wrapper' {...(i !== 0 ? { in: true } : {})}>
                  <Grid container mt={6}>
                    <RepeatingContent item xs={12}>
                      <Grid container sx={{ py: 4, width: '100%', pr: { lg: 0, xs: 4 } }}>
                        <Grid item lg={3} md={6} xs={12} sx={{ pl: 2, pr: 1, my: { lg: 0, xs: 2 } }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Product
                          </Typography>

                          <Select 
                          fullWidth
                          size='small'
                          placeholder='Select Product'
                          name='product_id'
                          onChange={handleOnChangeProduct("product_id", i)}
                          value={orderDetails[i]?.product_id ? orderDetails[i]?.product_id : {}}
                          error={formError?.products && formError?.products[i]?.product_id}
                          >
                            {
                              productListing?.items?.length > 0 && productListing?.items?.map((val: any) => (
                                <MenuItem value={val?.id}>{val.name}</MenuItem>
                              ))
                            }
                          </Select>
                          {formError?.products && formError?.products[i]?.product_id && (<FormHelperText error>{formError?.products[i]?.product_id}</FormHelperText>)}

                          <TextField
                            rows={2}
                            fullWidth
                            multiline
                            size='small'
                            sx={{ mt: 3.5 }}
                            value={orderDetails[i]?.description}
                            // defaultValue='Product Description'
                            name="description"
                            onChange={(e) => handleProductChange(e.target.value, i, e.target.name)}
                            error={formError?.products && formError?.products[i]?.description ? formError?.products[i]?.description : ""}
                            helperText={formError?.products && formError?.products[i]?.description ? formError?.products[i]?.description : ""}
                          />
                        </Grid>
                        <Grid item lg={1.25} md={6} xs={12} sx={{ px: 1, my: { lg: 0, xs: 2 } }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Quantity
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='Ex. 1'
                            name="quantity"
                            value={orderDetails[i]?.quantity}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleProductChange(e.target.value, i, e.target.name)}
                            error={formError?.products && formError?.products[i]?.quantity ? formError?.products[i]?.quantity : ""}
                            helperText={formError?.products && formError?.products[i]?.quantity ? formError?.products[i]?.quantity : ""}
                          // product_id quantity width length rate gst gst_amount amount
                          />
                        </Grid>
                        <Grid item lg={1.25} md={6} xs={12} sx={{ px: 1, my: { lg: 0, xs: 2 } }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Width
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='Ex. 1'
                            // defaultValue='1'
                            value={orderDetails[i]?.width}
                            name="width"
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleProductChange(e.target.value, i, e.target.name)}
                            error={formError?.products && formError?.products[i]?.width ? formError?.products[i]?.width : ""}
                            helperText={formError?.products && formError?.products[i]?.width ? formError?.products[i]?.width : ""}

                          />
                        </Grid>
                        <Grid item lg={1.25} md={1} xs={12} sx={{ px: 1, my: { lg: 0 }, mt: 2 }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Height
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='Ex. 1'
                            // defaultValue='1'
                            value={orderDetails[i]?.height}
                            name='height'
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleProductChange(e.target.value, i, e.target.name)}
                            error={formError?.products && formError?.products[i]?.height ? formError?.products[i]?.height : ""}
                            helperText={formError?.products && formError?.products[i]?.height ? formError?.products[i]?.height : ""}

                          />
                        </Grid>
                        <Grid item lg={1.25} md={1} xs={12} sx={{ px: 1, my: { lg: 0 }, mt: 2 }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Rate
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='Ex. 1'
                            // defaultValue='1'
                            name='rate'
                            value={orderDetails[i]?.rate}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleProductChange(e.target.value, i, e.target.name)}
                            error={formError?.products && formError?.products[i]?.rate ? formError?.products[i]?.rate : ""}
                            helperText={formError?.products && formError?.products[i]?.rate ? formError?.products[i]?.rate : ""}

                          />
                        </Grid>
                        <Grid item lg={1.25} md={2} xs={12} sx={{ px: 1, my: { lg: 0, xs: 2 } }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            GST %
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='Ex. 1'
                            // defaultValue='1'
                            value={orderDetails[i]?.gst_percentage}
                            name='gst_percentage'
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleProductChange(e.target.value, i, e.target.name)}
                            error={formError?.products && formError?.products[i]?.gst_percentage ? formError?.products[i]?.gst_percentage : ""}
                            helperText={formError?.products && formError?.products[i]?.gst_percentage ? formError?.products[i]?.gst_percentage : ""}

                          />
                        </Grid>

                        <Grid item lg={1.25} md={1} xs={12} sx={{ px: 1, my: { lg: 0 }, mt: 2 }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            GST Amount
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='Ex. 1'
                            disabled
                            // defaultValue='1'
                            value={orderDetails[i]?.gst_amount}
                            name='gst_amount'
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleProductChange(e.target.value, i, e.target.name)}
                            error={formError?.products && formError?.products[i]?.gst_amount ? formError?.products[i]?.gst_amount : ""}
                            helperText={formError?.products && formError?.products[i]?.gst_amount ? formError?.products[i]?.gst_amount : ""}

                          />
                        </Grid>
                        <Grid item lg={1.5} md={1} xs={12} sx={{ px: 1, my: { lg: 0 }, mt: 2 }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Amount
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='1'
                            disabled
                            // defaultValue='1'
                            name='amount'
                            value={orderDetails[i]?.amount}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) => handleProductChange(e.target.value, i, e.target.name)}
                            error={formError?.products && formError?.products[i]?.amount ? formError?.products[i]?.amount : ""}
                            helperText={formError?.products && formError?.products[i]?.amount ? formError?.products[i]?.amount : ""}

                          />
                        </Grid>
                      </Grid>

                      <InvoiceAction>
                        <IconButton size='small' onClick={(e) => deleteForm(e, i)}>
                          <Close fontSize='small' />
                        </IconButton>
                      </InvoiceAction>
                    </RepeatingContent>
                  </Grid>
                // </Tag>
              )
            })}


          <Divider sx={{ mt: 6, mb: 1.5 }} />
          <Grid item xs={12} sm={3} sx={{  display: 'flex', justifyContent: 'flex-end', py: 3, pl: 3, mb: { sm: 0, xs: 4 }, order: { sm: 2, xs: 1 } }}>
              {/* <Box style={{display: 'flex'}}>  */}
              <Box style={{ width: '250px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px'  }}>
            <CalcWrapper>
              <Typography variant='body2'>Total:</Typography>
              <Typography variant='body2' sx={{ fontWeight: 600, color: 'text.primary', lineHeight: '.25px' }}>
              ₹ {calculateTotalProductAmount(orderDetails)} /-
              </Typography>
            </CalcWrapper>
            <Divider  />

            <CalcWrapper sx={{ paddingTop: 1, paddingBottom: 1}}>             
                <Typography variant='body2' style={{marginRight: '10px'}}>Advance:</Typography>
                <TextField
                            size='small'
                            type='number'
                            placeholder='0'
                            // disabled
                            // defaultValue='1'
                            name='advance_amount'
                            value={formData?.advance_amount}
                            style={{width: '120px'}}
                            InputProps={{ inputProps: { min: 0 }, style: { textAlign: 'right' } }}
                            onChange={(e) => setFormData({...formData, advance_amount: e.target.value  })}
                            // error={formError?.products && formError?.products[i]?.amount ? formError?.products[i]?.amount : ""}
                            // helperText={formError?.products && formError?.products[i]?.amount ? formError?.products[i]?.amount : ""}

                          />
              {/* </Box> */}
              </CalcWrapper>
              <Divider />

              <CalcWrapper>
              <Typography variant='body2'>Remaining Amount:</Typography>
              <Typography variant='body2' sx={{ fontWeight: 600, color: 'text.primary', lineHeight: '.25px' }}>
              ₹ {calculateRemainingAmount(orderDetails, formData?.advance_amount) || 0} /-
              </Typography>
            </CalcWrapper>
            </Box>
          </Grid>
      </Box>
    </MDDialog>
  )
}

export default Index
