import { Box, Typography, IconButton, Tooltip } from '@mui/material'
// import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
// import TableServerSide from 'views/table/TableServerSide'

// ** Custom Components
import CustomChip from '@core/components/mui/chip'
import { SortType, StatusObj } from '@core/utils/types'

import AddUpdate from './add-update'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getClientList } from 'store/Thunk/clientManagmentThunk'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from './delete-dialog'
import AgGridSelectTable from 'views/table/AgGridSelectTable'

const statusObj: StatusObj = {
  1: { title: 'current', color: 'primary' },
  2: { title: 'professional', color: 'success' },
  3: { title: 'rejected', color: 'error' },
  4: { title: 'resigned', color: 'warning' },
  5: { title: 'applied', color: 'info' }
}

export default function Demo() {

  const { clientListing } = useAppSelector((state: any) => state.clientReducer);
  // ** State
  const [refresh, setRefresh] = useState(true); // Just a toggle. Does not any effect on true/false value
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState<any>({ client_category: 'category_a' });
  const [creditLimit, setCreditLimit] = useState<any>("");
  const [creditLimitHour, setCreditLimitHour] = useState<any>("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.client_management?.all_Client?.includes("export");
  // const user_type: any = localStorage.getItem("user_type");
  const user_type: any = localStorage.getItem("user_type");
  console.log("user_type123 super_admin", user_type);
  const [columnDefs, setColumnDefs] = useState<any>([
    {
      width: "100px",
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      width: "150px",
      field: 'name',
      headerName: 'Name',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'username',
      headerName: 'User Name',
      filter: 'agTextColumnFilter',
    },
    {
      width: "150px",
      field: 'client_category',
      headerName: 'Category',
      filter: 'agTextColumnFilter',
    },
    {
      width: "150px",
      field: 'city',
      headerName: 'City',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'home_address',
      headerName: 'Home Address',
      filter: 'agTextColumnFilter',
    },
    {
      width: "130px",
      field: 'phone',
      headerName: 'Mobile',
      filter: 'agTextColumnFilter',
    },
    {
      width: "130px",
      field: "action",
      headerName: 'Action',
      autoHeight: true,
      floatingFilter: false,
      sortable: false,
      cellRenderer: (params: any) => {

        if (user_type != "super_admin" && !rolesPermission?.client_management?.all_Client?.includes("write") && !rolesPermission?.client_management?.all_Client?.includes("delete")) {
          return (<>-</>)
        }        
        return (
          <Box>
            {
              (user_type == "super_admin" || rolesPermission?.client_management?.all_Client?.includes("write")) && (
                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setOpenDialog(!openDialog)
                  setFormData(params.data)
                  setCreditLimit(params.data.credit_limit)
                  setCreditLimitHour(params.data.credit_limit_hour)
                }}>
                  <EditIcon />
                </IconButton>

              )
            }
            {
              (user_type == "super_admin" || rolesPermission?.client_management?.all_Client?.includes("delete")) && (

                <IconButton onClick={(e) => {
                  e.stopPropagation()
                  setDeleteDialog(!deleteDialog);
                  setDeleteId(params.data.id)
                }}>
                  <DeleteIcon />
                </IconButton>
              )}
          </Box>
        )
      }
    },
  ]);

  // const columns: GridColDef[] = [
  //   {
  //     flex: 0.05,
  //     // minWidth: 290,
  //     field: 'id',
  //     headerName: 'Id',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const { row } = params

  //       return (
  //         <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //           {row.id}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.1,
  //     // minWidth: 120,
  //     headerName: 'Approved',
  //     field: 'approved',
  //     sortable: false,
  //     renderCell: (params: GridRenderCellParams) => {
  //       const approved = 'Yes';
  //       return (
  //         <CustomChip
  //           size='small'
  //           skin='light'
  //           color={approved == "Yes" ? "primary" : (approved == "Pending" ? "warning" : "error")}
  //           label={approved}
  //           sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
  //         />
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.15,
  //     // minWidth: 110,
  //     field: 'username',
  //     headerName: 'User Name',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
  //         <Tooltip title={params.row.username}>
  //           {params.row.username}
  //         </Tooltip>
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.15,
  //     minWidth: 80,
  //     field: 'name',
  //     headerName: 'Name',
  //     renderCell: (params: GridRenderCellParams) => (
  //       <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //         {params.row.name}
  //       </Typography>
  //     )
  //   },
  //   {
  //     flex: 0.1,
  //     // minWidth: 140,
  //     field: 'client_category',
  //     headerName: 'Category',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const status = statusObj[params.row.client_category]

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params.row.client_category}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.1,
  //     // minWidth: 140,
  //     field: 'city',
  //     headerName: 'City',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const status = statusObj[params.row.city]

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params.row.city}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.2,
  //     // minWidth: 140,
  //     field: 'home_address',
  //     headerName: 'home address',
  //     renderCell: (params: GridRenderCellParams) => {
  //       const status = statusObj[params.row.home_address]

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params.row.home_address}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     flex: 0.15,
  //     // minWidth: 140,
  //     field: 'phone',
  //     headerName: 'Mobile',
  //     type: "number",
  //     renderCell: (params: GridRenderCellParams) => {
  //       const status = statusObj[params.row.phone]

  //       return (
  //         <Typography variant='body2' sx={{ color: 'text.primary' }}>
  //           {params.row.phone}
  //         </Typography>
  //       )
  //     }
  //   },
  //   {
  //     minWidth: 140,
  //     field: '',
  //     sortable: false,
  //     headerName: 'Action',
  //     renderCell: (params: GridRenderCellParams) => {

  //       if (user_type != 1 && !rolesPermission?.client_management?.all_Client?.includes("write") && !rolesPermission?.client_management?.all_Client?.includes("delete")) {
  //         return (<>-</>)
  //       }        
  //       return (
  //         <Box sx={{m: "-10px"}}>
  //           {
  //             (user_type == 1 || rolesPermission?.client_management?.all_Client?.includes("write")) && (
  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setOpenDialog(!openDialog)
  //                 setFormData(params.row)
  //                 setCreditLimit(params.row.credit_limit)
  //                 setCreditLimitHour(params.row.credit_limit_hour)
  //               }}>
  //                 <EditIcon />
  //               </IconButton>

  //             )
  //           }
  //           {
  //             (user_type == 1 || rolesPermission?.client_management?.all_Client?.includes("delete")) && (

  //               <IconButton onClick={(e) => {
  //                 e.stopPropagation()
  //                 setDeleteDialog(!deleteDialog);
  //                 setDeleteId(params.row.id)
  //               }}>
  //                 <DeleteIcon />
  //               </IconButton>
  //             )}
  //         </Box>
  //       )
  //     }
  //   }
  // ]

  useEffect(() => {
    if (clientListing && clientListing?.items) {
      setRows(clientListing?.items);
      setTotal(clientListing?.meta?.total);
    }
  }, [clientListing])

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      // setLoading(true)
      return dispatch(getClientList({ pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : "" })).then((res: any) => {
        // setLoading(false)
        return res.payload.data
      })
    },
    []
  )

  const handleDialog = () => {
    if (openDialog) {
      setFormData({})
    }
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
    setOpenDialog(!openDialog)
  }

  const handleDeleteDialog = () => {
    setDeleteDialog(false);
    setDeleteId(null);
    setRefresh(!refresh);
    // fetchTableData(page, pageSize, sort, sortColumn);
  }

  const handleRowClick = (value: any) => {
    setOpenDialog(!openDialog)
    setFormData(value.data)
  }

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const attr = {
    title: 'Client Register',
    // buttonName: 'Add Client',
    buttonName: user_type == "super_admin" || rolesPermission?.client_management?.all_Client?.includes("write") ? 'Add Client' : "",
    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    columns: columnDefs,
    getRowClassName,
    searchValue,
    setSearchValue,
    sortColumn,
    loading,
    setSortColumn,
    onButtonClick: handleDialog,
    fetchTableData,
    handleRowClick,
    isExport,
    isWrite: user_type == "super_admin" || rolesPermission?.client_management?.all_Client?.includes("write"),
    refresh
  }

  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable  {...attr} />
      {openDialog && <AddUpdate open={openDialog} onClose={handleDialog} formData={formData} setFormData={setFormData} creditLimit={creditLimit} creditLimitHour={creditLimitHour} />}
      <DeleteDialog open={deleteDialog} onClose={handleDeleteDialog} deleteId={deleteId} />
    </Box>
  )
}
