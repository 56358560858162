// *** React Imports
import { SyntheticEvent, useState } from 'react'

//MUI Imports
import {
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  Grid,
  GridProps,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'

// ** Icon Imports
import Close from 'mdi-material-ui/Close'
import Plus from 'mdi-material-ui/Plus'

import MDDialog from '@core/components/MDDialog'
import Repeater from '@core/components/repeater'
import Validations from 'helper/validations'

interface Props {
  open?: any
  onClose?: any
}

const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
  paddingRight: 0,
  marginTop: theme.spacing(3),
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .col-title': {
    top: '-1.5rem',
    position: 'absolute'
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.down('lg')]: {
    '& .col-title': {
      top: '0',
      position: 'relative'
    }
  }
}))

const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(5.5),
  '& .repeater-wrapper + .repeater-wrapper': {
    marginTop: theme.spacing(8)
  }
}))

const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

function Index({ open, onClose }: Props) {
  const [count, setCount] = useState<number>(1)
  const [formData, setFormData] = useState<any>({})
  const [formError, setFormError] = useState<any>({})

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  // ** Deletes form
  const deleteForm = (e: SyntheticEvent) => {
    e.preventDefault()

    // @ts-ignore
    e.target.closest('.repeater-wrapper').remove()
  }

  const onSave = (event: any) => {
    event.preventDefault()
    let _request: any = {
      clientName: formData?.client_name || '',
      remark: formData?.remark || '',
      orderDate: formData?.orderDate || '',
      mobileNo: formData?.mobileNo || '',
      creditLimit: formData?.creditLimit || ''
    }

    let allError = Validations.validateOrderForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
    } else {
      setFormError(allError)
    }
  }

  const dialogProps = {
    open,
    onClose,
    onSave,
    title: `Manage Order`,
    size: 'md',
    saveTbtText: 'Save'
  }

  return (
    <MDDialog {...dialogProps}  >
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Select Client'
              name='client'
              onChange={handleChange}
              value={formData?.client || ''}
              error={formError && formError?.client}
              helperText={formError && formError?.client ? formError.client : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Order Date</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Order Date'
              name='order_date'
              onChange={handleChange}
              value={formData?.order_date || ''}
              error={formError && formError?.order_date}
              helperText={formError && formError?.order_date ? formError.order_date : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Client Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Client Name'
              name='client_name'
              onChange={handleChange}
              value={formData?.client_name || ''}
              error={formError && formError?.client_name}
              helperText={formError && formError?.client_name ? formError.client_name : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Mobile No</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Mobile No'
              name='mobile_no'
              onChange={handleChange}
              value={formData?.mobile_no || ''}
              error={formError && formError?.mobile_no}
              helperText={formError && formError?.mobile_no ? formError.mobile_no : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Remarks</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Remarks'
              name='remarks'
              onChange={handleChange}
              value={formData?.remarks || ''}
              // error={formError && formError?.remarks}
              // helperText={formError && formError?.remarks ? formError.remarks : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Credit Limit</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Credit Limit'
              name='credit_limit'
              onChange={handleChange}
              value={formData?.credit_limit || ''}
              error={formError && formError?.credit_limit}
              helperText={formError && formError?.credit_limit ? formError.credit_limit : ''}
            />
          </Grid>
        </Grid>

        <RepeaterWrapper>
          <Repeater count={count}>
            {(i: number) => {
              const Tag = i === 0 ? Box : Collapse

              return (
                <Tag key={i} className='repeater-wrapper' {...(i !== 0 ? { in: true } : {})}>
                  <Grid container>
                    <RepeatingContent item xs={12}>
                      <Grid container sx={{ py: 4, width: '100%', pr: { lg: 0, xs: 4 } }}>
                        <Grid item lg={6} md={5} xs={12} sx={{ px: 4, my: { lg: 0, xs: 2 } }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Product
                          </Typography>
                          <Select fullWidth size='small' defaultValue='Select Product'>
                            <MenuItem value='Product 1'>Product 1</MenuItem>
                            <MenuItem value='Product 2'>Product 2</MenuItem>
                            <MenuItem value='Product 3'>Product 3</MenuItem>
                            <MenuItem value='Product 4'>Product 4</MenuItem>
                          </Select>
                          <TextField
                            rows={2}
                            fullWidth
                            multiline
                            size='small'
                            sx={{ mt: 3.5 }}
                            defaultValue='Product Description'
                          />
                        </Grid>
                        <Grid item lg={2} md={3} xs={12} sx={{ px: 4, my: { lg: 0, xs: 2 } }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Quantity
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='24'
                            defaultValue='24'
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        </Grid>
                        <Grid item lg={2} md={2} xs={12} sx={{ px: 4, my: { lg: 0, xs: 2 } }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Rate
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='1'
                            defaultValue='1'
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        </Grid>
                        <Grid item lg={2} md={1} xs={12} sx={{ px: 4, my: { lg: 0 }, mt: 2 }}>
                          <Typography
                            variant='subtitle2'
                            className='col-title'
                            sx={{ mb: { md: 2, xs: 0 }, color: 'text.primary' }}
                          >
                            Amount
                          </Typography>
                          <TextField
                            size='small'
                            type='number'
                            placeholder='1'
                            defaultValue='1'
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        </Grid>
                      </Grid>
                      <InvoiceAction>
                        <IconButton size='small' onClick={deleteForm}>
                          <Close fontSize='small' />
                        </IconButton>
                      </InvoiceAction>
                    </RepeatingContent>
                  </Grid>
                </Tag>
              )
            }}
          </Repeater>

          <Grid container sx={{ mt: 4.75 }}>
            <Grid item xs={12} sx={{ px: 0 }}>
              <Button
                size='small'
                variant='contained'
                startIcon={<Plus fontSize='small' />}
                onClick={() => setCount(count + 1)}
              >
                Add Item
              </Button>
            </Grid>
          </Grid>
        </RepeaterWrapper>
      </Box>
    </MDDialog>
  )
}

export default Index
