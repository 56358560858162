// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'

// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical'

// ** Third Party Imports
import { ApexOptions } from 'apexcharts'

// ** Custom Components Imports
import ReactApexcharts from 'react-apexcharts'

// ** Util Import
import { hexToRGBA } from '@core/utils/hex-to-rgba'
import { useAppSelector } from 'store/store'
import { useEffect, useState } from 'react'
import { getLocalDateTime } from 'helper/service'

// ** MUI Imports
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiTabList, { TabListProps } from '@mui/lab/TabList'

// Styled TabList component
const TabList = styled(MuiTabList)<TabListProps>(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .Mui-selected': {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FFF !important"
  },
  '& .MuiTab-root': {
    minHeight: 38,
    minWidth: 110,
    borderRadius: 8,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "#F1F2F4",
    marginLeft: theme.spacing(2)
  }
}))

const OrderOverview = ({tabValue, setTabValue}: any) => {
  // ** Hook
  const theme = useTheme()
  const { reportData } = useAppSelector((state: any) => state.dashboardReducer);

  const [xAxisData, setXAxisData] = useState([]);
  const [series, setSeries] = useState([
    {
      name: 'Order',
      type: 'column',
      data: []
    },
    {
      name: 'Order',
      type: 'line',
      data: []
    }
  ]);
  const [minYAxis, setMinYAxis] = useState(0);
  const [maxYAxis, setMaxYAxis] = useState(0);

  const handleChange = (event: any, newValue: string) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    if (reportData?.monthlyOrderData?.length) {
      // Separate arrays for months and total orders
      const months = reportData?.monthlyOrderData.map((entry: any) => entry.month);
      const orderData = reportData?.monthlyOrderData.map((entry: any) => entry.totalOrder);

      const minValue = Math.min(...orderData);
      const maxValue = Math.max(...orderData);
      setMinYAxis(minValue);
      setMaxYAxis(maxValue);

      setSeries([
        {
          name: 'Order',
          type: 'column',
          data: orderData
        },
        {
          type: 'line',
          name: 'Order',
          data: orderData
        }
      ])

      setXAxisData(months);

    } else if (reportData?.currentMonthWeeklyOrders?.length) {
      const week = reportData?.currentMonthWeeklyOrders.map((entry: any) => getLocalDateTime(entry?.week?.endDate, "DD-MMM"));
      const orderData = reportData?.currentMonthWeeklyOrders.map((entry: any) => entry.totalCount);

      const minValue = Math.min(...orderData);
      const maxValue = Math.max(...orderData);
      setMinYAxis(minValue);
      setMaxYAxis(maxValue || 50);

      setSeries([
        {
          name: 'Order',
          type: 'column',
          data: orderData
        },
        {
          type: 'line',
          name: 'Order',
          data: orderData
        }
      ])

      setXAxisData(week);
    } else if (reportData?.last7DaysOrders?.length) {
      const days = reportData?.last7DaysOrders.map((entry: any) => entry?.date);
      const orderData = reportData?.last7DaysOrders.map((entry: any) => entry.orders);

      const minValue = Math.min(...orderData);
      const maxValue = Math.max(...orderData);
      setMinYAxis(minValue);
      setMaxYAxis(maxValue || 50);

      setSeries([
        {
          name: 'Order',
          type: 'column',
          data: orderData
        },
        {
          type: 'line',
          name: 'Order',
          data: orderData
        }
      ])

      setXAxisData(days);
    }
  }, [reportData]);

  const options: any = {
    chart: {
      offsetY: -9,
      offsetX: -16,
      parentHeightOffset: 0,
      toolbar: { show: false },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: '35%',
        endingShape: 'rounded',
        startingShape: 'rounded',
      }
    },
    markers: {
      size: 3.5,
      strokeWidth: 2,
      fillOpacity: 0,
      strokeOpacity: 1,
      colors: [theme.palette.background.paper],
      strokeColors: hexToRGBA(theme.palette.primary.main, 1)
    },
    stroke: {
      width: [0, 2],
      colors: [theme.palette.background.default, theme.palette.primary.main]
    },
    legend: { show: false },
    grid: { strokeDashArray: 7 },
    dataLabels: { enabled: false },
    colors: [hexToRGBA(theme.palette.background.default, 1)],
    states: {
      hover: {
        filter: { type: 'none' }
      },
      active: {
        filter: { type: 'none' }
      }
    },
    xaxis: {
      categories: xAxisData,
      tickPlacement: 'on',
      labels: { show: true },
      axisTicks: { show: false },
      axisBorder: { show: false }
    },
    yaxis: {
      min: minYAxis,
      max: maxYAxis,
      show: true,
      tickAmount: 5,
      labels: {
        formatter: (value: number) => `${value > 999 ? `${(value / 1000).toFixed(0)}k` : value.toFixed(0)}`
      }
    }
  }

  return (
    <Card>
      <CardHeader
        title='Order Overview'
        action={
          <TabContext value={tabValue}>
            <TabList onChange={handleChange} aria-label='customized tabs example'>
              <Tab value='1' label='Daily' />
              <Tab value='2' label='Weekly' />
              <Tab value='3' label='Monthly' />
            </TabList>
          </TabContext>
        }
      />
      <CardContent
        sx={{
          '& .apexcharts-xcrosshairs.apexcharts-active': { opacity: 0 },
          '& .apexcharts-canvas .apexcharts-yaxis-label': { fontSize: '0.75rem', fill: theme.palette.text.disabled },
          paddingLeft: "20px"
        }}
      >
        <ReactApexcharts type='line' height={208} series={series} options={options} />
      </CardContent>
    </Card>
  )
}

export default OrderOverview
