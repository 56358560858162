import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { setClientList } from "store/Slice/clientSlice";
import { setAreaList, setCategoryList, setClientProductList, setLaminationList, setPaperTypeList, setProductDropdownList, setProductList } from "store/Slice/masterDataSlice";
import { notificationFail, notificationSuccess } from "store/Slice/notificationSlice";

   /*--------------------------------- Product Apis ---------------------------------------------------*/

export const getProductList = createAsyncThunk(
  "getProductList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/product?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setProductList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);
export const getProductDropdownList = createAsyncThunk(
  "getProductList",
  async ({}: any, { dispatch }) => {
    try {
      let url = `/api/v1/product?page=1&limit=10000`;
      const response: any = await API.get(
        url,
      );
      dispatch(setProductDropdownList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postProductData = createAsyncThunk(
  "postProductData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/product`;
      const formData = new FormData();
      // Append any data you want to send in the request body
      formData.append('name', _request?.name);
      formData.append('code', _request?.code);
      formData.append('price_A', _request?.price_A);
      formData.append('price_B', _request?.price_B);
      formData.append('price_C', _request?.price_C);
      formData.append('price_D', _request?.price_D);
      formData.append('price_E', _request?.price_E);
      formData.append('price_F', _request?.price_F);
      formData.append('courier_charges', _request?.courier_charges);
      formData.append('transport_charges', _request?.transport_charges);
      formData.append('discount', _request?.discount);
      formData.append('cgst', _request?.cgst);
      formData.append('sgst', _request?.sgst);
      formData.append('igst', _request?.igst);
      formData.append('sort', _request?.sort);
      formData.append('hsn_code', _request?.hsn_code);
      formData.append('print_copy', _request?.print_copy);
      formData.append('image', _request?.image);
      formData.append('category_id', _request?.category_id);
      formData.append('banner', _request?.banner);
      formData.append('height_width', _request?.height_width);
      if (_request?.image_product) {
        formData.append('image_product', _request?.image_product);
      }
      if (_request?.image_banner) {
        formData.append('image_banner', _request?.image_banner);
      } 
      const response: any = await API.post(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the content type to FormData
          },
        }
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateProductList = createAsyncThunk(
  "updateProductList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/product/${id}`;
      const formData = new FormData();
      // Append any data you want to send in the request body
      formData.append('name', _request?.name);
      formData.append('code', _request?.code);
      formData.append('price_A', _request?.price_A);
      formData.append('price_B', _request?.price_B);
      formData.append('price_C', _request?.price_C);
      formData.append('price_D', _request?.price_D);
      formData.append('price_E', _request?.price_E);
      formData.append('price_F', _request?.price_F);
      formData.append('courier_charges', _request?.courier_charges);
      formData.append('transport_charges', _request?.transport_charges);
      formData.append('discount', _request?.discount);
      formData.append('cgst', _request?.cgst);
      formData.append('sgst', _request?.sgst);
      formData.append('igst', _request?.igst);
      formData.append('sort', _request?.sort);
      formData.append('hsn_code', _request?.hsn_code);
      formData.append('print_copy', _request?.print_copy);
      formData.append('image', _request?.image);
      formData.append('category_id', _request?.category_id);
      formData.append('banner', _request?.banner);
      formData.append('height_width', _request?.height_width);
      if (_request?.image_product) {
        formData.append('image_product', _request?.image_product);
      }
      if (_request?.image_banner) {
        formData.append('image_banner', _request?.image_banner);
      } 
      const response: any = await API.patch(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the content type to FormData
          },
        }
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteProductData = createAsyncThunk(
  "deleteClientData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/product/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

/*--------------------------------- Area Apis ---------------------------------------------------*/

export const getAreaList = createAsyncThunk(
  "getAreaList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/area?page=${page + 1}&limit=${pageSize}` + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setAreaList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postAreaData = createAsyncThunk(
  "postAreaData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/area`;
      const response: any = await API.post(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateAreaList = createAsyncThunk(
  "updateAreaList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/area/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteAreaData = createAsyncThunk(
  "deleteAreaData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/area/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

/*--------------------------------- Category Apis ---------------------------------------------------*/

export const getCategoryList = createAsyncThunk(
  "getCategoryList",
  async ({  pageSize, page, q, sort, column, filterModel  }: any, { dispatch }) => {
    try {
      let url = `/api/v1/category?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setCategoryList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postCategoryData = createAsyncThunk(
  "postCategoryData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/category`;
      const formData = new FormData();
      // Append any data you want to send in the request body
      formData.append('name', _request?.name);
      formData.append('sort', _request?.sort);
      formData.append('category_image', _request?.category_image);
      if (_request?.image) {
        formData.append('image', _request?.image);
      }
      
      const response: any = await API.post(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the content type to FormData
          },
        }
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateCategoryList = createAsyncThunk(
  "updateCategoryList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/category/${id}`;
      const formData = new FormData();
      // Append any data you want to send in the request body
      formData.append('name', _request?.name);
      formData.append('sort', _request?.sort);
      formData.append('category_image', _request?.category_image);
      if (_request?.image) {
        formData.append('image', _request?.image);
      }
      const response: any = await API.patch(
        url,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the content type to FormData
          },
        }
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteCategoryData = createAsyncThunk(
  "deleteCategoryData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/category/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

/*--------------------------------- Client Product Apis ---------------------------------------------------*/

export const getClientProductList = createAsyncThunk(
  "getClientProductList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client_product?page=${page + 1}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setClientProductList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postClientProductData = createAsyncThunk(
  "postClientProductData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client_product`;
      const response: any = await API.post(
        url,
        _request
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateClientProductList = createAsyncThunk(
  "updateClientProductList",
  async ({ _request, id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client_product/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      // dispatch(setClientList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteClientProductData = createAsyncThunk(
  "deleteClientProductData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/client_product/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);


export const getPaperTypeList = createAsyncThunk(
  "getPaperTypeList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/paper-type?page=${page}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setPaperTypeList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const getLaminationList = createAsyncThunk(
  "getLaminationList",
  async ({ pageSize, page, q, sort, column, filterModel }: any, { dispatch }) => {
    try {
      let url = `/api/v1/lamination?page=${page}&limit=${pageSize}`  + (q ? `&search=${q}` : ``) + ((sort && column) ? `&sort=${sort}&column=${column}` : ``) + (filterModel ? `&filterModel=${filterModel}`: ``);
      const response: any = await API.get(
        url,
      );
      dispatch(setLaminationList(response?.data?.data))
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const postPaperTypeData = createAsyncThunk(
  "postPaperTypeData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/paper-type`;
      const response: any = await API.post(
        url,
        _request
      );
      if(response?.status == 201){
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        dispatch(notificationFail(response?.data?.message));
      }
      return response;
    } catch (err: any) {
      dispatch(notificationFail("Something went wrong"));
      return err;
    }
  }
);
export const updatePaperTypeData = createAsyncThunk(
  "postPaperTypeData",
  async ({ _request,id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/paper-type/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      if(response?.status == 200){
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        dispatch(notificationFail(response?.data?.message));
      }
      return response;
    } catch (err: any) {
      dispatch(notificationFail("Something went wrong"));
      return err;
    }
  }
);

export const deletePaperTypeData = createAsyncThunk(
  "deletePaperTypeData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/paper-type/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);
export const postLaminationData = createAsyncThunk(
  "postLaminationData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let url = `/api/v1/lamination`;
      const response: any = await API.post(
        url,
        _request
      );
      if(response?.status == 201){
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        dispatch(notificationFail(response?.data?.message));
      }
      return response;
    } catch (err: any) {
      dispatch(notificationFail("Something went wrong"));
      return err;
    }
  }
);
export const updateLaminationData = createAsyncThunk(
  "updateLaminationData",
  async ({ _request,id }: any, { dispatch }) => {
    try {
      let url = `/api/v1/lamination/${id}`;
      const response: any = await API.patch(
        url,
        _request
      );
      if(response?.status == 200){
        dispatch(notificationSuccess(response?.data?.message));
      } else {
        dispatch(notificationFail(response?.data?.message));
      }
      return response;
    } catch (err: any) {
      dispatch(notificationFail("Something went wrong"));
      return err;
    }
  }
);

export const deleteLaminationData = createAsyncThunk(
  "deleteLaminationData",
  async ({ deleteId }: any, { dispatch }) => {
    try {
      let url = `/api/v1/lamination/${deleteId}`;
      const response: any = await API.delete(
        url
      );
      dispatch(notificationSuccess(response?.data?.message));
      return response;
    } catch (err: any) {
      return err;
    }
  }
);
