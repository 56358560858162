// *** React Imports
import { SyntheticEvent, useState } from 'react'

//MUI Imports
import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  CardContent,
  CardContentProps,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  GridProps,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'

// ** Icon Imports
import Close from 'mdi-material-ui/Close'
import Plus from 'mdi-material-ui/Plus'

import MDDialog from '@core/components/MDDialog'
import Repeater from '@core/components/repeater'
import Validations from 'helper/validations'

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAppDispatch, useAppSelector } from 'store/store'
import { getCategoryList, postProductData, updateProductList } from 'store/Thunk/masterDataThunk'
import React from 'react'
import { Height } from '@mui/icons-material'

interface Props {
  open?: any
  onClose?: any
  formData?: any
  setFormData?: any
}

const RepeatingContent = styled(Grid)<GridProps>(({ theme }) => ({
  paddingRight: 0,
  marginTop: theme.spacing(3),
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .col-title': {
    top: '-1.5rem',
    position: 'absolute'
  },
  '& .MuiInputBase-input': {
    color: theme.palette.text.secondary
  },
  [theme.breakpoints.down('lg')]: {
    '& .col-title': {
      top: '0',
      position: 'relative'
    }
  }
}))

const RepeaterWrapper = styled(CardContent)<CardContentProps>(({ theme }) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(5.5),
  '& .repeater-wrapper + .repeater-wrapper': {
    marginTop: theme.spacing(8)
  }
}))

const InvoiceAction = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 0),
  borderLeft: `1px solid ${theme.palette.divider}`
}))

function Index({ open, onClose, formData, setFormData }: Props) {
  const [count, setCount] = useState<number>(1)
  // const [formData, setFormData] = useState<any>({})
  const [formError, setFormError] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryLoading, setCategoryLoading] = React.useState<boolean>(false);
  const [openACCategory, setOpenACCategory] = React.useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = React.useState<any[]>([]);

  let { categoryListing } = useAppSelector(
    (state: any) => state.masterDataReducer
  );

  const handleChange = (e: any) => {
    // if (e.target.name === "height_width") {
    //   let formDataObj = {
    //     ...formData,
    //     width: e.target?.value?.split("*")[0] || '',
    //     height: e.target?.value?.split("*")[1] || '',
    //   }
    //   setFormData({ ...formDataObj })
    // } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    // }
  }
  const dispatch = useAppDispatch();

  React.useEffect(() => {

    setCategoryLoading(false);
    setCategoryOptions(categoryListing?.items ? categoryListing?.items : []);
  }, [categoryListing]);

  React.useEffect(() => {
    if (!openACCategory) {
      setCategoryOptions([]);
    } else {
      dispatch(getCategoryList({ pageSize: 100, page: 0 }));
    }
    setCategoryLoading(openACCategory);
  }, [openACCategory]);

  const handleOnChange = (name: string) => (event: React.SyntheticEvent<Element, Event>, value: any) => {

    setFormData({
      ...formData,
      [name]: value ? value : null
    })
  }

  const handleFileChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] })
  }

  const onSave = (event: any) => {
    setLoading(true);
    event.preventDefault()
    let _request: any = {
      name: formData?.name || '',
      code: formData?.code || '',
      price_A: formData?.price_A || '',
      price_B: formData?.price_B || '',
      price_C: formData?.price_C || '',
      price_D: formData?.price_D || '',
      price_E: formData?.price_E || '',
      price_F: formData?.price_F || '',
      courier_charges: formData?.courier_charges || '',
      transport_charges: formData?.transport_charges || '',
      discount: formData?.discount || '',
      cgst: formData?.cgst || '',
      sgst: formData?.sgst || '',
      igst: formData?.igst || '',
      sort: formData?.sort || '',
      hsn_code: formData?.hsn_code || '',
      print_copy: formData?.print_copy || '',
      image: formData?.image || '',
      category_id: formData?.category?.id || '',
      banner: formData?.banner || '',
      height_width: formData?.height_width || '',
      image_product: formData?.image_product || null,
      image_banner: formData?.image_banner || null
      // height: formData?.height || '',
    }

    let allError = Validations.validateProductForm(_request)
    if (Object.entries(allError).length === 0 && allError.constructor === Object) {
      setLoading(true);
      if (formData?.id) {
        const id = formData?.id
        dispatch(updateProductList({ _request, id })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 200) {
            onClose();
          }
        })
      } else {
        dispatch(postProductData({ _request })).then((res: any) => {
          setLoading(false);

          if (res.payload.status == 201) {
            onClose();
          }
        })
      }

    } else {
      setLoading(false);
      setFormError(allError)
    }
  }

  const dialogProps = {
    open,
    onClose,
    onSave,
    loading,
    title: formData?.id ?  `Edit Product` : `Add Product`,
    size: 'md',
    saveTbtText: 'Save'
  }

  return (
    <MDDialog {...dialogProps}>
      <Box p={4}>
        <Grid container spacing={7} mb={5}>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Name</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Name'
              name='name'
              onChange={handleChange}
              value={formData?.name || ''}
              error={formError && formError?.name}
              helperText={formError && formError?.name ? formError.name : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Code</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Code'
              name='code'
              onChange={handleChange}
              value={formData?.code || ''}
              error={formError && formError?.code}
              helperText={formError && formError?.code ? formError.code : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Price(A)</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Price(A)'
              name='price_A'
              onChange={handleChange}
              value={formData?.price_A || ''}
              error={formError && formError?.price_A}
              helperText={formError && formError?.price_A ? formError.price_A : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Price(B)</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Price(B)'
              name='price_B'
              onChange={handleChange}
              value={formData?.price_B || ''}
              error={formError && formError?.price_B}
              helperText={formError && formError?.price_B ? formError.price_B : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Price(C)</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Price(C)'
              name='price_C'
              onChange={handleChange}
              value={formData?.price_C || ''}
              error={formError && formError?.price_C}
              helperText={formError && formError?.price_C ? formError.price_C : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Price(D)</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Price(D)'
              name='price_D'
              onChange={handleChange}
              value={formData?.price_D || ''}
              error={formError && formError?.price_D}
              helperText={formError && formError?.price_D ? formError.price_D : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Price(E)</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Price(E)'
              name='price_E'
              onChange={handleChange}
              value={formData?.price_E || ''}
              error={formError && formError?.price_E}
              helperText={formError && formError?.price_E ? formError.price_E : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Price(G)</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Price(G)'
              name='price_G'
              onChange={handleChange}
              value={formData?.price_F || ''}
              error={formError && formError?.price_F}
              helperText={formError && formError?.price_F ? formError.price_F : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Courier Charges</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Courier Charges'
              name='courier_charges'
              onChange={handleChange}
              value={formData?.courier_charges || ''}
              error={formError && formError?.courier_charges}
              helperText={formError && formError?.courier_charges ? formError.courier_charges : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Transport Charges</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Transport Charges'
              name='transport_charges'
              onChange={handleChange}
              value={formData?.transport_charges || ''}
              error={formError && formError?.transport_charges}
              helperText={formError && formError?.transport_charges ? formError.transport_charges : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Discount(%)</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='Discount(%)'
              name='discount'
              onChange={handleChange}
              value={formData?.discount || ''}
              error={formError && formError?.discount}
              helperText={formError && formError?.discount ? formError.discount : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>CGST(%)</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='CGST(%)'
              name='cgst'
              onChange={handleChange}
              value={formData?.cgst || ''}
              error={formError && formError?.cgst}
              helperText={formError && formError?.cgst ? formError.cgst : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>SGST(%)</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='SGST(%)'
              name='sgst'
              onChange={handleChange}
              value={formData?.sgst || ''}
              error={formError && formError?.sgst}
              helperText={formError && formError?.sgst ? formError.sgst : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>IGST(%)</InputLabel>
            <TextField
              fullWidth
              type='number'
              size='small'
              placeholder='IGST(%)'
              name='igst'
              onChange={handleChange}
              value={formData?.igst || ''}
              error={formError && formError?.igst}
              helperText={formError && formError?.igst ? formError.igst : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Sort</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Sort'
              name='sort'
              onChange={handleChange}
              value={formData?.sort || ''}
              error={formError && formError?.sort}
              helperText={formError && formError?.sort ? formError.sort : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>HSN CODE</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='HSN CODE'
              name='hsn_code'
              onChange={handleChange}
              value={formData?.hsn_code || ''}
              error={formError && formError?.hsn_code}
              helperText={formError && formError?.hsn_code ? formError.hsn_code : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Print Copy</InputLabel>
            <TextField
              fullWidth
              size='small'
              placeholder='Print Copy'
              name='print_copy'
              onChange={handleChange}
              value={formData?.print_copy || ''}
              error={formError && formError?.print_copy}
              helperText={formError && formError?.print_copy ? formError.print_copy : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Image(150*150)</InputLabel>
            <TextField
              fullWidth
              type='file'
              size='small'
              placeholder='Image(150*150)'
              name='image_product'
              onChange={handleFileChange}
              // value={formData?.image || ''}
              error={formError && formError?.image}
              helperText={formError && formError?.image ? formError.image : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Banner</InputLabel>
            <TextField
              fullWidth
              type='file'
              size='small'
              placeholder='Banner'
              name='image_banner'
              onChange={handleFileChange}
              // value={formData?.banner || ''}
              error={formError && formError?.banner}
              helperText={formError && formError?.banner ? formError.banner : ''}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Height * Width</InputLabel>
            <Select fullWidth size='small' name="height_width" value={formData?.height_width} onChange={handleChange} error={formError?.height_width} >
              <MenuItem value='yes'>Yes</MenuItem>
              <MenuItem value='no'>No</MenuItem>
            </Select>
            {formError?.height_width && <FormHelperText error>{formError?.height_width}</FormHelperText>}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel>Category</InputLabel>
            <Autocomplete
              id="asynchronous-demo"
              open={openACCategory}
              onOpen={() => {
                setOpenACCategory(true);
              }}
              onClose={() => {
                setOpenACCategory(false);
              }}
              fullWidth
              size='small'
              onChange={handleOnChange("category")}
              value={formData.category ? formData.category : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              options={categoryOptions}
              loading={categoryLoading}
              loadingText={"Loading..."}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formError && formError.category ? true : false}
                  helperText={formError && formError.category}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {categoryLoading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <Typography variant='body1' sx={{ my: 2, color: "rgba(108,108,132,1)" }}>
              Select which Category mapped with their stage and above selected lesson.
            </Typography>
            {formError?.category && <FormHelperText>{formError?.category}</FormHelperText>}
          </Grid>
        </Grid>
      </Box>
    </MDDialog>
  )
}

export default Index
