import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	rolePermissionListing: any[],
	rolesPermission: {}
}

const initialState: InitialState = {
	rolePermissionListing: [],
	rolesPermission: {}
};

const rolePermissionSlice = createSlice({
	name: 'rolePermissionSlice',
	initialState: initialState,
	reducers: {
		setRolePermissionList: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.rolePermissionListing = action.payload
		},
		setRolePermission: (
			state: Draft<InitialState>,
			action: PayloadAction<[]>
		) => {
			state.rolesPermission = action.payload
		},
	}
});

export const { 
	setRolePermissionList,
	setRolePermission
} =
rolePermissionSlice.actions;

export default rolePermissionSlice.reducer;
