import { Box, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import TableServerSide from 'views/table/TableServerSide'

// ** Custom Components
import CustomChip from '@core/components/mui/chip'
import { SortType, StatusObj } from '@core/utils/types'

import AddUpdate from 'views/dialog/order/AddUpdate'
import { useAppDispatch, useAppSelector } from 'store/store'
import { getClientLoginReport } from 'store/Thunk/clientManagmentThunk'
import { getLocalDateTime } from 'helper/service'
import AgGridSelectTable from 'views/table/AgGridSelectTable'

const columns: GridColDef[] = [
  {
    flex: 0.2,
    minWidth: 50,
    field: 'id',
    headerName: 'Id',
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params

      return (   
            <Typography noWrap variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
              {row?.id}
            </Typography>
      )
    }
  },
  {
    flex: 0.3,
    minWidth: 200,
    headerName: 'Client Name',
    field: 'client_name',
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 600 }}>
        {params?.row?.client?.name}
      </Typography>
    )
  },
  {
    flex: 0.2,
    minWidth: 200,
    field: 'phone',
    headerName: 'Phone',
    type: "number",
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant='body2' sx={{ color: 'text.primary' }}>
        {params?.row?.client?.phone}
      </Typography>
    )
  },
  {
    flex: 0.3,
    minWidth: 200,
    field: 'login_time',
    headerName: 'Login Date Time',
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant='body2' sx={{ color: 'text.primary' }}>
        {getLocalDateTime(params?.row?.login_time, "DD/MM/YYYY HH:mm")}
      </Typography>
    )
  }
]

export default function Demo() {
  const dispatch = useAppDispatch();
  const { clientLoginReport } = useAppSelector((state: any) => state.clientReducer);
  // ** State
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>(null)
  const [pageSize, setPageSize] = useState<number>(25)
  const [rows, setRows] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false);
  const { rolesPermission } = useAppSelector((state: any) => state.rolePermissionReducer);
  const isExport = rolesPermission?.client_management?.Client_Login_Report?.includes("export");

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      field: "id",
      headerName: "Id",
      filter: 'agNumberColumnFilter',
      checkboxSelection: true
    },
    {
      field: "client_name",
      headerName: "Client Name",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.client?.name || ""
      }
    },
    {
      field: "client_phone",
      headerName: "Phone",
      filter: 'agTextColumnFilter',
      valueGetter: (p: any) => {
        return p.data?.client?.phone || ""
      }
    },
    {
      field: 'login_time',
      headerName: 'Login Date Time',
      filter: 'agDateColumnFilter',
      valueFormatter: (p: any) => p.value ? getLocalDateTime(p.value, "DD/MM/YYYY HH:mm") : "-",
    }
  ]);

  useEffect(() => {
    if (clientLoginReport && clientLoginReport?.items) {
      setRows(clientLoginReport?.items);
      setTotal(clientLoginReport?.meta?.total);
    }
  }, [clientLoginReport])

  const fetchTableData = useCallback(
    async (page: number, pageSize: number, sort: SortType, column: string, filterModel: any = "") => {
      setLoading(true);
      return dispatch(getClientLoginReport({ pageSize, page, sort, column, filterModel: filterModel ? encodeURIComponent(JSON.stringify(filterModel)) : "" })).then((res: any) => {
        return res.payload.data
      })
    },
    []
  )

  const handleDialog = () => {
    setOpenDialog(!openDialog)
  }

  const getRowClassName = () => {
    return {
      'normal-row': (params: any) => { return true; }
    }
  };

  const attr = {
    title: 'Client Login Report',
    // buttonName: 'Add',
    page,
    setPage,
    total,
    setTotal,
    sort,
    setSort,
    pageSize,
    setPageSize,
    rows,
    columns: columnDefs,
    searchValue,
    setSearchValue,
    sortColumn,
    loading,
    getRowClassName,
    setSortColumn,
    onButtonClick: handleDialog,
    fetchTableData,
    isExport,
    isWrite: false
  }

  return (
    <Box>
      {/* <TableServerSide {...attr} /> */}
      <AgGridSelectTable {...attr} />
      {openDialog && <AddUpdate open={openDialog} onClose={handleDialog} />}
    </Box>
  )
}
